import React, { Component } from 'react';
import { connect } from 'react-redux';
import { gameActions } from '../../action/GameAction';
import Header from '../../Header/Header';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import Countdown, { zeroPad } from 'react-countdown';
import PriceBreakdown from './PriceBreakdown';
import BrandTrust from './BrandTrust';
import HowToPlay from './HowToPlay';
import LatestResults from './LatestResults';
import DownloadApp from './DownloadApp';
import { history } from '../../helpers/history';
import { commonClass } from "../../helpers/commonClass";
import { Helmet } from "react-helmet";
import { loginActions } from '../../action/loginAction';
import SaleClose from '../SaleClosed/SaleClose';
import SetCookie from '../../Cookie/SetCookie';
let refecthgamecnt = 0;
let TotalGames = 4;
let lastCountDownTime = Date.now();
const locky1Arr = [1, 2, 5, 10];
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDrawCompleted: false,
            cartItems: [],
            random: [],
            autoApplyCountmax: 10,//max pad count
            myfavNoID: 0,
            otherGameCount: 0,
            dataFatchCompleted: false,
            ActiveSlider: 1,
            showSaleCloseInfo: false,
            showSaleCloseSuper: false,
            bannerList: [],
            SaleCloseInfoMagic: false,
            MagicGameId: 0,
            SuperGameId: 0,
            FortuneGameId: 0,
            PrevCartFlag: false,
            SaleCloseInfoLucky: false,
            LuckyGameId: 0,
        };
        // props.ClearCart();
        this.props.getBanner(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
        props.closeBetSlip();
        let tmr = setInterval(
            () => {
                if (this.props.serverTime && this.props.serverTime > 0) {
                    props.GameAvailableGames(props.user.mobileNo, 0, props.i18n.language, this.props.serverTime, this.props.user.loginKey);
                    clearInterval(tmr);
                }
            }, 1000)

    }

    componentDidMount() {
        window.backtotop();
        let mythis = this;
        mythis.setjsondata();
        setTimeout(() => { mythis.setjsondata(1); }, 1000)//700
        setTimeout(() => {
            if ((!this.state.currentAvailableDraws || this.state.currentAvailableDraws.length == 0) && localStorage.getItem("fis") != "1") {
                localStorage.setItem("fis", "1");
                window.location.href = window.location.href;
            }
            // else if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.dataFatchCompleted == false) {
            //     this.setState({ dataFatchCompleted: true });
            // }
        }, 2000);
        window.addEventListener("visibilitychange", function () { mythis.handleFocus(); })
        let Obj = {
            'language': this.props.i18n.language
        }
        commonClass.SendEventTracking(16, Obj)
    };

    handleFocus = () => {
        try {
            if (document.visibilityState == "visible") {
                if (!this.state.currentAvailableDraws || this.state.currentAvailableDraws.filter(Obj => Obj.SaleStatus == 0).length != TotalGames) {
                    this.props.GameFamily(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);//typeID,mobileno,langID
                    setTimeout(() => {
                        refecthgamecnt = 0;
                        this.state.dataFatchCompleted = false;
                    }, 500);
                }
            }
        }
        catch (ex) {
            console.log("document visibilityState", ex)
        }
    }
    componentDidUpdate() {
        var lstrdata = localStorage.getItem('alertmsg');
        if (lstrdata != null) {
            this.props.alertsucc(lstrdata);
        }
        this.loadPrevCartItem();
        this.reFetchTheGameData(); // game loading performance changes.
    }

    reFetchTheGameData = () => {
        try {
            if (this.state.dataFatchCompleted == false) {
                if ((!this.state.currentAvailableDraws || this.state.currentAvailableDraws.filter(Obj => Obj.SaleStatus == 0).length != TotalGames) && refecthgamecnt < 2) {
                    console.log("refecthgamecnt " + refecthgamecnt);
                    let mythis = this;
                    refecthgamecnt++;
                    let regame = setInterval(() => {
                        mythis.props.GameAvailableGames(mythis.props.user.mobileNo, 0, mythis.props.i18n.language, mythis.props.serverTime, mythis.props.user.loginKey);
                        clearInterval(regame)
                    }, (2000 * refecthgamecnt));
                }
                else {
                    this.state.dataFatchCompleted = true;
                    this.setState({ dataFatchCompleted: true });
                }
            }
        }
        catch (ex) {
            console.log("reFetchTheGameData => " + ex)
        }
    };

    static getDerivedStateFromProps(props, state) {
        return {
            currentAvailableDraws: props.currentAvailableDraws,
        }
    }
    loadPrevCartItem = () => {
        if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.PrevCartFlag == false) {
            this.state.PrevCartFlag = true;
            let prevCartItem = localStorage.getItem('mycart') && localStorage.getItem('mycart') == '' ? null : JSON.parse(localStorage.getItem('mycart'));
            // window.SetWidthCountDown();
            if (prevCartItem) {
                this.state.otherGameCount = prevCartItem.length;
                return
                this.state.currentAvailableDraws.forEach(_drawEle => {
                    //for get the other game betslip value..                
                    let prevCartItem1 = prevCartItem.filter(a => a.gamegroupID == _drawEle.gamegroupID);
                    if (prevCartItem1 && prevCartItem1.length > 0) {
                        _drawEle.BetSelection = prevCartItem1[0].BetSelection;
                        _drawEle.stakeValue = prevCartItem1[0].stakeValue;
                        this.state.cartItems.push(prevCartItem1[0]);
                    }
                });
            }
        }
    }
    showMsg = (msg, flag) => {
        this.props.alertError(this.props.t(msg), flag);
    }

    createBall = (x) => {
        // alreadt check condition in calling area.
        // if (x.SaleStatus == 1) {
        //     return <li><h6 className={`paddingNone uppercase !text-xl text-game${x.gamegroupID} text-center`}>{this.props.t("lblclosed")}</h6></li>;
        // } 
        let darr = x.no_of_balls.split('~');
        let GameId = x.GameId;//x.GamePadId;
        let sballno = darr[0];
        let eballno = darr[1];
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            //i = ("0" + i).slice(-2);
            i = "" + i;
            let b = i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall(i, x) }}><a className={`${x.BetSelection && x.BetSelection.filter(y => y == i).length > 0 ? `game-${x.gamegroupID}-active` : ''}`}>{i}</a></li>)
        }
        return ballLst;
    }

    selMulti_lucy1 = (x, mval, fromoval) => {
        x.OddsVal = mval;
        if (fromoval == 1) {
            document.getElementById('txtmtpl').value = '';
        }
    }
    selMulti_lucy1_sub = (x) => {
        const mval = locky1Arr[0];
        document.getElementById('txtmtpl').value = '';
        this.selMulti_lucy1(x, mval);
    }
    multiKup = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    multiKyblur = (_txtthis, x) => {
        if (/\D/g.test(_txtthis.currentTarget.value)) {
            _txtthis.currentTarget.value = _txtthis.currentTarget.value.replace(/\D/g, '');
        }
        if (x.maxStake < (_txtthis.currentTarget.value * x.minStake)) {
            _txtthis.currentTarget.value = '';
            this.selMulti_lucy1(x, locky1Arr[0]);
        }
        else {
            this.selMulti_lucy1(x, _txtthis.currentTarget.value);
        }
    }
    createBall_lucy1 = (x) => {
        // <li><a href="#" className="game-13-balls-1"><span>1</span></a></li>
        // <li><a href="#" className="game-13-balls-2 game-13-active"><span>2</span></a></li>
        let darr = x.no_of_balls.split('~');
        let GameId = x.GameId;
        let sballno = parseInt(darr[0]);
        let eballno = parseInt(darr[1]);
        let ballLst = [];
        for (let i = sballno; eballno >= i; i++) {
            let b = "" + i;
            let b_i = GameId + "_" + b;
            ballLst.push(<li id={`ball_${b_i}`} onClick={() => { this.selectBall_lucy1(i, x) }}><a className={`game-${x.gamegroupID}-balls-${i} ${x.BetSelection.filter(y => y == i).length > 0 ? `game-${x.gamegroupID}-active` : ''}`}><span>{i}</span></a></li>)
        }
        return ballLst;
    }
    selectBall_lucy1 = (b_id, x) => {
        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                if (x.BetSelection[inx] == b_id) {
                    if (x.BetSelection.length == 1) {
                        x.BetSelection[inx] = '?';
                    }
                    else {
                        x.BetSelection.splice(inx, 1);
                    }
                    break;
                }
            };
        }
        else {
            if (x.BetSelection.length > 1) {
                x.BetSelection.push(b_id);
            }
            else if (x.BetSelection[0] == "?") {
                x.BetSelection[0] = b_id;
            }
            else {
                x.BetSelection.push(b_id);
            }
        }
        if (x.BetSelection.filter(a => a == '?').length <= 0) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            x.BetSelectionSort = jnew;
            x.stakeValue = x.minStake;
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.cartItems;
        this.setState({ cartItems: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
        }
        if (x.stakeValue > 0) {
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }

    selectBall = (b_id, x) => {

        let flst = x.BetSelection.filter(a => a == b_id);
        let darr = x.no_of_balls.split('~')[2];
        if (flst.length > 0) {
            for (let inx = 0; inx < x.BetSelection.length; inx++) {
                if (x.BetSelection[inx] == b_id) {
                    x.BetSelection[inx] = '?';
                    break;
                }
            };
            //x.BetSelection = x.BetSelection.filter(a => (a != b_id));
        }
        else {
            if (x.BetSelection.filter(a => a != '?').length >= darr) {
                this.props.alertError(this.props.t("lblMaxSelect") + darr + '.');
                return;
            }
            else {
                for (let inx = 0; inx < x.BetSelection.length; inx++) {
                    if (x.BetSelection[inx] == "?") {
                        x.BetSelection[inx] = b_id;
                        break;
                    }
                };
            }
        }
        if (x.BetSelection.filter(a => a != '?').length == darr) {
            var arr2 = JSON.stringify(x.BetSelection);
            arr2 = JSON.parse(arr2);
            let sbet = [...arr2.sort((a, b) => parseInt(a) > parseInt(b) ? 1 : -1)];
            let jnew = sbet.map((e, c) => e).join(';')
            let prevCartItem = localStorage.getItem('mycart') != '' ? JSON.parse(localStorage.getItem('mycart')) : null;
            if (prevCartItem) {
                let noexits1 = prevCartItem.filter(a => a.BetSelectionSort == jnew && a.gamegroupID == x.gamegroupID);
                if (noexits1.length <= 0) {
                    x.BetSelectionSort = jnew;
                    x.stakeValue = x.minStake;
                }
                else {
                    for (let inx = 0; inx < x.BetSelection.length; inx++) {
                        if (x.BetSelection[inx] == b_id) {
                            x.BetSelection[inx] = '?';
                            break;
                        }
                    };
                    this.showMsg(this.props.t('lblDuplicateNoSel'))
                    return;
                }
            }
            else {
                x.BetSelectionSort = jnew;
                x.stakeValue = x.minStake;
            }
        }
        else {
            x.stakeValue = 0;
        }
        // for slow ball selection issues fixed
        let s = this.state.currentAvailableDraws;
        this.setState({ currentAvailableDraws: s });
        //or gtm data layer fires, 1st time selection 
        let fsel = localStorage.getItem("ftimesel")
        if (x.stakeValue > 0 && (!fsel || fsel.indexOf("~" + x.gamegroupID) < 0)) {
            localStorage.setItem("ftimesel", fsel + "~" + x.gamegroupID)
            if (x.gamegroupID == 9) {
                commonClass.SendPageDataLayer(5);
            }
            else if (x.gamegroupID == 11) {
                commonClass.SendPageDataLayer(17);
            }
            else if (x.gamegroupID == 10) {
                commonClass.SendPageDataLayer(6);
            }
            else if (x.gamegroupID == 13) {
                commonClass.SendPageDataLayer(19);
            }
        }
        if (x.stakeValue > 0) {
            // this.showMsg('lblnumberselsuc'); //Show After Number Select Msg
            let tmr = setInterval(
                () => {
                    this.props.clearAlerts()
                    clearTimeout(tmr);
                }, 2000)
        }
    }

    reCount = (rundraw) => {
        if (rundraw == null || Object.keys(rundraw).length == 0) {
            return <></>;
        }
        else {
            this.state.coundownStart = 1;
            return <><Countdown now={() => { return (this.props.serverTime); }} date={this.getGameTime(rundraw)} onComplete={this.countdownCompleted} renderer={this.renderer} /> </>;
        }
    };

    getGameTime = (rundraw) => {
        lastCountDownTime = Date.now();
        let _date = rundraw ? rundraw.DrawDateTime : "";
        if (_date != undefined && _date != "") {
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            let sTime = (new Date(_date.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
            //console.log("getGameTime : " + sTime);
            //console.log("DateNow : " + Date.now());

            // if (rundraw.SaleStatus == 0) { //0-open,1-closed  ,Sale closeTime implemented
            let SaleCloseTime = sTime - rundraw.SaleCloseTimeMSec;
            let SaleStatusRemaining = SaleCloseTime - this.props.serverTime;
            let SalesClo = (SaleStatusRemaining > 0 ? 0 : 1);
            if (SalesClo != rundraw.SaleStatus) {
                rundraw.SaleStatus = SalesClo;
            }
            // }

            //if (rundraw.SaleStatus == 0) {
            //this.handleFocus();
            //}

            // if (this.state.showSaleCloseInfo == false) {
            //     let SaleCloseTime1 = sTime - this.props.serverTime;
            //     this.state.showSaleCloseInfo = SaleCloseTime1 < (24 * (60 * (60 * 1000))) ? true : false;
            // }
            let daysmillis = 86400000; //One day (24 hours)
            if (rundraw.gamegroupID == 9 || (rundraw.gamegroupID == 9 && this.state.FortuneGameId != rundraw.GameId)) {
                if (this.state.showSaleCloseInfo == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.showSaleCloseInfo = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.FortuneGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 11 || (rundraw.gamegroupID == 11 && this.state.MagicGameId != rundraw.GameId)) {
                if (this.state.SaleCloseInfoMagic == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.SaleCloseInfoMagic = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.MagicGameId = rundraw.GameId;
            }
            else if (rundraw.gamegroupID == 13 || (rundraw.gamegroupID == 13 && this.state.LockyGameId != rundraw.GameId)) {
                if (this.state.SaleCloseInfoLucky == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.SaleCloseInfoLucky = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.LockyGameId = rundraw.GameId;

                // let ss = new Date("28/08/2024 00:00:00".replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
                // let gametimestamp = (ss.getTime());
                // let dd = gametimestamp - this.props.serverTime;
                // if (dd > daysmillis) {
                //     this.state.SaleCloseInfoLuckyTemp = true;
                // }
            }
            else if (rundraw.gamegroupID == 10 || (rundraw.gamegroupID == 10 && this.state.SuperGameId != rundraw.GameId)) {
                if (this.state.showSaleCloseSuper == false) {
                    let SaleCloseTime1 = sTime - this.props.serverTime;
                    this.state.showSaleCloseSuper = SaleCloseTime1 < daysmillis ? true : false;
                }
                this.state.SuperGameId = rundraw.GameId;
            }

            return sTime;
        }
        else {
            return 0;
        }
    };
    renderer = ({ total, days, hours, minutes, seconds, completed }) => {
        let retval
        if (completed) {
            retval = <div className='aftercomplete' >{this.props.t('lblDrawClosed')}</div>;
            // className='whiteclr aftercomplete' style={{ color: "#ffffff", marginTop: "1.75rem", position: "relative", top: "-18px" }}
        } else {
            if (days > 0) {
                retval = <>
                    {/* {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)} */}
                    <li><p className='whiteclr'>Days</p><div id="days1">{zeroPad(days)}</div><span>:</span></li>
                    <li><p className='whiteclr'>Hours</p><div id="hours1">{zeroPad(hours)}</div><span>:</span></li>
                    <li><p className='whiteclr'>Minutes</p><div id="minutes1">{zeroPad(minutes)}</div><span>:</span></li>
                    <li><p className='whiteclr'>Seconds</p><div id="seconds1">{zeroPad(seconds)}</div></li>
                </>;
            }
            else {
                retval = <>
                    <li><p className='whiteclr'>Hours</p><div id="hours1">{zeroPad(hours)}</div><span>:</span></li>
                    <li><p className='whiteclr'>Minutes</p><div id="minutes1">{zeroPad(minutes)}</div><span>:</span></li>
                    <li><p className='whiteclr'>Seconds</p><div id="seconds1">{zeroPad(seconds)}</div></li>
                </>;
            }
        }
        return retval;
    };
    countdownCompleted = () => {
        //this.RefreshAfterGameClose();        
        this.state.isDrawCompleted = true;
    };
    clearCart = (x) => {
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
    };

    BuyLotteryBet = (x) => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblPlsLoginOrSignUp');
            return;
        }
        if (x.BetSelection.length != x.no_of_balls.split('~')[2]) {
            this.showMsg('lblplcurbet');
            return;
        }
        let totStake = x.stakeValue;
        var sTime = new Date(this.props.serverTime);
        let cartList = [(x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')];
        let gamestring = "";
        let inx = 1;
        cartList.forEach(item => {
            gamestring += inx;
            gamestring += "~" + item;
            gamestring += "~" + 0;//oddsValue;
            gamestring += "~" + x.minStake;//stakeValue;
            gamestring += "~" + x.minStake + "|";//total
        })
        let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
        let ObjBuy = {
            "reflot": x.GameId,
            "group_id": parseInt(x.gamegroupID),
            "draw_time": x.DrawTime,
            "bet_info": gamestring,
            "client_transid": TransID,
            "amount": totStake,
            "type_id": 0
        }
        this.props.Buybet(ObjBuy, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.clearCart(x);
    };

    AddToCart = (_draw) => {
        let bervalidate = true;
        // if (this.props.user == undefined || this.props.user.mobileNo == "0") {
        //     this.showMsg('lblPlsLoginOrSignUp', 1);
        //     bervalidate = false;
        //     return;
        // }
        // else 
        if (_draw.BetSelection.filter(a => a == '?').length > 0 || (_draw.OddsVal <= 0 && _draw.gamegroupID == 13)) {
            bervalidate = false;
            this.showMsg('lblplcurbet');
            return;
        }
        if (bervalidate == false) { return }
        //if (this.state.cartItems && this.state.cartItems.length > 0) {
        let lst = this.state.cartItems.filter(a => a.gamegroupID == _draw.gamegroupID);
        if (lst && lst.length > 0) {
            lst[0].BetSelection = _draw.BetSelection;
            this.props.UpdateCartAll(lst[0]);
        }
        //}
        else {
            if (this.state.otherGameCount >= 50) {
                this.showMsg('lblMaxLen')
                return;
            }
            let _mybetslip = null;
            if (_draw.gamegroupID != 13) {
                _mybetslip = _draw.autoApply
            }
            else {
                _mybetslip = _draw.BetSelection.length
            }
            for (let i = 0; i < _mybetslip; i++) {
                // let _maxid = this.state.cartItems.length > 0 ? Math.max(...this.state.cartItems.map(o => o.GamePadId)) : 0;
                // _maxid++;
                const min = 0;
                const max = 99999;
                let _maxid = min + (Math.random() * (max - min));
                let _mybetslipArr = _draw.gamegroupID != 13 ? _draw.BetSelection : [_draw.BetSelection[i]];
                let _mybetslipArrSort = _draw.gamegroupID != 13 ? _draw.BetSelectionSort : "" + _draw.BetSelection[i];
                let mybet = {
                    GamePadId: _maxid, BetSelection: _mybetslipArr, stakeValue: _draw.stakeValue, GameId: _draw.GameId,
                    RateInPs: _draw.RateInPs, gamegroupID: _draw.gamegroupID, StakeMulti: _draw.StakeMulti,
                    total: (_draw.minStake * _draw.RateInPs), no_of_balls: _draw.no_of_balls, DrawDate: _draw.DrawDate,
                    LotName: _draw.LotName, DrawDateTime: _draw.DrawDateTime, autoApply: _draw.autoApply,
                    DrawTime: _draw.DrawTime, minStake: _draw.minStake, maxStake: _draw.maxStake, DrawShowTime: _draw.DrawDate + " " + _draw.DrawShowTime,
                    SaleCloseTimeMSec: _draw.SaleCloseTimeMSec, BetSelectionSort: _mybetslipArrSort, OddsVal: _draw.OddsVal
                };
                this.state.cartItems.push(mybet);
                if (this.state.cartItems[i].gamegroupID == 13) {
                    this.state.cartItems[i].stakeValue = this.state.cartItems[i].minStake * this.state.cartItems[i].OddsVal;
                }
                this.props.AddToCart(mybet);
            }
            let cobj = {};
            commonClass.SendEventTracking(9, cobj, false, this.state.cartItems);
        }
        //for multi buy
        this.gotoMyCart();
    }
    gotoMyCart = () => {
        let bervalidate = true;
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            let mobno = 0;
            localStorage.setItem('lastloginuser', mobno);
            setTimeout(() => {
                this.showMsg('lblPlsLoginOrSignUp', 1);
            }, 500);
            bervalidate = false;
            return;
        }
        //if (this.state.cartItems.length == 0 && this.state.otherGameCount == 0) {
        if (this.state.cartItems == undefined && this.state.cartItems == null) {
            bervalidate = false;
            this.showMsg('lblplcurbet');
            return;
        }
        for (let i = 0; i < this.state.cartItems.length; i++) {
            let x = this.state.cartItems[i];
            if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
                this.showMsg('lblplcurbet');
                bervalidate = false;
                break;
            }
        }
        if (bervalidate) {
            this.showMsg('lblAddCartsucss');
            setTimeout(() => {
                history.push('/mycart');
            }, 1000);
        }
    }

    quickPick = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        this.state.random = [...x.BetSelection.filter(a => a != '?')];
        x.BetSelection.forEach((_bx, inx) => {
            x.BetSelection[inx] = '?';
        });
        this.state.random = [];
        let QPBallCount = maxballno - this.state.random.length;
        if (QPBallCount <= 0) {
            QPBallCount = maxballno;
        }
        for (var k = sballno; k <= QPBallCount; k++) {
            let _bal = this.generateRandomInteger(x);
            this.selectBall(_bal, x);
        }
    }

    generateRandomInteger = (x) => {
        let darr = x.no_of_balls.split('~');
        let sballno = darr[0];
        let eballno = darr[1];
        let maxballno = darr[2];
        var min = sballno;
        var max = eballno;
        if (this.state.random.length >= maxballno) {
            this.state.random = [];
        }
        let valFound = false;
        while (valFound == false) {
            for (var i = min; i <= max; i++) {
                var temp = Math.floor(max - Math.random() * (max - min));
                temp = "" + temp;
                //temp = ("0" + temp).slice(-2);
                if (temp && this.state.random.indexOf(temp) == -1) {
                    this.state.random.push(temp);
                    valFound = true;
                    return temp;
                }
            }
        }
    };

    applyAutoBuy = (flog, x) => {
        if (flog == "+") {
            if (x.autoApply + 1 > this.state.autoApplyCountmax) {
                return;
            }
            else {
                x.autoApply++;
            }
        }
        else if (flog == "-") {
            if (x.autoApply - 1 < 1) {
                return
            }
            else {
                x.autoApply--;
            }
        }
    };

    setIsShown = (x, flog) => {
        if (x.BetSelection && x.BetSelection.filter(a => a != '?').length == x.no_of_balls.split('~')[2]) {
            x.betComplete = flog;
        }
        else {
            x.betComplete = false;
        }
    }
    saveMyFavNo = (x) => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblplsloginTry', 1);
            return;
        }
        if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
            this.showMsg('lblplcurbet');
            return;
        }
        let no = (x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')
        this.props.SaveMyFavNumbers(this.state.myfavNoID, x.gamegroupID, no, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
        let uObj = {};
        let SavemyNo = [x]
        commonClass.SendEventTracking(12, uObj, false, SavemyNo);

    }
    AddTicket = (obj) => {
        let no = (obj.BetSelection.join(';'))
        //localStorage.setItem('setfavNo', no);
        localStorage.setItem('setAddPad', no);
        if (obj.gamegroupID == 9) {
            history.push('/fortune4');
        }
        else if (obj.gamegroupID == 11) {
            history.push('/magic5');
        }
        else if (obj.gamegroupID == 13) {
            history.push('/lucky1');
        }
        else {
            history.push('/super6');
        }
    }
    Formbannerimage = (data) => {
        try {
            const mythis = this;
            let HomebannerJsonObj = [];
            data.data.forEach(obj => {
                if (obj) {
                    let startdate = obj.startDate;
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    let strmilliseconds = (new Date(startdate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                    if (mythis.props.serverTime >= strmilliseconds) {
                        if (obj.expiryDate != "" && obj.expiryDate != "null") {
                            let enddate = obj.expiryDate;
                            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                            let endmilliseconds = (new Date(enddate.replace(pattern, '$3-$2-$1').replace(/-/g, '/')).getTime());
                            if (mythis.props.serverTime < endmilliseconds) {
                                obj.imagPath = data.baseurl ? data.baseurl + obj.imagPath : obj.imagPath;
                                HomebannerJsonObj.push(obj);
                            }
                        }
                        else {
                            obj.imagPath = data.baseurl ? data.baseurl + obj.imagPath : obj.imagPath;
                            HomebannerJsonObj.push(obj);
                        };
                    }
                }
            });
            return HomebannerJsonObj;
        }
        catch (ex) {
            console.log("Formbannerimage => ", ex)
        }
    }
    bnrClck = (e) => {
        try {
            if (e.target.tagName === 'IMG') {
                const _ink = e.target.getAttribute('data-lnk');
                if (_ink && _ink.length > 1) {
                    history.push("/" + e.target.getAttribute('data-lnk'))
                }
            }
        } catch (ex) {
            console.log("bnrClck ==>", ex)
        }
    }
    SetgetJsonbanner = (HomebannerJson) => {
        try {
            let numAscending = HomebannerJson.sort(function (a, b) {
                return a.position - b.position || b.modifyOn - a.modifyOn;
            });
            //     this.setState({ bannerList: numAscending });
            //   setTimeout(() => {
            //     window.documentReadyjQueryFlex();
            //     }, 600); 

            let dd = "";
            numAscending.forEach(x => {
                dd += `<li class="bannerdisplay"><img src='${x.imagPath + '?' + x.version}' data-lnk='${x.linkUrl}' alt='Slider Image'/></li>`;
            });
            document.getElementById('Homeslide').innerHTML = "<div class='banner'> <ul class='slides' id='HomeslideChild' >" + dd + "</ul></div>";
            document.getElementById('HomeslideChild').addEventListener("click", this.bnrClck);
            window.documentReadyjQueryFlex();

            // let gg = setInterval(() => {
            //     let c_cnt = document.getElementById('HomeslideChild').childElementCount;
            //     if (c_cnt > 0 && this.state.bcn != c_cnt) {
            //         this.state.bcn = c_cnt;
            //         clearInterval(gg);
            //         window.documentReadyjQueryFlex();
            //     }
            // }, 500);

        }
        catch (ex) {
            console.log("SetgetJsonbanner => ", ex)
        }
    }

    setjsondata = (isdelay = 0) => {
        try {
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('whbver');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[0];
                    // localStorage.setItem('whbver', hbanner); Somes Time Could Not Loaded Implement In Inside The After Fetch FTP
                }
            }
            let temp_hbanner = false;
            let _test = JSON.parse(localStorage.getItem('whblst'));
            if (_test && !_test.data) {
                localStorage.removeItem('whblst');
                temp_hbanner = true;
            }
            let HomebannerJson = [];
            if (hbanner_old != hbanner || temp_hbanner) { // hbanner_old != hbannertrue
                var s = process.env.REACT_APP_CDN_URL + 'Web/WEBHome.json' + "?V" + hbanner;
                fetch(s).then(response => response.json()).then(data => {
                    //  HomebannerJson = this.Formbannerimage(data.data, data.baseurl);          //form banner image
                    // localStorage.setItem('whblst', JSON.stringify(data.data)); // set ftb all Array
                    localStorage.setItem('whblst', JSON.stringify(data)); // set ftb all Array 
                    let dg = setInterval(() => {
                        if (this.props.serverTime > 0) {
                            clearInterval(dg);
                            HomebannerJson = this.Formbannerimage(data);          //form banner image
                            this.SetgetJsonbanner(HomebannerJson);                     //set banner image for state
                            localStorage.setItem('whbver', hbanner);
                        }
                    }, 100);
                })
            } else {
                if (isdelay == 0) {
                    let BannerimgLcl = JSON.parse(localStorage.getItem('whblst')); // get ftb all Array
                    let dg1 = setInterval(() => {
                        if (this.props.serverTime > 0) {
                            clearInterval(dg1);
                            HomebannerJson = this.Formbannerimage(BannerimgLcl);           //form banner image
                            this.SetgetJsonbanner(HomebannerJson);                         //set banner image for state
                        }
                    })
                }
            }
        }
        catch (ex) {
            console.log("setjsondata => ", ex)
        }
    };

    render() {
        const { t } = this.props;
        // let iSforture5 = 0;
        // let iSsuper6 = 0;
        // let iSmagic6 = 0;
        // if (this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0) {
        //     let s = this.state.currentAvailableDraws.filter(a => a.gamegroupID == 9);
        //     if (s.length > 0) { iSforture5 = 9; }
        //     let s1 = this.state.currentAvailableDraws.filter(a => a.gamegroupID == 10);
        //     if (s1.length > 0) { iSsuper6 = 10; }
        //     let s2 = this.state.currentAvailableDraws.filter(a => a.gamegroupID == 11);
        //     if (s2.length > 0) { iSmagic6 = 11; }
        // }
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title >Gulf Ticket: Your Gateway to Millionaire Dreams</title>
                    <meta name="description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                    <meta name="keywords" content="Gulf Ticket, online raffle draw, UAE raffle draw, Gulf Ticket millionaire draw, best online raffle draw in the UAE, how to play Gulf Ticket, Gulf Ticket lottery prizes, Gulf Ticket results, Gulf Ticket winner stories, Gulf Ticket reviews, Gulf Ticket promo codes, Gulf Ticket contact information, online raffle Dubai, how to play Gulf Ticket online, Gulf Ticket schedule and results, best strategies for winning Gulf Ticket Lottery" />

                    <meta property="og:title" content="Gulf Ticket: Your Gateway to Millionaire Dreams" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Unleash your chance to win millions with Gulf Ticket, the UAE's premier online raffle draw. Experience the thrill of life-changing possibilities and become a part of our winning community." />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                </Helmet>}
                <Header />
                <div id='Homeslide'>
                    {/* <div className="banner">
                        <ul className="slides" id='HomeslideChild'>
                            {this.state.bannerList && this.state.bannerList.length > 0 && this.state.bannerList.map((x, inx) =>
                                <li style={{ display: inx == 0 ? 'none' : 'none' }}>
                                    <img src={x.imagPath + '?' + x.version} onClick={() => { if (x.linkUrl) { history.push("/" + x.linkUrl) } }} />
                                </li>
                            )}
                        </ul>
                    </div > */}
                </div >
                <section className="hmGame">
                    <div className="wrap1">
                        {[13].map((x1, inx1) => <>
                            {(this.state.currentAvailableDraws && this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).length > 0) ?
                                this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).map((x, inx) =>
                                    <>
                                        <div key={`a_${inx}`} className={`zodiacGameCont gameCont bg-img13 gamesContainerHeight`}
                                            onMouseEnter={() => this.setIsShown(x, false)} onMouseLeave={() => this.setIsShown(x, true)}>
                                            <div className="lckGameLft">
                                                <div onClick={() => { history.push('/lucky1') }} className="flex justify-center mx-auto luckyLogo">
                                                    <img src="./assets/img/gamesBg/lucky-1-logo.png?v0.1" alt="lucky 1 logo" className="w-30" />
                                                </div>
                                                <h4 className={`paddingNone uppercase !text-base text-game13`}>{t('lblevryday')}  {x.DrawShowTime}(UAE)</h4>
                                                {x.SaleStatus != 1 ? <>
                                                    {(x.SaleCloseTimeMSec > 0 && this.state.SaleCloseInfoLucky && x.gamegroupID == 13) ?
                                                        <div className={`adjustDate text-game13`} >{t('lblsalebfr')} {x.SaleCloseTimeMSec / (60 * 1000)} {t('lblminutes')} </div>
                                                        :
                                                        <>
                                                            <p className="adjustDate text-game13"> &nbsp;</p>
                                                        </>
                                                    }
                                                </>
                                                    :
                                                    <p className="adjustDate text-game13"> &nbsp;</p>
                                                }
                                                <ul className={`hmDateCoundown lckDateCoundown mt-2 bg-game13`}  >
                                                    {this.reCount(x)}
                                                </ul>
                                            </div>
                                            <div className="lckGameRht">
                                                {x.SaleStatus == 1 ?
                                                    <>
                                                        <h4 className={`paddingNone uppercase !text-xl text-game${x.gamegroupID} text-center`}>{this.props.t("lblclosed")}</h4>
                                                    </>
                                                    : <ul className={`zodiacBallSec drawBallSectionlck game-13-balls rowGap ${x.betComplete ? `addTick${x.gamegroupID}` : ''}`}>
                                                        {this.createBall_lucy1(x)}
                                                    </ul>}
                                                {x.SaleStatus != 1 && <div className="lckGameRhtCont">
                                                    <div className="lckGameRhtCont1">
                                                        {x.SaleStatus != 1 && <>
                                                            {/* <div className="likeAndShare likeAndSharelck">
                                                                <a onClick={() => { this.saveMyFavNo(x) }}><img src="./assets/img/icons/like.webp" /></a>
                                                                <a onClick={() => { this.quickPick(x) }}><img src="./assets/img/icons/shuffle.webp" /></a>                                                                 
                                                                <button className={`bg-game13 !text-white flex justify-center items-center`} style={{ border: 'none', padding: '10px 0', borderRadius: '30px' }} onClick={() => { this.AddToCart(x) }}>{t('lblAddTocart')}</button>
                                                            </div> */}

                                                            <div className='ticketBtom lckticketBtom'>
                                                                {/* <div className='plusMinus'>
                                                                    <a className={`bg-game${x.gamegroupID} !text-white`} >-</a>
                                                                    <span>{x.autoApply}</span>
                                                                    <a className={`bg-game${x.gamegroupID} !text-white`} onClick={() => { this.AddTicket(x) }}>+</a>
                                                                </div> */}
                                                                <button className={`bg-game13 !text-white flex justify-center items-center zodiacSpace`} onClick={() => { this.AddToCart(x) }}>{t('lblAddTocart')}</button>
                                                            </div>
                                                        </>}
                                                    </div>

                                                    <div className="lckGameRhtCont2">
                                                        {locky1Arr.map((nx, inxl) =>
                                                            <button key={`b_${inxl}`} onClick={() => { this.selMulti_lucy1(x, nx) }} className={`zodiacValBtn clrAll ${x.OddsVal == nx ? 'bg-game13 text-white' : ''}`}>{nx}<sup>x</sup></button>
                                                        )}
                                                        <div className="zodiacValCont">
                                                            <span onClick={() => { this.selMulti_lucy1_sub(x) }}>x</span>
                                                            <input onBlur={(obj) => this.multiKyblur(obj, x)} onKeyUp={(obj) => this.multiKup(obj, x)} id='txtmtpl' maxLength={4} className="zodiacValInpt" />
                                                        </div>
                                                        <div className="lckWindtls">
                                                            <p>{t('lblPay')} {(x.minStake * x.OddsVal) * x.BetSelection.filter(fobj => fobj != '?').length}</p>
                                                            <span>{t('lblwin')} {(x.minStake * x.OddsVal) * x.StakeMulti}</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="circlePosn circlePosnLck">
                                                <div className="half-circle circle1"></div>
                                                <div className="half-circle circle2"></div>
                                            </div>
                                        </div>
                                    </>
                                ) :
                                <>{
                                    <div>
                                        <div className={`zodiacGameCont gameCont bg-img13 gameCont2 marBottom gamesContainerHeight`} >
                                            <div className="flex justify-center h-14 w-40 mx-auto">
                                                <img src="./assets/img/gamesBg/lucky-1-logo.png?v0.1" alt="lucky 1 logo" className="object-fill" />
                                            </div>
                                            {this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.dataFatchCompleted ?
                                                <h4 className={`paddingNone uppercase !text-xl text-game${x1} text-center Gameclose`} >{t('lblclosed')}</h4>
                                                : <h4 className={`paddingNone uppercase !text-xl text-game${x1} text-center Gameclose`} >{t('lblLoading')}..</h4>}
                                        </div>
                                    </div>
                                }
                                </>
                            }</>
                        )}
                    </div>
                </section >
                <section className="hmGame">
                    <div className="wrap1">
                        <div className="hmGameLiSec">
                            {[11, 9, 10].map((x1, inx1) => <>
                                {(this.state.currentAvailableDraws && this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).length > 0) ?
                                    this.state.currentAvailableDraws.filter(a => a.gamegroupID == x1).map((x, inx) =>
                                        <>
                                            {/* <div className={`gameCont ${x.gamegroupID == 9 ? 'games_bg' : (x.gamegroupID == 10 ? 'game2Background bg-white' : "magic5_bg")}`} onMouseEnter={() => this.setIsShown(x, false)} onMouseLeave={() => this.setIsShown(x, true)}> */}
                                            <div key={`c_${inx}`} className={`gameCont bg-img${x.gamegroupID} ${x.gamegroupID == 10 ? "bg-white" : ""} margin-0`} onMouseEnter={() => this.setIsShown(x, false)} onMouseLeave={() => this.setIsShown(x, true)}>
                                                {/* <h2>{x.LotName}</h2> */}
                                                <div className="flex justify-center h-14 w-40 mx-auto">
                                                    {x.gamegroupID == 9 ?
                                                        <img onClick={() => { history.push('/fortune4') }} src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune 5 logo" className="object-fill" />
                                                        : x.gamegroupID == 10 ?
                                                            <img onClick={() => { history.push('/super6') }} src="./assets/img/gamesBg/super-6-logo.png" alt="super 6 logo" className="object-fill" />
                                                            : <img onClick={() => { history.push('/magic5') }} src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="object-fill" />
                                                    }
                                                </div>
                                                <h3 className={`text-game${x.gamegroupID} font-[portilla] !mt-4`}>
                                                    <a onClick={() => { x.gamegroupID == 9 ? history.push('/fortune4') : (x.gamegroupID == 10 ? history.push('/super6') : history.push('/magic5')) }}>
                                                        <span className={`${x.gamegroupID == 10 ? "text-nowrap" : ""}`}>{this.props.appvariables.currency} {x.RateInPs.replace('#', t('lblMillion'))}*</span>
                                                    </a>
                                                </h3>
                                                <h4 className={`paddingNone uppercase !text-base text-game${x.gamegroupID}`}>{x.DisplayDateTime} {x.DrawShowTime}(UAE)</h4>
                                                {x.SaleStatus != 1 ? <>
                                                    {/* {((x.SaleCloseTimeMSec > 0 && this.state.showSaleCloseInfo && x.gamegroupID == 9) || (x.SaleCloseTimeMSec > 0 && this.state.showSaleCloseSuper && x.gamegroupID == 10) || (x.SaleCloseTimeMSec > 0 && this.state.SaleCloseInfoMagic && x.gamegroupID == 11)) ? */}
                                                    {(x.SaleCloseTimeMSec > 0 && ((this.state.showSaleCloseInfo && x.gamegroupID == 9) || (this.state.showSaleCloseSuper && x.gamegroupID == 10) || (this.state.SaleCloseInfoMagic && x.gamegroupID == 11))) ?
                                                        <div className={`adjustDate text-game${x.gamegroupID}`} >{t('lblsalebfr')} {x.SaleCloseTimeMSec / (60 * 1000)} {t('lblminutes')} </div>
                                                        : this.state.SaleCloseInfoMagic == false && this.state.showSaleCloseInfo == false && this.state.showSaleCloseSuper == false ?
                                                            <div className={`adjustDate text-game${x.gamegroupID}`}>&nbsp;</div>
                                                            : <div className={`adjustDate text-game${x.gamegroupID}`}>&nbsp;</div>
                                                    }
                                                </>
                                                    : <div className={`adjustDate text-game${x.gamegroupID}`}>&nbsp;</div>

                                                }
                                                <ul className={`hmDateCoundown mt-2 ${x.gamegroupID == 10 ? 'hmDateCoundown2 bgb' : x.gamegroupID == 9 ? 'bgo' : 'bgp'}`} >
                                                    {this.reCount(x)}
                                                </ul>

                                                <ul className={`ballSec topBallSec ${x.gamegroupID == 10 ? 'topBallsAdjust2' : 'topBallsAdjust'} game-${x.gamegroupID}-balls  ${x.gamegroupID == 9 ? 'topBallsAdjust4' : ''} ${x.SaleStatus == 1 ? 'AdjustSize' : ''}`}>
                                                    {x.SaleStatus != 1 && x.BetSelection && x.BetSelection.map((_sball, inxed) => <li key={`d_${inxed}`}><a className={`${_sball != "?" ? `game-${x.gamegroupID}-active` : ""}`}>{_sball}</a></li>)}
                                                </ul>

                                                <div className="circlePosn hmCirclePosn">
                                                    <div className="half-circle circle1"></div>
                                                    <div className="half-circle circle2"></div>
                                                </div>
                                                {x.SaleStatus == 1 ?
                                                    <><div></div><h4 className={`paddingNone uppercase !text-xl text-game${x.gamegroupID} text-center`}>{this.props.t("lblclosed")}</h4></> :
                                                    <ul className={`ballSec ${x.gamegroupID == 10 ? 'drawBallSection2' : 'drawBallSection'} game-${x.gamegroupID}-balls ${x.betComplete ? `addTick${x.gamegroupID}` : ''}`}>
                                                        {this.createBall(x)}
                                                    </ul>}

                                                {x.SaleStatus != 1 && <>
                                                    {/* <div className={`ticketBtom ${x.gamegroupID == 10 ? "ticket2Margin" : "ticket1Margin"}`}> */}
                                                    <div className={`ticketBtom ticket-${x.gamegroupID}-Margin`}>
                                                        <div className="likeAndShare">
                                                            <a onClick={() => { this.saveMyFavNo(x) }}><img src="./assets/img/icons/like.png" alt='like image' /></a>
                                                            <a onClick={() => { this.quickPick(x) }}><img src="./assets/img/icons/shuffle.png" alt='shuffle image' /></a>
                                                        </div>
                                                        <button onClick={() => { this.clearCart(x) }}>{t('lblClearAll')}</button>
                                                    </div>
                                                    <div className='ticketBtom'>
                                                        <div className='plusMinus'>
                                                            <a className={`bg-game${x.gamegroupID} !text-white`} >-</a>
                                                            <span>{x.autoApply}</span>
                                                            <a className={`bg-game${x.gamegroupID} !text-white`} onClick={() => { this.AddTicket(x) }}>+</a>
                                                        </div>
                                                        <button className={`bg-game${x.gamegroupID} !text-white ${x.gamegroupID == 11 ? "flex justify-center items-center" : ""} Addcartbdr`} onClick={() => { this.AddToCart(x) }}>{t('lblAddTocart')}</button>
                                                    </div>
                                                </>}
                                            </div>
                                        </>
                                    ) :
                                    <>{
                                        <div>
                                            <div className={`gameCont bg-img${x1} ${x1 == 10 ? 'bg-white' : ""} GameLoadingSec`} >
                                                {/* <>{<div className={`gameCont ${x1 == 9 ? 'games_bg' : (x1 == 10 ? 'game2Background bg-white' : "magic5_bg")}`} > */}
                                                {/* <h2>{x1 == 9 ? t('lblFortune5') : (x1 == 10 ? t('lblSuperSix') : t('lblmagic5'))}</h2> */}
                                                <div className="flex justify-center h-14 w-40 mx-auto">
                                                    {x1 == 9 ?
                                                        <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune 5 logo" className="object-fill" />
                                                        : x1 == 10 ?
                                                            <img src="./assets/img/gamesBg/super-6-logo.png" alt="super 6 logo" className="object-fill" />
                                                            : <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="object-fill" />
                                                    }

                                                </div>
                                                {this.state.currentAvailableDraws && this.state.currentAvailableDraws.length > 0 && this.state.dataFatchCompleted ?
                                                    <h4 className={`paddingNone uppercase !text-xl text-game${x1} text-center Gameclose`} >{t('lblclosed')}</h4>
                                                    // <SaleClose Pagetype={1} drawswise={this.state.currentAvailableDraws.filter(Obj => Obj.gamegroupID == x1)[0].RateInPs} currency={this.props.appvariables.currency} lottotype={x1}/>
                                                    : <h4 className={`paddingNone uppercase !text-xl text-game${x1} text-center Gameclose`} >{t('lblLoading')}..</h4>}
                                            </div>
                                        </div>
                                    }
                                    </>
                                }</>
                            )}
                        </div>
                    </div>
                </section >
                <PriceBreakdown />
                {/* <BrandTrust /> */}
                <HowToPlay ispage={false} />
                <LatestResults />
                <DownloadApp />
                <a id="bckTop"><img src="./assets/img/icons/back-to-top.png" alt='back-to-top Image' /></a>
                {/* <SetCookie launguage={this.props} /> */}
            </>
        );
    }
}


function mapStateToProps(state) {
    const { currentAvailableDraws } = state.gameReducer;
    const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    return { user, serverTime, currentAvailableDraws, appvariables };
}
const mapActionToProps = {
    GameFamily: gameActions.GameFamily,
    GameAvailableGames: gameActions.GameAvailableGames,
    Buybet: gameActions.Buybet,
    AddToCart: gameActions.AddToCart,
    ClearCart: gameActions.ClearCart,
    closeBetSlip: gameActions.closeBetSlip,
    alertError: alertActions.error,
    alertsucc: alertActions.success,
    SaveMyFavNumbers: gameActions.SaveMyFavNumbers,
    GetMyFavNumbers: gameActions.GetMyFavNumbers,
    UpdateCartAll: gameActions.UpdateCartAll,
    clearAlerts: alertActions.clear,
    getBanner: loginActions.getBanner
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))
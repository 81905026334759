import { commonClass } from "..//helpers/commonClass"
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
export const gameService = {
	GetGameFamily,
	GetAvailableGames,
	GetServerTime,
	GetLatestResult,
	LotteryOnlineSale,

	GetCurrentResult,
	GetSlotGames,
	GetRePrintDel,
	GetPopularGames,
	GetConfigOdds,
	SaveMyFavNumbers,
	GetMyFavNumbers,
	GetPreviousResult,
	GetReBetNumbers,
	GetFavNo,
	DeleteFavNo,
	GetRaffleResults,
	UserContact,
	SendUserratings,
	GetUserratings
};
const _apiUrl = process.env.REACT_APP_API_URL;
async function postFetch(_url, data, mobileNo, langID, loginKey, retrycnt = 0) {
	try {
		let _option = await commonClass.requestOptions(data, mobileNo, langID, loginKey);
		return fetch(`${_apiUrl}${_url}/`, {
			..._option,
			//retryDelay: 1000,
			retryOn: function (attempt, error, response) {
				// retry on any network error, or 4xx or 5xx status codes
				// if (error !== null || response.status == 403) {
				if (response && response.status == 401) {
					console.log(`retrying, attempt number ${attempt + 1}`);
					//return attempt >= 2 ? false : true;         
					if (retrycnt < 1) {
						retrycnt++;
						postFetch(_url, data, mobileNo, langID, loginKey, retrycnt);
					}
					return false
				}
			}
		})
			.then(commonClass.handleResponse)
			.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "postFetch=>" + _url);
	}
}
async function getFetch(_url, mobileNo, langID, loginKey, Ccode, retrycnt = 0) {
	try {
		let _option = await commonClass.getRequestOptions(mobileNo, langID, loginKey, Ccode);
		return fetch(`${_apiUrl}${_url}/`, {
			..._option,
			retryOn: function (attempt, error, response) {
				// retry on any network error, or 4xx or 5xx status codes
				// if (error !== null || response.status == 403) {
				if (response && response.status == 401) {
					console.log(`retrying, attempt number ${attempt + 1}`);
					//return attempt >= 2 ? false : true;         
					if (retrycnt < 1) {
						retrycnt++;
						getFetch(_url, mobileNo, langID, loginKey, retrycnt)
					}
					return false
				}
			}
		})
			.then(commonClass.handleResponse)
			.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "getFetch=>" + _url);
	}
}

function GetGameFamily(data, mobno, langID, loginKey) {
	try {
		// available_games - non catching api url		
		// return postFetch('available_games', data, mobno, langID, loginKey);
		return getFetch('get_avail_games', mobno, langID, loginKey);
		// return fetch(`${_apiUrl}available_games/`, commonClass.requestOptions(data, mobno, langID, loginKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(games => {
		// 		return games;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetAvailableGames(data) {
	try {
		return fetch(`${_apiUrl}/games/GetAvailableGames`, commonClass.requestOptions(data))
			.then(commonClass.handleResponse)
			.then(games => {
				let gamefamily = null;
				if (games != null && games.data != null) { games = games.data; }
				gamefamily = games;
				return gamefamily;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetLatestResult(data, mobno, langID, loginID) {
	try {
		//getlatestresult- non catching api url
		// return postFetch('getlatestresult', data, mobno, langID, loginID);
		return getFetch('get_all_result_arc', mobno, langID, loginID);
		// return fetch(`${_apiUrl}getlatestresult/`, commonClass.requestOptions(data, mobno, langID, loginID))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetServerTime(mobno, langID, loginID, Ccode) {
	try {
		// getservertime  - non catching api url
		return getFetch('getservertime', mobno, langID, loginID, Ccode)
			// return getFetch('getservertime_arc', mobno, langID, loginID,Ccode)
			.then(sTime => { return sTime.result; });
		// return fetch(`${_apiUrl}getservertime/`, commonClass.getRequestOptions())
		// 	.then(commonClass.handleResponse)
		// 	.then(serverTime => {
		// 		return serverTime.result;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function LotteryOnlineSale(data, mobno, langID, loginID) {
	try {
		return postFetch('sellticket_v1', data, mobno, langID, loginID);
		// return fetch(`${_apiUrl}sellticket_v1/`, commonClass.requestOptions(data, mobno, langID, loginID))
		// 	.then(commonClass.handleResponse)
		// 	.then(games => {
		// 		return games;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};

function GetCurrentResult(data, mobno, langID, loginID) {
	try {
		return postFetch('getcurrentresult', data, mobno, langID, loginID)
			.then(results => {
				results = results.result.win_nos;
				//results="0~ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2~3:00:00";
				//results="0~12~142~10/09/2021~10:10 AM~Quick Win~9";
				return results;
			});
		// return fetch(`${_apiUrl}getcurrentresult/`, commonClass.requestOptions(data, mobno, langID, loginID))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		results = results.result.win_nos;
		// 		//results="0~ThOtArPuElLaThA~7~12~11/11/11~11:00 AM~Test~2~3:00:00";
		// 		//results="0~12~142~10/09/2021~10:10 AM~Quick Win~9";
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex);
	}
};
function GetSlotGames(data, mobileNo, langID, sessionKey) {
	try {
		return fetch(`${_apiUrl}getslotgames/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
			.then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
function GetRePrintDel(data, mobileNo, langID, loginKey) {
	try {
		return postFetch('ticketinfo', data, mobileNo, langID, loginKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}ticketinfo/`, commonClass.requestOptions(data, mobileNo, langID, loginKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "ReprintBetDetails");
	}
}

function GetPopularGames(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('getpopulargames', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}getpopulargames/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "PopularGames");
	}
}

function GetConfigOdds(data, mobileNo, langID, sessionKey) {
	try {
		return fetch(`${_apiUrl}getoddsconfigscheme/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
			.then(commonClass.handleResponse)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "PopularGames");
	}
}
function SaveMyFavNumbers(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('upd_fav_numbers', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}upd_fav_numbers/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}
function GetMyFavNumbers(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('get_fav_number', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}get_fav_number/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}

function GetPreviousResult(data, mobno, langID, loginID) {
	try {
		return postFetch('get_all_result', data, mobno, langID, loginID)
		// return fetch(`${_apiUrl}get_all_result/`, commonClass.requestOptions(data, mobno, langID, loginID))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
};
function GetReBetNumbers(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('rebet_transaction', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}rebet_transaction/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}

function GetFavNo(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('get_fav_number', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}get_fav_number/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}
function DeleteFavNo(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('delete_fav_number', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}delete_fav_number/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}

function GetRaffleResults(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('draw_wise_result', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}draw_wise_result/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SaveMyFavNumbers");
	}
}


function UserContact(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('contacts_us', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "UserContact");
	}
}

function SendUserratings(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('post_rating', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "UserContact");
	}
}




function GetUserratings(mobileNo, langID, loginKey) {
    try {
        return getFetch('get_rating', mobileNo, langID, loginKey);
    }
    catch (ex) {
        commonClass.writeLog(ex, "GetUserratings");
    }
};
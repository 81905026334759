import { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { accountActions } from '../../action/AccountAction';
import breakdownlst from './pricebreakdown.json';
class PriceBreakdown extends Component {
    constructor(props) {
        super(props);
        this.state = { dataLoadCompleted: false };
        this.props.GetPriceBreakdownlst(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.state.dataLoadCompleted == false && this.state.breakdownlst && this.state.breakdownlst.result && Array.isArray(this.state.breakdownlst.result)) {
            this.state.dataLoadCompleted = true;
            setTimeout(() => { window.PriceBreakdownflex() }, 300);
        }
    }

    createBall = (x) => {
        let ballLst = [];
        for (let i = 1; i <= x.max_ball; i++) {
            ballLst.push(<span className={`bg-game${x.game_group_id}`}>{i}</span>)
        }
        return ballLst;
    }

    createTickBall = (x) => {
        let ballLst = [];
        for (let i = 1; i <= x.max_ball; i++) {
            if (x.matchingball >= i)
                ballLst.push(<span className={`bg-game${x.game_group_id}`}><img src="./assets/img/icons/tick.webp" /></span>)
            else
                ballLst.push(<span className={`bg-game${x.game_group_id}fade`}></span>)
        }
        return ballLst;
    }
    static getDerivedStateFromProps(props, state) {
        return {
            breakdownlst: props.breakdownlist
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <section className="priceBraDwn bg4">
                    <div className="wrap1">
                        <div className="priceBraDwnCont">
                            <div className="priceBraDwnTitle">
                                <img src="./assets/img/icons/money-bag.webp" />
                                <h1>{t('lblPriceBreak')}</h1>
                            </div>
                            <div className="pBrDnDls">
                                <ul className="slides">
                                    {[11, 9, 10].map((gObj, ginx) =>
                                    // style={{ display: 'none' }}
                                        <li key={`e_${ginx}b`} className='pricebreakdlist' >
                                            {this.state.breakdownlst && this.state.breakdownlst.result && Array.isArray(this.state.breakdownlst.result) && this.state.breakdownlst.result.filter(a => a.game_group_id == gObj).map((obj, inxx) =>
                                                <>{inxx == 0 &&
                                                    <div key={`ff_${inxx}a`} className="grid3Cols gamePrizeContainer">
                                                        <div>&nbsp;</div>
                                                        <div className={(gObj == 10) ? "grid6Cols" : "grid5Cols"}>
                                                            {this.createBall(obj)}
                                                        </div>
                                                        <div>
                                                            <div className="w-full flex justify-center py-2 rounded-full">
                                                                {gObj == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune 5 logo" className="h-12 w-28" /> :
                                                                    gObj == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png" alt="super 6 logo" className="h-12 w-28" /> :
                                                                        <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="h-12 w-28" />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>}
                                                    <div key={`fg_${inxx}`} className="grid3Cols numbersContainer">
                                                        <div>  {obj.matchingball} {t('lblOutOf')} {obj.max_ball}</div>
                                                        <div className={(gObj == 10) ? "grid6Cols" : "grid5Cols"}>
                                                            {this.createTickBall(obj)}
                                                        </div>
                                                        <div className="flex items-center gap-1 w-full">
                                                            <div className={`bg-game${gObj} winningTextSection`}>
                                                                <span>{obj.pricetext}</span>
                                                                {/* <p>{process.env.REACT_APP_CURRENCY} {obj.winamt.split("|")[0]}{obj.winamt.indexOf("|") > -1 && obj.winamt.split("|")[1] == "1" ? "*" : ""} </p> */}
                                                                {/* style={{ textTransform: "uppercase" }} */}
                                                                <p className='uppercase'>{process.env.REACT_APP_CURRENCY} {obj.winamt.replace('#', t('lblMillion'))}{obj.starFlag && obj.starFlag == "1" ? "*" : ""} </p>
                                                            </div>
                                                            {/* {obj.winamt.indexOf("|") > -1 && obj.winamt.split("|")[1] == "1" ?
                                                        <span className={`bg-game${gObj} fade text-white rounded-full h-5 w-5 flex justify-center items-center font-[quinn-bold]`}>*</span>
                                                        : <span className="w-5 h-5"></span>
                                                    } */}
                                                        </div>
                                                    </div>
                                                </>)}
                                            <div className="flex items-center gap-1 justify-center mt-4">
                                                <p>*</p>
                                                <span>{t('lblPriceShared')}</span>
                                                {/* <span className={`bg-game${gObj}fade text-white rounded-full h-5 w-5 flex justify-center items-center font-[quinn-bold]`}>*</span> */}
                                            </div>
                                        </li>
                                    )}
                                    {/* <li style={{ display: 'none' }}>
                                        <div className="grid3Cols gamePrizeContainer">
                                            <div>&nbsp;</div>
                                            <div>
                                                <div className="w-full flex justify-center py-2 rounded-full">
                                                    <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-12 w-28" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid3Cols numbersContainer">
                                            <div>  1 {t('lblOutOf')} 12 zodiac sign</div>
                                             <div className="grid5Cols"></div>

                                            <div className="flex items-center gap-1 w-full">
                                                <div className={`bg-game13 winningTextSection`}>
                                                    <span>Prize</span>
                                                    <p style={{ textTransform: "uppercase" }}>10x Entry Amount  </p>
                                                </div>

                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }

}
function mapStateToProps(state) {
    const { breakdownlist } = state.accountReducer;
    const { user } = state.loginReducer;
    return { user, breakdownlist };
}
const mapActionToProps = {
    GetPriceBreakdownlst: accountActions.GetPriceBreakdownlst
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PriceBreakdown))


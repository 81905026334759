import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginActions } from './action/loginAction';
import { withTranslation } from 'react-i18next';
import { appVariables } from './helpers/appVariables';
import { alertActions } from './action/AlertAction';
import Header from './Header/Header';

class Otpgeneration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: ['', '', '', '', '', ''],
            myRTime: 0,
            timeFinished: false,
            myRefNo: 0,
        }
        // if (props.otp && props.otp.otp) {
        //     this.showMsg(props.otp.otp);
        // }
    }
    handleChange = (value, e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(prevState => ({ list: prevState.list.map((obj, inx) => (inx === value ? e.target.value : obj)) }));
            if (((this.state.list.length - 1) > value) || e.target.value == "")
                document.querySelector(`input[name='${e.target.value !== "" ? (parseInt(value) + 1) : ((value !== 0) ? parseInt(value) - 1 : parseInt(value))}']`).focus();
        }
    }
    static getDerivedStateFromProps(props, state) {
        return { otp: props.otp };
    };
    btnLogin = () => {
        if (this.props.otp && this.props.otp.mobileNo && this.props.otp.mobileNo == "") {
            this.showMsg('lblentermobileNo');
            return;
        }
        let _otp = this.state.list.join('');
        if (_otp.length === 6) { }
        else { this.showMsg('lblEnterValidOtp'); return; }
        //no neeed to check login or register
        // if (this.props.otp.flog == 2)
        //     this.props.login(this.props.otp.mobileNo, _otp, appVariables.LOGIN.Otp, this.props.i18n.language, this.props.user.loginKey);
        // else
        //     this.props.Register(this.props.otp.mobileNo, _otp, this.props.i18n.language, this.props.user.loginKey, this.props.otp.firstname, this.props.otp.password, this.props.otp.Ccode);
        this.props.Registerotp(this.props.otp.mobileNo, _otp, this.props.i18n.language, this.props.user.loginKey, this.props.otp.Ccode);
    }
    GetOtp = () => {
        if (this.state.timeFinished == false) {
            // this.props.alertError(this.props.t("Try after OTP expired"));                
            return;
        }
        if (this.props.otp.mobileNo && this.props.otp.mobileNo != "") {
            if (this.props.otp.flog == 2) {
                this.props.Otpgen(this.props.otp.mobileNo, appVariables.OTP.Login, this.props.i18n.language, this.props.user.loginKey,this.props.otp.Ccode);
            }
            else {
                this.props.Otpgen(this.props.otp.mobileNo, appVariables.OTP.Register, this.props.i18n.language, this.props.user.loginKey,this.props.otp.Ccode);
            }
        }
    }
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }
    componentDidUpdate(previousProps, previousState) {
        if (this.props.otp && this.props.otp.ExpSec && this.props.otp.ExpSec > 0) {
            if (this.state.myRefNo != this.props.otp.RefNo) {//to check new otp after click resend btn
                this.state.myRefNo = this.props.otp.RefNo;
                this.state.myRTime = 0;
                this.state.timeFinished = false;
            }
            if (this.state.timeFinished == true) //check the time completed
            {
                return;
            }
            let tot = 0
            if (this.state.myRTime > 0) {
                tot = this.state.myRTime;
            }
            else {
                tot = this.props.serverTime + (this.props.otp.ExpSec * 1000);
                this.state.myRTime = tot;
            }
            let _rtime = tot - this.props.serverTime;
            let second = 1000
            let minute = second * 60
            let hour = minute * 60
            let day = hour * 24
            let _mintime = Math.floor(_rtime % day % hour / minute);
            let _sectime = Math.floor(_rtime % day % hour % minute / second);
            this.state.Rtime = _mintime + " : " + _sectime;
            if (_mintime <= 0 && _sectime <= 0) {
                this.state.Rtime = "";
                this.state.timeFinished = true;
            }
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <div className="wrap2">
                    <div className="otp">
                        <h2 className="headding3 cartHead">{t('lblVerification')}</h2>
                        <div className="otpCont">
                            <h3>{t('lblOTP')}</h3>
                            <p>{t('lblSendSixDigitCode')} <span dir='ltr'>{this.props.otp &&  this.props.otp.Ccode} {this.props.otp.mobileNo.substring(0, 3)}*****{this.props.otp.mobileNo.substring(this.props.otp.mobileNo.length - 2, this.props.otp.mobileNo.length)}</span> {t('lblVlaidateNo')}.</p>
                            <p>{this.props.otp && this.props.otp.otp}</p>
                            <div className="otpSec flex gap-4">
                                {this.state.list.map((digit, idx) => (
                                    <input autoFocus={idx === 0} key={idx} name={idx} type="text" inputMode="numeric" autoComplete="one-time-code" pattern="\d{1}" maxLength={1} className="otp-input" value={digit.value} onKeyUp={this.handleChange.bind(this, idx)} />
                                ))}
                            </div>
                            <div className="reSendBtn rtl:flex"><p>{t('lblDidnotReceiveSMS')}</p> <a onClick={() => this.GetOtp()} style={{ color: (this.state.timeFinished ? "#494949" : "#878585") }}>{t('lblResend')}</a>
                                <div style={{ color: '#d90606' }}>{this.state.Rtime ? this.state.Rtime + "s" : ""}</div>
                            </div>
                            <button className="verifyBtn bg3" onClick={() => { this.btnLogin() }} id="verifyBtn">{t('lblVerify')}</button>
                        </div></div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { serverTime } = state.commonReducer;
    const { user, pageFlog, otp } = state.loginReducer;
    return { user, pageFlog, otp ,serverTime};
}
const mapActionToProps = {
    login: loginActions.login,
    Registerotp: loginActions.Registerotp,
    Otpgen: loginActions.lfnotpgen,
    setPage: loginActions.setPage,
    alertError: alertActions.error
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Otpgeneration))
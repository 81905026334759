import { appConstants } from '../helpers/actionType';

export function alertReducer(state = {}, action) {
    switch (action.type) {
        case appConstants.CLEAR:
            return {
                ...state,
                message: ""
            };
        case appConstants.LOADING:
            return {
                appvariables: state.appvariables,
                loading: true
            };
        case appConstants.LOADED:
            return {
                appvariables: state.appvariables,
                loading: false
            };
        case appConstants.SUCCESS:
            return {
                appvariables: state.appvariables,
                type: 'alert-success',
                alertcolor: "#a05f26",
                // alertcolor: "#3BC789",
                alertTitle: "Info",
                message: action.message
            };
        case appConstants.ERROR:
            return {
                appvariables: state.appvariables,
                type: 'alert-danger',
                alertcolor: "#a05f26",
                alertTitle: "Error",
                message: action.message,
                loginFlag:action.loginflag
            };
        default:
            return state
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginActions } from './action/loginAction';
import { withTranslation } from 'react-i18next';
import { appVariables } from './helpers/appVariables';
import { alertActions } from './action/AlertAction';
import Header from './Header/Header';
// import countrycode from './Pages/Home/countrycode.json';
import { history } from './helpers/history';
import { commonClass } from './helpers/commonClass';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: '', otp: '', password: '',
            Countrydetails: [], Selcountry: {},
            showpwd: false,
            Countryfilter: ''
        }
    }
    toggleCountry = () => {
        window.setCountryToggle();
    }

    countryChange = (data) => {
        this.setState({ Selcountry: data, mobileNo: '' });
        window.setCountryToggle();
        this.state.Countryfilter = ''
    }
    handleInputChange(e) {
        const regex = /^[0-9\b]+$/;
        if (regex.test(e.target.value) || e.target.value == '') {
            this.setState({ [e.target.name]: e.target.value });
        }
    }
    componentDidMount(prevState, prevProps) {
        // this.setState({ Selcountry: this.state.Countrydetails.default });
        this.GetCountryList()
    }

    GetOtp = () => {
        if (this.state.mobileNo && this.state.mobileNo != "") {
            if (this.state.mobileNo.length <= this.state.Selcountry.mobNolength && this.state.mobileNo.length >= this.state.Selcountry.mobNoMinlength) {
                this.props.Otpgen(this.state.mobileNo, appVariables.OTP.ForgetPassword, this.props.i18n.language, this.props.user.loginKey, this.state.Selcountry.flag);
            }
            else { this.showMsg('lblValidMobileNo'); }
        }
        else {
            this.showMsg('lblentermobileNo');
        }
    }
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }
    handleChange = (e) => {
        e.preventDefault()
        let name = e.target.name;
        this.setState({ [name]: e.target.value })
        this.state.Countryfilter = e.target.value;
    }

    GetCountryList = () => {
        let new_Version = 0;
        let Version_old = localStorage.getItem('countryver');
        if (this.props.appvariables.bVersion) {
            let totalversion = this.props.appvariables.bVersion.split("~")
            if (totalversion.length > 0) {
                new_Version = totalversion[3];
            }
        }
        if (Version_old != new_Version) {
            commonClass.GetCountry(new_Version)
                .then(response =>
                    response.json()
                )
                .then(data => {
                    localStorage.setItem('countryver', new_Version);
                    localStorage.setItem('countrylst', JSON.stringify(data));
                    this.setState({ Countrydetails: data })
                    this.setState({ Selcountry: data.default }) 
                });
        }
        else {
            let CountryList = JSON.parse(localStorage.getItem('countrylst')); // get from localstorage for countrylist
            this.setState({ Countrydetails: CountryList })
            this.setState({ Selcountry: CountryList.default }) 
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <div className='wrap2'>
                    <div className="logReg">
                        <div className="logRegLi loginSec">
                            <div className="logRegTitle">
                                <img src="./assets/img/icons/login.webp" alt='Forgot Password'/><h2>{t('lblForgetPwd')}</h2>
                            </div>
                            <div className='form'>
                                <div className="userLogCont">
                                    <div className="countryCode">
                                        {this.state.Selcountry && this.state.Selcountry.flag && <a className="countryImg !w-10" style={{ width: '10px' }} onClick={() => this.toggleCountry()}>
                                            <img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${this.state.Selcountry.flag}.png?v0`} alt='Show Flag Icon'/><small>{this.state.Selcountry.Ccname}</small>  {this.state.Selcountry.code}
                                            <i className="fa fa-angle-down"></i>
                                        </a>}
                                        <ul className="selContry scrollbar" style={{ display: "none" }}>
                                            <li><input type="text" value={this.state.Countryfilter} name="Countryfilter" placeholder="Search" onChange={(e) => { this.handleChange(e) }} /></li>
                                            {this.state.Countrydetails && this.state.Countrydetails.other && this.state.Countrydetails.other.filter(country =>
                                                (country.Countryname.includes(this.state.Countryfilter.toUpperCase()) || country.flag.includes(this.state.Countryfilter))).map((data,inx) =>
                                                    <li key={`o_${inx}`}><a onClick={() => this.countryChange(data)}><img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${data.flag}.png?v0`} alt='Show Flag Icon'/>{data.code}</a></li>
                                                )}
                                        </ul>
                                        {this.state.Selcountry && <input type="tel" name='mobileNo' placeholder={`${t('lblMobileNo')}*`} value={this.state.mobileNo} onChange={this.handleInputChange.bind(this)} maxLength={this.state.Selcountry.mobNolength} autocomplete="off" className='form-control lgnusr' required></input>}
                                        {/* text phoneRegister */}
                                    </div>
                                </div>
                                <button onClick={() => { this.GetOtp() }}>{t('lblNext')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user, otp, appvariables } = state.loginReducer;
    return { user, otp, appvariables };
}
const mapActionToProps = {
    login: loginActions.login,
    setPage: loginActions.setPage,
    Otpgen: loginActions.lfnotpgen,
    alertError: alertActions.error,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ChangePassword))
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginActions } from './action/loginAction';
import { withTranslation } from 'react-i18next';
import { appVariables } from './helpers/appVariables';
import { alertActions } from './action/AlertAction';
import Header from './Header/Header';
// import countrycode from './Pages/Home/countrycode.json';
import { history } from './helpers/history';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { commonClass } from './helpers/commonClass';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: '', otp: '', password: '',
            Countrydetails: [], Selcountry: {},
            showpwd: false, TermsAndCond: true,
            Countryfilter: ''
        }
        this.props.logout(this.props.user.mobileNo, 1);
    }
    handleInputChange(e) {
        const regex = /^[0-9\b]+$/;
        if (regex.test(e.target.value) || e.target.value == '') {
            this.setState({ [e.target.name]: e.target.value });
        }
    }
    toggleCountry = () => {
        window.setCountryToggle();
    }
    countryChange = (data) => {
        this.setState({ Selcountry: data, mobileNo: '' });
        window.setCountryToggle();
        this.state.Countryfilter = ''
    }
    handleInputChangePwd(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    // No Use
    // btnGetOTP(e) {
    //     if (this.state.mobileNo && this.state.mobileNo != "") {
    //         if (this.state.mobileNo.length == this.state.Selcountry.mobNolength) {
    //             //flog 1 - for Register, 2 - for Login
    //             this.props.Otpgen(this.state.mobileNo, appVariables.OTP.Login, this.props.i18n.language, this.props.user.loginKey,this.state.Selcountry.flag);
    //         }
    //         else { this.showMsg('lblValidMobileNo'); }
    //     }
    //     else { this.showMsg('lblentermobileNo'); }
    // }

    btnLogin(e) {
        if (this.state.mobileNo && this.state.mobileNo.trim() != "") {
            if (this.state.password && this.state.password.trim() != "") {
                if (this.state.mobileNo.length <= this.state.Selcountry.mobNolength && this.state.mobileNo.length >= this.state.Selcountry.mobNoMinlength) {
                    // if (this.state.TermsAndCond && this.state.TermsAndCond != false) {
                    //flog 1 - for Password Login, 2 - for Otp Login
                    this.state.password = this.state.password.replace(/'/g, "''");
                    this.props.login(this.state.mobileNo, this.state.password, appVariables.LOGIN.Password, this.props.i18n.language, this.props.user.loginKey, this.state.Selcountry.flag);
                    // }
                    // else { this.showMsg(this.props.t('lblAcceptTermCond')); }
                }
                else { this.showMsg('lblValidMobileNo'); }
            }
            else { this.showMsg('lblEnterPwd'); }
        }
        else { this.showMsg('lblentermobileNo'); }
    }
    static getDerivedStateFromProps(props, state) {
        return { otp: props.otp };
    };
    componentDidUpdate(prevState, prevProps) {
        //bcoz alertsucc null value due to action comment already
        // var lstrdata = localStorage.getItem('alertmsg');
        // if (lstrdata != null) {
        //     this.props.alertsucc(lstrdata);
        // }
        if (this.props.otp && this.props.otp.otp && (prevProps.otp == null || prevProps.otp.mobileNo != this.props.otp.mobileNo)) {
            this.showMsg(this.props.otp.otp);
        }
    }
    componentDidMount(prevState, prevProps) {
        // this.setState({ Selcountry: this.state.Countrydetails.default });
        this.GetCountryList()
    }
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }
    HandleCheckBox = (e) => {
        let Ischecked = e.target.checked;
        let name = e.target.name;
        this.setState({ [name]: Ischecked })
    }

    handleChange = (e) => {
        e.preventDefault()
        let name = e.target.name;
        this.setState({ [name]: e.target.value })
        this.state.Countryfilter = e.target.value;
    }

    GetCountryList = () => {
        let new_Version = 0;
        let Version_old = localStorage.getItem('countryver');
        if (this.props.appvariables.bVersion) {
            let totalversion = this.props.appvariables.bVersion.split("~")
            if (totalversion.length > 0) {
                new_Version = totalversion[3];
            }
        }
        if (Version_old != new_Version) {
            commonClass.GetCountry(new_Version)
                .then(response =>
                    response.json()
                )
                .then(data => {
                    localStorage.setItem('countryver', new_Version);
                    localStorage.setItem('countrylst', JSON.stringify(data));
                    this.setState({ Countrydetails: data })
                    this.setState({ Selcountry: data.default })
                });
        }
        else {
            let CountryList = JSON.parse(localStorage.getItem('countrylst')); // get from localstorage for countrylist
            this.setState({ Countrydetails: CountryList })
            this.setState({ Selcountry: CountryList.default })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title>Gulf Ticket - Login</title>
                    <meta name="description" content="Securely Access Your Gulf Ticket Account: Login Now" />
                    <meta name="keywords" content="Gulf Ticket, online raffle draw, Biggest Online Draw" />

                    <meta property="og:title" content="Gulf Ticket - Login" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Securely Access Your Gulf Ticket Account: Login Now" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                </Helmet>}

                <Header />

                <div className='wrap2'>
                    <div className="logReg">
                        <div className="logRegLi loginSec">
                            <div className="logRegTitle">
                                <img src="./assets/img/icons/login.webp" alt='Login Icon'/><h2>{t('lbldSignIn')}</h2>
                            </div>
                            <div className='form'>
                                <div className="userLogCont">
                                    <div className="countryCode">
                                        {this.state.Selcountry && this.state.Selcountry.flag && <a className="countryImg !w-10" style={{ width: '10px' }} onClick={() => this.toggleCountry()}>
                                            <img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${this.state.Selcountry.flag}.png?v0`} alt='Show Flag Icon'/> <small>{this.state.Selcountry.Ccname}</small> <p>{this.state.Selcountry.code}</p>
                                            <i className="fa fa-angle-down"></i>
                                        </a>}
                                        <ul className="selContry scrollbar" style={{ display: "none" }}>
                                            <li><input type="text" value={this.state.Countryfilter} name="Countryfilter" placeholder="Search" onChange={(e) => { this.handleChange(e) }} /></li>
                                            {this.state.Countrydetails && this.state.Countrydetails.other && this.state.Countrydetails.other.filter(country =>
                                                (country.Countryname.includes(this.state.Countryfilter.toUpperCase()) || country.flag.includes(this.state.Countryfilter))).map((data,inx) =>
                                                    <li key={`n_${inx}`}><a onClick={() => this.countryChange(data)}><img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${data.flag}.png?v0`} alt='Show Flag Icon'/>{data.code}</a></li>
                                                )}
                                        </ul>
                                        {this.state.Selcountry && <input type="tel" name='mobileNo' placeholder={`${t('lblMobileNo')}*`} value={this.state.mobileNo} onChange={this.handleInputChange.bind(this)} maxLength={this.state.Selcountry.mobNolength} autocomplete="off" className='form-control lgnusr' required></input>}
                                        {/* text phoneRegister */}
                                    </div>
                                </div>
                                <div className='passwordDiv'>
                                    {this.state.showpwd ? <img src="./assets/img/icons/eyeopen.webp?ver=1.0" alt="show password icon" className="passwordEye" onClick={() => this.setState({ showpwd: false })} /> : <img src="./assets/img/icons/eye-slash.webp?ver=1.0" alt="show password icon" className="passwordEye" onClick={() => this.setState({ showpwd: true })} />}
                                    <input type={this.state.showpwd ? "text" : "password"} style={{ paddingLeft: '10%' }} name='password' placeholder={`${t('lblPwd')}*`} value={this.state.password} maxLength={15} onChange={this.handleInputChangePwd.bind(this)} autocomplete="off" required></input>
                                </div>
                                {/* <div className="checkBox">
                                    <label className="checkLble">{t('lblAgree')} <a className="terms"><Link to="/termscondition" target="_blank">{t('lblTermsandCondition')}</Link>&nbsp;</a>{t('lblWebsite')}
                                        <input className="checkboxInput" type="checkbox" value={this.state.TermsAndCond} name='TermsAndCond' checked={this.state.TermsAndCond} onChange={(e) => { this.HandleCheckBox(e) }}></input>
                                        <span className="checkmark border-color"></span>
                                    </label>
                                </div> */}
                                <button onClick={() => { this.btnLogin() }}>{t('lblLogin')}</button>
                                <span className="frtgotPsw" onClick={() => history.push('/OtpForgetPassword')}><a>{`${t('lblforgetpwdQue')}`}</a></span>
                                {/* <span className="frtgotPsw"><a href="/register">New to Gulf Ticket? Create an account</a></span> */}
                                <span className="frtgotPsw"><a href={`${process.env.REACT_APP_BASENAME}/register`} >{t('lblNewSignUpLink')}  <a style={{ textDecoration: "underline" }}>{t('lblNewSignAc')}</a></a></span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user, otp, appvariables } = state.loginReducer;
    return { user, otp, appvariables };
}
const mapActionToProps = {
    login: loginActions.login,
    setPage: loginActions.setPage,
    Otpgen: loginActions.lfnotpgen,
    alertError: alertActions.error,
    logout: loginActions.logout,
    //alertsucc: alertActions.success
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Login))
import React, { Component } from 'react';
import Header from './Header/Header';
import { withTranslation } from 'react-i18next';
import { loginActions } from './action/loginAction';
import { alertActions } from './action/AlertAction';
import { connect } from 'react-redux';
import { appVariables } from './helpers/appVariables';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: ['', '', '', '', '', ''],
            newPwd: "",
            ConfirmPwd: "",
            shownewpwd: false,
            showconfrimpwd: false,
            myRTime: 0,
            timeFinished: false,
            myRefNo: 0,
        }
        this.handleChange = this.handleChange.bind()
    }
    handleChange = (e) => {
        try { this.setState({ [e.target.name]: e.target.value }); }
        catch (ex) { }
    }
    static getDerivedStateFromProps(props, state) {
        return { otp: props.otp };
    };
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }
    handleInputChange = (value, e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(prevState => ({ list: prevState.list.map((obj, inx) => (inx === value ? e.target.value : obj)) }));
            if (((this.state.list.length - 1) > value) || e.target.value == "") {
                let Check = `input[name='${e.target.value !== "" ? (parseInt(value) + 1) : ((value !== 0) ? parseInt(value) - 1 : parseInt(value))}']`;
                document.querySelector(`input[name='${e.target.value !== "" ? (parseInt(value) + 1) : ((value !== 0) ? parseInt(value) - 1 : parseInt(value))}']`).focus();
                // document.querySelector(`input[name=2`).focus()
            }
        }
    }

    btnForgetPwd = () => {
        if (this.state.newPwd && this.state.newPwd.trim() != "" && this.state.ConfirmPwd && this.state.ConfirmPwd.trim() != "") {
            let _otp = this.state.list.join('');
            if (_otp.length === 6) { }
            else { this.showMsg('lblEnterValidOtp'); return; }
            let regEx = /^(?=.*?[0-9])(?=.*?[^0-9A-Za-z]).{8,15}$/;
            if (regEx.test(this.state.newPwd)) {
                // if (this.state.newPwd.length > 2 && this.state.ConfirmPwd.length > 2 ) {
                if (this.state.newPwd == this.state.ConfirmPwd) {
                    this.state.newPwd = this.state.newPwd.replace(/'/g, "''");
                    this.props.changepassword(this.props.otp.mobileNo, _otp, this.state.newPwd, appVariables.PASSWORD_FLAG.ForgetPasswordFlag, this.props.i18n.language, this.props.user.loginKey, this.state.otp.Ccode)
                    this.setState({ newPwd: '', ConfirmPwd: '' })
                }
                else {
                    this.showMsg('lblPwdMismatch');
                }
            }
            else { this.showMsg('lblReqPasswordPolicy'); }
            // else {
            //     this.showMsg('lblMinlen');
            // }
        }
        else {
            this.showMsg('lblPleaseFillDet');
        }
    }

    ResendOtp = () => {
        if (this.props.otp.mobileNo && this.props.otp.mobileNo != "") {
            if (this.state.timeFinished == false) {
                // this.props.alertError(this.props.t("Try after OTP expired"));                
                return;
            }
            if (this.props.otp.flog == 3) {
                this.props.Otpgen(this.props.otp.mobileNo, appVariables.OTP.ForgetPassword, this.props.i18n.language, this.props.user.loginKey, this.state.otp.Ccode)
            }
        }
    }
    componentDidUpdate(previousProps, previousState) {
        if (this.props.otp && this.props.otp.ExpSec && this.props.otp.ExpSec > 0) {
            if (this.state.myRefNo != this.props.otp.RefNo) {//to check new otp after click resend btn
                this.state.myRefNo = this.props.otp.RefNo;
                this.state.myRTime = 0;
                this.state.timeFinished = false;
            }
            if (this.state.timeFinished == true) //check the time completed
            {
                return;
            }
            let tot = 0
            if (this.state.myRTime > 0) {
                tot = this.state.myRTime;
            }
            else {
                tot = this.props.serverTime + (this.props.otp.ExpSec * 1000);
                this.state.myRTime = tot;
            }
            let _rtime = tot - this.props.serverTime;
            let second = 1000
            let minute = second * 60
            let hour = minute * 60
            let day = hour * 24
            let _mintime = Math.floor(_rtime % day % hour / minute);
            let _sectime = Math.floor(_rtime % day % hour % minute / second);
            this.state.Rtime = _mintime + " : " + _sectime;
            if (_mintime <= 0 && _sectime <= 0) {
                this.state.Rtime = "";
                this.state.timeFinished = true;
            }
        }
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <Header />
                <div className="wrap2">
                    <div className="logReg">
                        <div className="logRegLi loginSec">
                            <div className="logRegTitle">
                                <img src="./assets/img/icons/login.webp" alt='Forgot PassWord'/><h2>{t('lblForgetPwd')}</h2>
                            </div>
                            <div className="otp">
                                <div className="otpCont">
                                    <h3>{t('lblOTP')}</h3>
                                    <p>{t('lblSendSixDigitCode')} <span dir='ltr'>{this.props.otp && this.props.otp.Ccode} {this.props.otp.mobileNo.substring(0, 3)}*****{this.props.otp.mobileNo.substring(this.props.otp.mobileNo.length - 2, this.props.otp.mobileNo.length)}</span> {t('lblVlaidateNo')}.</p>
                                    {/* <p>{t('lblOtpGen')}:{this.props.otp && this.props.otp.otp}</p> */}
                                    <p>{this.props.otp && this.props.otp.otp}</p>
                                    <div className="otpSec flex gap-4">
                                        {this.state.list.map((digit, idx) => (
                                            <input  autoFocus={idx === 0} key={idx} name={idx} type="text" inputMode="numeric" autoComplete="one-time-code" pattern="\d{1}" maxLength={1} className="otp-input" value={digit.value} onKeyUp={this.handleInputChange.bind(this, idx)} style={{ width: "70px" }} />
                                        ))}
                                    </div>
                                    <div className="reSendBtn rtl:flex"><p>{t('lblDidnotReceiveSMS')}</p> <a onClick={() => this.ResendOtp()} style={{ color: (this.state.timeFinished ? "#494949" : "#878585") }}>{t('lblResend')}</a>
                                        <div style={{ color: '#d90606' }}>{this.state.Rtime ? this.state.Rtime + "s" : ""}</div>
                                    </div>
                                    <div className="walletTrans">
                                        <div className='form'>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('lblNewPwd')}<small className="requiredSymbol">*</small></label>
                                                    <div className="PswImg">
                                                        <span><img src="./assets/img/icons/message_01.webp" alt="show Message icon"/></span>
                                                        <input type={this.state.shownewpwd ? "text" : "password"} placeholder="********" id="txtNewPwd" name="newPwd" value={this.state.newPwd} onChange={(e) => this.handleChange(e)} maxLength={15} />
                                                        {this.state.shownewpwd ? <img src="./assets/img/icons/eyeopen.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ shownewpwd: false })} /> : <img src="./assets/img/icons/eye-slash.webp?ver=1.0" alt="show password icon" className="currentPassEye" onClick={() => this.setState({ shownewpwd: true })} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>{t('lblConfirmpwd')}<small className="requiredSymbol">*</small></label>
                                                    <div className="PswImg">
                                                        <span><img src="./assets/img/icons/circle_password.webp" alt="show Circle Lock icon"/></span>
                                                        <input type={this.state.showconfrimpwd ? "text" : "password"} placeholder="********" id="txtConfirmPwd" name="ConfirmPwd" value={this.state.ConfirmPwd} onChange={(e) => this.handleChange(e)} maxLength={15} />
                                                        {this.state.showconfrimpwd ? <img src='./assets/img/icons/eyeopen.webp?ver=1.0' alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showconfrimpwd: false })} /> : <img src='./assets/img/icons/eye-slash.webp?ver=1.0' alt="show password icon" className="currentPassEye" onClick={() => this.setState({ showconfrimpwd: true })} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small style={{ color: "#b1b2ba", fontSize: "small" }}><i className="fa fa-info-circle" style={{ fontFamily: "fontawesome-webfont" }}></i> {t('lblPolicyInPwd')}</small>
                                        <button onClick={() => { this.btnForgetPwd() }} style={{ width: "100%", float: "left", marginTop: "30px", padding: "7px 20px", color: "#ffffff", background: "#df911a", textTransform: "uppercase", fontFamily: "SourceSans3-Bold", borderRadius: "25px", fontSize: "20px", border: "1px solid #df911a" }}>{t('lblSubmit')}</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { serverTime } = state.commonReducer;
    const { user, otp } = state.loginReducer;
    return { user, otp, serverTime };
}
const mapActionToProps = {
    login: loginActions.login,
    setPage: loginActions.setPage,
    Otpgen: loginActions.lfnotpgen,
    alertError: alertActions.error,
    changepassword: loginActions.changepassword,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ForgetPassword));
import { commonClass } from "../helpers/commonClass"
//https://github.com/jonbern/fetch-retry?tab=readme-ov-file
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
export const userService = {
    login,
    otpgeneration,
    Register,
    getBalance,
    changepassword,
    captchaVerify,
    Registerotp,
    RegisterActivation,
    ExternalLogin,
    logout,
    getBanner
};
const _apiUrl = process.env.REACT_APP_API_URL;

async function postFetch(_url, data, mobileNo, langID, loginKey, CCode, retrycnt = 0) {
    try {
        let _option = await commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode);
        return fetch(`${_apiUrl}${_url}/`, {
            ..._option,
            //retryDelay: 1000,
            retryOn: function (attempt, error, response) {
                // retry on any network error, or 4xx or 5xx status codes
                // if (error !== null || response.status == 403) {
                    if (response && response.status == 401) {
                    console.log(`retrying, attempt number ${attempt + 1}`);
                    //return attempt >= 2 ? false : true;         
                    if (retrycnt < 1) {
                        retrycnt++;
                        postFetch(_url, data, mobileNo, langID, loginKey, CCode, retrycnt);
                    }
                    return false
                }
            }
        })
            .then(commonClass.handleResponse)
            .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "postFetch=>" + _url);
    }
}
async function getFetch(_url, mobileNo, langID, loginKey,Ccode, retrycnt = 0) {
    try {
        let _option = await commonClass.getRequestOptions(mobileNo, langID, loginKey,Ccode);
        return fetch(`${_apiUrl}${_url}/`, {
            ..._option,
            retryOn: function (attempt, error, response) {
                // retry on any network error, or 4xx or 5xx status codes
                // if (error !== null || response.status == 403) {
                    if (response && response.status == 401) {
                    console.log(`retrying, attempt number ${attempt + 1}`);
                    //return attempt >= 2 ? false : true;         
                    if (retrycnt < 1) {
                        retrycnt++;
                        getFetch(_url, mobileNo, langID, loginKey,Ccode, retrycnt)
                    }
                    return false
                }
            }
        })
            .then(commonClass.handleResponse)
            .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "getFetch=>" + _url);
    }
}

function login(data, mobileNo, langID, loginKey, CCode) {
    try {
        return postFetch('player_login', data, mobileNo, langID, loginKey, CCode);
        // return fetch(`${_apiUrl}player_login/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode))
        //     .then(commonClass.handleResponse)
        //     .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "login");
    }
};
function Registerotp(data, mobileNo, langID, loginKey, CCode) {
    try {
        return postFetch('player_register', data, mobileNo, langID, loginKey, CCode);
        //return fetch(`${_apiUrl}player_creation/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode))
        // return fetch(`${_apiUrl}player_register/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode))
        //     .then(commonClass.handleResponse)
        //     .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "Register");
    }
};
function otpgeneration(data, mobileNo, langID, loginKey, CCode) {
    try {
        return postFetch('otpgeneration', data, mobileNo, langID, loginKey, CCode);
        // return fetch(`${_apiUrl}otpgeneration/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode))
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     });
    }
    catch (ex) { commonClass.writeLog(ex, "otpgeneration"); }
};
function getBalance(mobileNo, langID, loginKey,Ccode) {
    try {
        return getFetch('get_balance', mobileNo, langID, loginKey,Ccode);
        // return fetch(`${_apiUrl}get_balance/`, commonClass.getRequestOptions(mobileNo, langID, loginKey))
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     });
    }
    catch (ex) {
        commonClass.writeLog(ex, "getBalance");
    }
};
function changepassword(data, mobileNo, langID, loginKey,Ccode) {
    try {
        return postFetch('passwordchange', data, mobileNo, langID, loginKey,Ccode);
        // return fetch(`${_apiUrl}passwordchange/`, commonClass.requestOptions(data, mobileNo, langID, loginKey))
        //     .then(commonClass.handleResponse)
        //     .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "changepassword");
    }
};
function captchaVerify(data, mobileNo, langID, loginKey) {
    try {
        return postFetch('captchaverify', data, mobileNo, langID, loginKey);
        // return fetch(`${_apiUrl}captchaverify/`, commonClass.requestOptions(data, mobileNo, langID, loginKey))
        //     .then(commonClass.handleResponse)
        //     .then(user => { return user; });
    }
    catch (ex) {
        commonClass.writeLog(ex, "captchaVerify");
    }
};

function Register(data, mobileNo, langID, loginKey, CCode) {
    try {
        return postFetch('otpgeneration_register', data, mobileNo, langID, loginKey, CCode);
        // return fetch(`${_apiUrl}otpgeneration_register/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, CCode))
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     });
    }
    catch (ex) { commonClass.writeLog(ex, "Registerotp"); }
}
function RegisterActivation(data, mobileNo, langID, loginKey, Ccode) {
    try {
        return postFetch('profile_register', data, mobileNo, langID, loginKey, Ccode);
        // return fetch(`${_apiUrl}profile_register/`, commonClass.requestOptions(data, mobileNo, langID, loginKey, Ccode))
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     });
    }
    catch (ex) { commonClass.writeLog(ex, "RegisterActivation"); }
}
function ExternalLogin(data, mobileNo) {
    try {
        return postFetch('player_session_validation', data, mobileNo);
        // return fetch(`${_apiUrl}player_session_validation/`, commonClass.requestOptions(data, mobileNo))
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     });
    }
    catch (ex) {
        commonClass.writeLog(ex, "ExternalLogin");
    }
}

function logout(data, mobileNo, langID, loginKey) {
    try {
        return postFetch('logout', data, mobileNo, langID, loginKey);
        // return fetch(`${_apiUrl}logout/`, commonClass.requestOptions(data, mobileNo)
        //     .then(commonClass.handleResponse)
        //     .then(user => {
        //         return user;
        //     })
        // )
    }
    catch (ex) {

    }
}
function getBanner(mobileNo, langID, loginKey) {
    try{
        // return getFetch('get_banner_version', mobileNo, langID, loginKey); non catching api url
        return getFetch('get_banner_version_arc', mobileNo, langID, loginKey); // get_banner_version_arc
    }
    catch(ex){
        commonClass.writeLog(ex, "getBanner");
    }
    }

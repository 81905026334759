import React, { Component } from 'react';
import Header from '../../Header/Header';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class UnderConstruction extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        return (<>
            <Header />
            <div className="wrap2">
                <div>
                    <span></span>
                    <h3 style={{ textAlign: "center", fontSize: "30px", padding: "20px", marginTop: "20px" }}>{t('lblUnderConst')}</h3>
                </div>
            </div>
        </>);
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(UnderConstruction))
import { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { history } from '../../helpers/history';
class HowToPlay extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<section className="howToPlay bg-brand">
					<div className="wrap1">
						<h1 className="headding1 text-white">{t('lblHowToPlay')}</h1>
						<ul className="text-white">
							<li>
								<div className="htpCuntNo bg-white text-brand">1</div>
								<div className="htpCuntImg"><img src="./assets/img/htp-1.svg?v0.1" /></div>
								{/* <p><span className="font-[quinn-regular]">{t('lblRegister')} {t('lblOr')} {t('lblLogin')} {t("lblYourAcc")}</span></p> */}
								<p><span className="font-[quinn-regular]">{t('lblHowToPlayRegOrLog')}</span></p>
							</li>
							<li>
								<div className="htpCuntNo bg-white text-brand">2</div>
								<div className="htpCuntImg"><img src="./assets/img/htp-2.svg?v0.1" /></div>
								{/* <p><span>{t('lblSel')} {t("lblGameChoice")}</span></p> */}
								<p><span>{t('lbHowToPlayPurchase')}</span></p>
							</li>
							<li>
								<div className="htpCuntNo bg-white text-brand">3</div>
								<div className="htpCuntImg"><img src="./assets/img/htp-3.svg?v.0.2" /></div>
								{/* <p><span>{t('lblSel')} {t("lblYourFavNo")}</span></p> */}
								<p><span>{t('lblHowToPlayParticipate')}</span></p>
							</li>
							<li>
								<div className="htpCuntNo bg-white text-brand">4</div>
								<div className="htpCuntImg"><img src="./assets/img/htp-4.svg?v0.1" /></div>
								{/* <p><span>{t('lblComThePur')} {t("lblWaterBottles")}</span></p> */}
								<p><span>{t('lblHowToPlayFavNo')}</span></p>
							</li>
							<li>
								<div className="htpCuntNo bg-white text-brand">5</div>
								<div className="htpCuntImg"><img src="./assets/img/htp-5.svg?v0.1" /></div>
								{/* <p><span>{t('lblWatch')} {t("lblLiveDraw")}</span></p> */}
								<p><span>{t('lblHowToPlayLiveDraw')}</span></p>
							</li>
						</ul>
						{/* style={{ float: 'right', cursor: 'pointer' }} */}
						{this.props.ispage == false && <p onClick={() => { history.push('/HowtoPlay') }}><span className='howtoplaybtn'>{t('lblKnowMore')}</span></p>}
					</div>
				</section>

				{this.props.ispage != false &&
					<section className="howToPlay">
						<div className="wrap1">
							<div className="htpCont">
								<h4>{t('lblHowTosignup')}</h4>
								<div className="videoSec">
									<iframe
										width="100%"
										height="auto"
										title="YouTube video player"
										// frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										className="rounded-md iframeborder"
										allowFullScreen
										src="https://www.youtube.com/embed/wAvK6w99j2w?si=ykKro0HfQ3Ghc-q2&rel=0"
									>
									</iframe>
								</div>
							</div>

							<div className="htpCont">
								<h4>{t('lblHowTopurchase')}</h4>
								<div className="videoSec">
									<iframe
										width="100%"
										height="auto"
										title="YouTube video player"
										// frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										className="rounded-md iframeborder"
										allowFullScreen
										src="https://www.youtube.com/embed/Jt9ZBNdyaDM?si=oaQxcK2Yh-Q5M8V9&rel=0"
										// style={{border:"0px"}}
									>
									</iframe>
								</div>
							</div>


							<div className="htpCont">
								<h4>{t('lblHowToWdraw')}</h4>
								<div className="videoSec">
									<iframe
										width="100%"
										height="auto"
										title="YouTube video player"
										// frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
										className="rounded-md iframeborder"
										allowFullScreen
										src="https://www.youtube.com/embed/bXIJgPE0Vjs?si=iSmibpGIvxo6bgzb&rel=0" 
										// style={{border:"0px"}}
									>
									</iframe>
								</div>
							</div>

						</div>
					</section>
				}
			</>
			// 	<video width="100%" height="auto" controls>
			// 	<source src="movie.mp4" type="video/mp4" />
			// 	<source src="movie.ogg" type="video/ogg" />
			// 	Your browser does not support the video tag.
			// </video>
		)
	}

}
export default withTranslation()(HowToPlay)


import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { history } from '../../helpers/history';

class Promotionterms extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div className="wrap2 contentalign">
                    <button type="button" className="backBtn" onClick={() => { history.goBack() }}>
                        <img src="./assets/img/icons/Back-Icon.svg" alt='Back-Icon'/>
                        <span>{t('lblBack')}</span>
                    </button>
                    <div className="addlPage">
                        <div className="text-center text-brand text-2xl md:text-3xl font-bold tracking-wide">
                            LAUNCH EXTRAVAGANZA
                        </div>

                        <div className="text-xl text-center text-smallCaps font-bold">
                            Offer lasts only 3 Weeks after launch.
                        </div>

                        <div className="flex gap-5 flex-col items-start mt-6">
                            <h2 className="text-2xl font-bold">Terms & Conditions</h2>
                            <div>
                                <h4 className="text-lg font-bold">Eligibility</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        This offer is open to all Indians aged 18 years or older, regardless of their
                                        location.
                                    </li>
                                    <li>
                                        Employees of Gulf Ticket, its affiliates, and their immediate families are
                                        ineligible to participate.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h4 className="text-lg font-bold">Campaign Period:</h4>
                                <p>
                                    The "Launch Extravaganza" offer begins on February 7th, 2024 at launch and ends on
                                    March 9th, 2024 at 09:30 PM.
                                </p>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Participation:</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                <li>
									All users registered on the Gulf Ticket website are eligible for the Launch offer.
								</li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Prize:</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        During the Launch Offer phase: :
                                        <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                            <li>Fortune 5 will have 5 guaranteed raffle winners.</li>
                                            <li>Super 6 will have 10 guaranteed raffle winners.</li>
                                            <li>The guaranteed raffle prize will be AED 10,000 each.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Selection & Notification:</h4>

                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>Raffle Winners will be selected randomly by the system.</li>
                                    <li>Raffle Winners are announced during the show at 08:00 PM UAE time onwards.</li>
                                    <li>
                                        Winners will be notified via email and phone number provided during registration
                                        within 48 hours of the draw.
                                    </li>
                                    <li>
                                        By participating in this offer, winners agree to allow Gulf Ticket to use their
                                        photo, details, and testimonials for marketing and promotional purposes. Winner
                                        consent is mandatory to claim the prize.
                                    </li>
                                    <li>Winners must provide valid legal identification proof to claim the prize.</li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Prize Distribution:</h4>

                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        All prizes, including those for winners from February 7th onwards, will be
                                        distributed within the stipulated time frame.
                                    </li>
                                    <li>
                                        Winners must claim their prize within 10 days from the date of notification.
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">General:</h4>

                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        Gulf Ticket reserves the right to modify or amend these terms and conditions at
                                        any time without prior notice. Any changes will be communicated through
                                        appropriate channels, including the official website and social media platforms.
                                    </li>
                                    <li>
                                        Participants agree to abide by all terms and conditions outlined herein and the
                                        general Terms & Conditions of Gulf Ticket.
                                    </li>

                                    <li>
                                        Gulf Ticket is not responsible for any technical failures or errors that may
                                        prevent participation in the offer.
                                    </li>
                                    <li>
                                        All decisions made by Gulf Ticket regarding the offer are final and binding.
                                    </li>
                                    <li>This offer is subject to all applicable laws and regulations in the UAE.</li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Additional Notes:</h4>

                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        Gulf Ticket encourages responsible gaming and recommends playing within financial
                                        limits.
                                    </li>
                                    <li>
                                        Please visit the Gulf Ticket website for more information on the online draws and
                                        responsible gaming practices.
                                    </li>

                                    <li>
                                        For any enquiries or clarifications regarding the offer, please contact Gulf
                                        Ticket customer support.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Promotionterms)

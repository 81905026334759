export const appConstants = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_ERROR: 'REGISTER_ERROR',

    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    GET_GAMEFAMILY_SUCCESS: 'GET_GAMEFAMILY',
    GET_GAMEFAMILY_ERROR: 'GET_GAMEFAMILY',

    SET_GAMEFAMILY: 'SET_GAMEFAMILY',

    //GET_GAME_SUCCESS: 'GET_GAME_SUCCESS',
    //GET_GAME_ERROR: 'GET_GAME_ERROR',

    GET_AVAILDRAWS_SUCCESS: "GET_AVAILDRAWS_SUCCESS",
    GET_AVAILDRAWS_ERROR: "GET_AVAILDRAWS_ERROR",

    GET_SERVERTIME_SUCCESS: "GET_SERVERTIME_SUCCESS",
    GET_SERVERTIME_ERROR: "GET_SERVERTIME_ERROR",

    SET_DRAW: "SET_DRAW",

    ADD_CARTITEM: 'ADD_CARTITEM',
    UPDATE_CARTITEM: 'UPDATE_CARTITEM',
    REMOVE_CARTITEM: 'REMOVE_CARTITEM',
    CLEAR_CARTITEM: 'CLEAR_CARTITEM',
    REMOVE_CLOSEDDRAW_CARTITEM: 'REMOVE_CLOSEDDRAW_CARTITEM',

    BUY_SUCCESS: 'BUY_SUCCESS',
    BUY_ERROR: 'BUY_ERROR',
    LOADING: 'LOADING',
    LOADED: 'LOADED',



    GET_BET_HISTORY: 'GET_BET_HISTORY',
    GET_ACCOUNT_HISTORY: 'GET_ACCOUNT_HISTORY',
    GET_ACCOUNT_ACTIVITY: 'GET_ACCOUNT_ACTIVITY',
    GET_RESULT_REPORT: 'GET_RESULT_REPORT',
    GET_ACCOUNT_SUMMARY: 'GET_ACCOUNT_SUMMARY',
    ADD_MONEY_SUCCESS: 'ADD_MONEY_SUCCESS',
    WITHDRAW_MONEY_SUCCESS: 'WITHDRAW_MONEY_SUCCESS',
    SET_PAGE_MENU: 'SET_PAGE_MENU',
    UPDATE_STAKE_ALL: "UPDATE_STAKE_ALL",

    GET_LATEST_RESULT_SUCCESS: "GET_LATEST_RESULT_SUCCESS",
    GET_LATEST_RESULT_ERROR: "GET_LATEST_RESULT_ERROR",
    GET_CURRENT_RESULT: "GET_CURRENT_RESULT",
    GET_USER_PROFILE: "GET_USER_PROFILE",

    SET_SEARCH_TICKET: "SET_SEARCH_TICKET",
    SET_PAGEID: "SET_PAGEID",
    SET_LOGIN_OTP: "SET_LOGIN_OTP",
    GET_SLOT_GAMES: "GET_SLOT_GAMES",
    GET_TRANSACTION_DATA: "GET_TRANSACTION_DATA",
    GET_DEPOSITE_SUCESS: "GET_DEPOSITE_SUCESS",
    GET_CASHOUT_SUCESS: "GET_CASHOUT_SUCESS",
    GET_BALANCE: 'GET_BALANCE',
    GET_PROFILE_DETAILS: 'GET_PROFILE_DETAILS',
    SET_ECAPTCHA: 'SET_ECAPTCHA',
    GET_REPRINT_DATA: 'GET_REPRINT_DATA',
    GET_PROFILE_DETAILS_ERROR: 'GET_PROFILE_DETAILS_ERROR',
    GET_POPUPLAR_GAMES: "GET_POPUPLAR_GAMES",
    GET_ODDS_CONFIGLIST: "GET_ODDS_CONFIGLIS",
    GET_REPORT_DETAILS: 'GET_REPORT_DETAILS',
    UPDATE_PROFILE_IMG: 'UPDATE_PROFILE_IMG',
    ORDER_PAYTYPE_ID: 'ORDER_PAYTYPE_ID',
    My_FAV_NUMBERS: 'My_FAV_NUMBERS',
    GET_PREVIOUS_RESULT_SUCCESS: 'GET_PREVIOUS_RESULT_SUCCESS',
    GET_PREVIOUS_RESULT_ERROR: 'GET_PREVIOUS_RESULT_ERROR',
    GET_FAV_NUMBERS: 'GET_FAV_NUMBERS',
    DELETE_FAV_NUMBERS: 'DELETE_FAV_NUMBERS',
    PRICE_BREAKDOWN_DATA:'PRICE_BREAKDOWN_DATA',
    GET_RAFFLE_RESULTS:"GET_RAFFLE_RESULTS",
    ANOTHER_GET_PREVIOUS_RESULT_SUCCESS:"ANOTHER_GET_PREVIOUS_RESULT_SUCCESS",
    ANOTHER_GET_PREVIOUS_RESULT_ERROR:"ANOTHER_GET_PREVIOUS_RESULT_ERROR",
    GET_BANNER_VERSION:"GET_BANNER_VERSION",
    GET_USER_RATINGS:"GET_USER_RATINGS",
    GET_ORDER_DATA:"GET_ORDER_DATA"
}

import { appConstants } from '../helpers/actionType';
import { accountService } from '../services/accountService';
import { commonClass } from "../helpers/commonClass";
import { alertActions } from './AlertAction';
import { loginActions } from './loginAction';

export const accountActions = {
    GetTransactionDel,
    Cashdesposite,
    Cashoutmoney,
    GetProfile,
    UpdateProfile,
    ClearCashdesposite,
    ClearCashout,
    ConfirmVerify,
    GetAllResult,
    GetPlayerLedger,
    GetPriceBreakdownlst,
    GetOrderDel
}
function GetProfile(mobileNo, langID, sessionKey, country_code) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = `'${mobileNo}','${langID}'`
            accountService.getProfile(mobileNo, langID, sessionKey, country_code)
                .then(
                    details => {
                        dispatch({ type: appConstants.LOADED });
                        if (details.result.Status_id == 0) {
                            details = details.result;
                            dispatch({ type: appConstants.GET_PROFILE_DETAILS, details });
                        }
                        else if (details.result.Status_id == 5) { //5 login Id mismatch
                            setTimeout(() => {
                                loginActions.logout();
                            }, 1000);
                            dispatch(alertActions.error(details.result.Message));
                        }
                        else {
                            dispatch(alertActions.error(details.result.Message));
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch({ type: appConstants.GET_PROFILE_DETAILS_ERROR, error })
                    }
                )
        }
    }

    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function UpdateProfile(player_image, player_name, player_lname, email, kyc_no, dob, city, country_code, contact_number, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            commonClass.SendEventTracking(18, {})
            let data = ({ "player_image": player_image, "player_name": player_name, "player_lname": player_lname, "email": email, "kyc_no": kyc_no, "dob": dob, "city": city, "country_code": country_code, "contact_number": contact_number })
            accountService.updateProfile(data, mobileNo, langID, sessionKey)
                .then(
                    details => {
                        dispatch({ type: appConstants.LOADED });
                        if (details.result.Status_id == "0") {
                            let img = player_image;
                            let name = player_name;
                            dispatch({ type: appConstants.UPDATE_PROFILE_IMG, img, name, email });
                            let strArr = details.result.Message;
                            dispatch(alertActions.success(strArr));
                        }
                        else {
                            let strArr = details.result.Message;
                            dispatch(alertActions.error(strArr));
                        }

                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = error.message;
                        dispatch(alertActions.error(error));
                    }
                )
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}
function GetTransactionDel(game_id, fromDate, toDate, typeID, mobileNo, langID, sessionKey) {
    //typeID 1-order ,2 -transaction
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let pg_type_id = 1;
            let data = { "from_date": fromDate, "to_date": toDate, "game_group_id": game_id, "type_id": typeID, "pg_type_id": pg_type_id }
            accountService.GetTransactionDel(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let TransactionData = data;
                        dispatch({ type: appConstants.LOADED });
                        if (TransactionData.result.Status_id == 5) {// 5-login Id mismatch
                            setTimeout(() => {
                                loginActions.logout();
                            }, 1000);
                            dispatch(alertActions.error(TransactionData.result.Message));
                        }
                        else if (TransactionData.result.Status_id == 1) {
                            TransactionData = [];
                            dispatch({ type: appConstants.GET_TRANSACTION_DATA, TransactionData });
                        }
                        else {
                            dispatch({ type: appConstants.GET_TRANSACTION_DATA, TransactionData });
                        };
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let TransactionData = [];
                        dispatch({ type: appConstants.GET_TRANSACTION_DATA, TransactionData });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function Cashdesposite(data, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            // let data ={"type_id":1,"amount":10,"pg_type_id":123456789,"pg_txn_id":"123456","email":"String","item_description":"String"};
            accountService.Cashdesposite(data, mobileNo, langID, sessionKey)
                .then(
                    deposite => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_DEPOSITE_SUCESS, deposite });
                        let balance = deposite.result.Balance;
                        let winbalance = deposite.result.Win_Balance;
                        dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                        if (deposite.result && deposite.result.Status_id == 0) {
                            dispatch(alertActions.success(deposite.result.Message.split(':')[0] + "" + parseFloat(deposite.result.Message.split(':')[1] / 100)));
                        }
                        else {
                            dispatch(alertActions.error(deposite.result.Message));
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = [];
                        dispatch({ type: appConstants.GET_DEPOSITE_SUCESS, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function Cashoutmoney(data, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            accountService.Cashoutmoney(data, mobileNo, langID, sessionKey)
                .then(
                    cashout => {
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_CASHOUT_SUCESS, cashout });
                        if (cashout.result.Status_id == 0) {
                            let balance = cashout.result.Balance;
                            let winbalance = cashout.result.Win_balance;
                            dispatch({ type: appConstants.GET_BALANCE, balance, winbalance });
                            // dispatch(alertActions.success(cashout.result.Message.split(':')[0] + "" + parseFloat(cashout.result.Message.split(':')[1] / 100)));
                            dispatch(alertActions.success(cashout.result.Message.split(':')[0]));
                        }
                        else if (cashout.result.Status_id == 5) {//5-for loginID Mismatch                                                                                     
                            setTimeout(() => {
                                loginActions.logout();
                            }, 1000);
                            dispatch({ type: appConstants.LOGOUT })
                            dispatch(alertActions.error(cashout.result.Message));
                        }
                        else {
                            dispatch(alertActions.error(cashout.result.Message));
                        }

                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        error = [];
                        dispatch({ type: appConstants.GET_CASHOUT_SUCESS, error });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function ClearCashdesposite() {
    return dispatch => {
        let deposite = [];
        dispatch({ type: appConstants.GET_DEPOSITE_SUCESS, deposite });
    }
}
function ClearCashout() {
    return dispatch => {
        let cashout = [];
        dispatch({ type: appConstants.GET_CASHOUT_SUCESS, cashout });
    }
}

function ConfirmVerify(type_id, player_name, dob, nationality, id_type, id_no, address, image, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            let data = ({ "type_id": type_id, "player_name": player_name, "dob": dob, "nationality": nationality, "id_type": id_type, "id_no": id_no, "address": address, "proof": image })
            accountService.GetConfirmkyc(data, mobileNo, langID, sessionKey)
                .then(
                    details => {
                        let strArr = details.result.Message;
                        dispatch(alertActions.success(strArr));
                    },
                    error => {
                        error = error.message;
                        dispatch(alertActions.error(error));
                    }
                )
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function GetAllResult(gg_id, mobileNo, fromDate, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "date": fromDate, "game_group_id": gg_id };
            accountService.GetAllResult(data, mobileNo, langID, sessionKey)
                .then(
                    _data => {
                        let results = _data.result;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_RESULT_REPORT, results });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let results = [];
                        dispatch({ type: appConstants.GET_RESULT_REPORT, results });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetPlayerLedger(ReportType, fromDate, toDate, mobileNo, langID, loginKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let data = { "type_id": ReportType, "from_date": fromDate, "to_date": toDate };
            accountService.GetPlayerLedger(data, mobileNo, langID, loginKey)
                .then(
                    data => {
                        let LedgerData = data;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_REPORT_DETAILS, LedgerData });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let LedgerData = [];
                        dispatch({ type: appConstants.GET_REPORT_DETAILS, LedgerData });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
}

function GetPriceBreakdownlst(mobileNo, langID, sessionKey, Ccode) {
    try {
        return dispatch => {
            //dispatch({ type: appConstants.LOADING });
            accountService.GetPriceBreakdownlst(mobileNo, langID, sessionKey, Ccode)
                .then(
                    data => {
                        let breakdownlist = data;
                        breakdownlist && breakdownlist.result && breakdownlist.result.forEach(element => {
                            let amontandflag = element.winamt;
                            element.winamt = amontandflag.split('|')[0];
                            element.starFlag = amontandflag.split('|')[1]
                        });
                        //dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.PRICE_BREAKDOWN_DATA, breakdownlist });
                    },
                    error => {
                        // dispatch({ type: appConstants.LOADED });
                        let breakdownlist = [];
                        dispatch({ type: appConstants.PRICE_BREAKDOWN_DATA, breakdownlist });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetOrderDel(game_id, fromDate, toDate, typeID, mobileNo, langID, sessionKey) {
    //typeID 1-order ,2 -transaction
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            let pg_type_id = 1;
            let data = { "from_date": fromDate, "to_date": toDate, "game_group_id": game_id, "type_id": typeID, "pg_type_id": pg_type_id }
            accountService.GetTransactionDel(data, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let TransactionData = data;
                        dispatch({ type: appConstants.LOADED });
                        if (TransactionData.result.Status_id == 5) {// 5-login Id mismatch
                            setTimeout(() => {
                                loginActions.logout();
                            }, 1000);
                            dispatch(alertActions.error(TransactionData.result.Message));
                        }
                        else if (TransactionData.result.Status_id == 1) {
                            TransactionData = [];
                            dispatch({ type: appConstants.GET_ORDER_DATA, TransactionData });
                        }
                        else {
                            dispatch({ type: appConstants.GET_ORDER_DATA, TransactionData });
                        }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let TransactionData = [];
                        dispatch({ type: appConstants.GET_ORDER_DATA, TransactionData });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
import { appConstants } from "../helpers/actionType";
let PastResult = [];
try {
    PastResult = JSON.parse(localStorage.getItem('prresult'));
    PastResult = PastResult ? PastResult : [];
}
catch (ex) {

}
const initialState = { TransactionData: [], deposite: [], cashout: [], ResultReport: [], breakdownlist: PastResult }
export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case appConstants.GET_TRANSACTION_DATA:
            return {
                ...state,
                TransactionData: action.TransactionData
            };
        case appConstants.GET_DEPOSITE_SUCESS:
            return {
                ...state,
                deposite: action.deposite
            };
        case appConstants.GET_CASHOUT_SUCESS:
            return {
                ...state,
                cashout: action.cashout
            };
        case appConstants.GET_PROFILE_DETAILS:
            return {
                ...state,
                details: action.details
            };
        case appConstants.GET_RESULT_REPORT:
            return {
                ...state,
                ResultReport: action.results
            };
        case appConstants.GET_REPORT_DETAILS:
            return {
                ...state,
                LedgerData: action.LedgerData
            };
        case appConstants.PRICE_BREAKDOWN_DATA:
            localStorage.setItem('prresult', JSON.stringify(action.breakdownlist))
            return {
                ...state,
                breakdownlist: action.breakdownlist
            };
        case appConstants.GET_ORDER_DATA:
            return {
                ...state,
                OrderData: action.TransactionData
            };
        default:
            return { ...state }
    }
}
import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ParticipateResponsible extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                <h2 className='headding3 '>{t('lblParticipateResponsible')}</h2>
                {i18n.language == 'ur' ?
                    <div className='addlpage scrollbar'>
                        <div className="launchOfferDiv">
                            <h3 className="text-base lg:text-xl font-semibold"> ذمہ داری سے کھیلینا گلف ٹکٹ کی بنیادی قیمت ہے.</h3>
                            <p>
                                گلف ٹکٹ کا مشن گلف ٹکٹ کے صارفین کو ایسی مصنوعات کی پیشکش کر کے جو ماحولیاتی وجوہات کو پائیدار طریقے سے بڑھاتے ہیں اور دیانتداری اور ایمانداری کے ذریعے عوام کے اعتماد کو یقینی بنا کر اچھی عوامی اور سماجی ذمہ داری کے اعلیٰ ترین معیارات کے مطابق کاروباری انداز میں آمدنی میں اضافہ کرنا ہے۔.
                            </p>
                            <p className="margin-top10">
                                گاہک کے تحفظ میں اعلیٰ معیارات حاصل کرنے کے لیے، گلف ٹکٹ خود کو درج ذیل اصولوں اور اعمال کا پابند کرتا ہے:
                            </p>
                            <p className="ageVerification">عمر کی توثیق:</p>
                            <p>
                                ہم کم عمر افراد (18 سال سے کم عمر) کے داخلے کو روکنے کے لیے پرعزم ہیں۔ گلف ٹکٹ صرف ان صارفین کے لیے دستیاب ہے جن کی عمر اور شناخت کی تصدیق ہوچکی ہے۔ چوں کہ ہمیں 'پلیئر پروفائل' ترتیب دینے کی ضرورت پڑتی ہے، اس لیے ہم کھلاڑیوں کی عمر کی تصدیق کرنے کے قابل ہوتے ہیں۔.
                            </p>
                            <p className="ageVerification">ڈیزائن:</p>
                            <p>
                                ہم اس بات کو یقینی بناتے ہوئے اپنے پروگرام کو ذمہ دارانہ انداز میں ڈیزائن کرتے ہیں کہ اس میں خطرہ کم ہو اور یہ تفریحی ہو۔.
                            </p>
                        </div>
                        <div className="launchOfferDiv">
                            <h4>آن لائن سسٹم ذمہ دار پلے میکانزم</h4>
                            <p>
                                گلف ٹکٹ پر ہم نہیں چاہتے کہ گاہک اپنی استطاعت سے بڑھ کر خرچ کرے۔ گلف ٹکٹ آن لائن سسٹم شرکاء کو ایسا کرنے میں مدد کرنے کے لیے ڈیزائن کیا گیا ہے۔.
                            </p>
                        </div>
                        <div className="launchOfferDiv">
                            <h4>ذمہ دار پلے سسٹم کے اجزاء ہیں:</h4>
                            <p>آپ کی خریداری پر نظر رکھنا:</p>
                            <p className="margin-top10">
                                گاہک کی پروفائل آپ کی تمام خریداریوں کے مکمل لین دین کی تاریخ دکھائے گی۔ گلف ٹکٹ، اپنے سی ایس آر پروگرام کے حصے کے طور پر اور اپنے وسیع پی آر کوریج کے ذریعے اچھی شہریت کے لیے اپنے عہد کے حصے کے طور پر 'ذمہ داری سے کھیلیں' کو فروغ دے گا۔.
                            </p>
                        </div>
                    </div>

                    : i18n.language == 'ml' ?
                        <div className='addlpage scrollbar'>
                            <div className="launchOfferDiv">
                                <h3 className="text-base lg:text-xl font-semibold">ഉത്തരവാദിത്തത്തോടെകളിക്കുകഎന്നത്ഗൾഫ്ടിക്കറ്റിന്റെപ്രധാനമൂല്യമാണ്.</h3>
                                <p>
                                    ഉത്തരവാ ദി ത്തത്തോ ടെ കളി ക്കുക എന്നത് ഗൾഫ് ടി ക്കറ്റി ന്റെ പ്രധാ ന മൂല്യ മാ ണ്. ഗൾഫ് ടി ക്കറ്റ് ഉപഭോ ക്താ ക്കൾക്ക്
                                    പാ രി സ്ഥി തി ക കാ രണങ്ങൾ വർധി പ്പി ക്കു ന്ന ഉൽപ്പന്നങ്ങൾ വാ ഗ്ദാ നം ചെ യ്തും , സമഗ്രതയി ലൂടെ യും സത്യ സന്ധതയി ലൂടെ യും
                                    പൊ തുജനങ്ങളുടെ വി ശ്വാ സം ഉറപ്പാ ക്കി ക്കൊ ണ്ടും നല്ല പൊ തു, സാ മൂഹി ക ഉത്തരവാ ദി ത്തത്തി ന്റെ ഉയർന്ന മാ നദണ്ഡങ്ങൾക്ക്
                                    അനുസൃതമാ യി , സം രം ഭക രീ തി യി ൽ വരുമാ നം വർധി പ്പി ക്കു ക എന്നതാ ണ് ഗൾഫ് ടി ക്കറ്റി ന്റെ ലക്ഷ്യം .
                                </p>
                                <p className="margin-top10">
                                    ഉപഭോക്താവിന്റെസംരക്ഷണത്തിൽഉയർന്നനിലവാരംകൈവരിക്കുന്നതിന്, ഗൾഫ്ടിക്കറ്റ്ഇനിപ്പറയുന്നതത്വങ്ങളുംസമ്പ്രദായങ്ങളുംപാലിക്കുന്നു:</p>
                                <p className="ageVerification">പ്രായപരിശോധന:</p>
                                <p>
                                    പ്രാ യപൂർത്തിയാ കാ ത്തവർക്കു ള്ള (18 വയസ്സി ന് താ ഴെ യുള്ളവർ) പ്രവേ ശനം തടയാ ൻ ഞങ്ങൾ
                                    പ്രതിജ്ഞാ ബദ്ധരാ ണ്. പ്രാ യവും ഐഡന്റി റ്റി യും സ്ഥി രീ കരി ച്ചി ട്ടുള്ള ഉപയോ ക്താ ക്കൾക്ക് മാ ത്രമേ ഗൾഫ് ടി ക്കറ്റ് കൈ കാ ര്യം
                                    ചെ യ്യാ നാ കൂ. ഞങ്ങൾക്ക് ഒരു 'പ്ലെ യർ പ്രൊ ഫൈ ൽ' സജ്ജീ കരി ക്കേ ണ്ടതി നാ ൽ കളി ക്കാ രുടെ പ്രാ യം പരി ശോ ധി ക്കാ ൻ
                                    ഞങ്ങൾക്ക് കഴി യും .

                                </p>
                                <p className="ageVerification">രൂപകൽപ്പന:</p>
                                <p>
                                    അപകടസാ ധ്യ ത കുറഞ്ഞതും രസകരവുമാ ണെ ന്ന് ഉറപ്പാ ക്കി ക്കൊ ണ്ട് ഉത്തരവാ ദി ത്തമുള്ള രീ തി യി ലാ ണ്
                                    ഞങ്ങൾ ഞങ്ങളുടെ പ്രോ ഗ്രാം രൂപകൽപ്പന ചെ യ്യുന്നത്.
                                </p>
                            </div>
                            <div className="launchOfferDiv">
                                <h4>ഓൺ-ലൈൻസിസ്റ്റംറെസ്പോൺസിബിൾപ്ലേമെക്കാനിസങ്ങൾ</h4>
                                <p>
                                    ഗൾഫ് ടി ക്കറ്റി ൽ ഉപഭോ ക്താ വ് അവർക്ക് താ ങ്ങാ നാ വുന്നതി ലും കൂടുതൽ ചെ ലവഴി ക്കാ ൻ ഞങ്ങൾ ആഗ്രഹി ക്കുന്നി ല്ല.
                                    ഗൾഫ് ടി ക്കറ്റി ന്റെ ഓൺലൈ ൻ സം വി ധാ നം , പങ്കെ ടുക്കുന്നവരെ അത് ചെ യ്യാ ൻ സഹാ യി ക്കുന്നതി ന് രൂപകൽപ്പന
                                    ചെ യ്തി ട്ടുള്ളതാ ണ്.
                                </p>
                            </div>
                            <div className="launchOfferDiv">
                                <h4>റെസ്പോൺസിബിൾപ്ലേസിസ്റ്റത്തിന്റെഘടകങ്ങൾ ഇവയാണ്:</h4>
                                <p>നിങ്ങളുടെ പർച്ചേസുകളുടെട്രാക്ക്സൂക്ഷിക്കുന്നു:</p>
                                <p className="margin-top10">
                                    നി ങ്ങളുടെ പർച്ചേ സു കളു ടെ ട്രാ ക്ക് സൂക്ഷി ക്കുന്നു: ഉപഭോ ക്താ വി ന്റെ പ്രൊ ഫൈ ൽ നി ങ്ങളുടെ എല്ലാ
                                    പർച്ചേ സു കളു ടെ യും മുഴുവൻ ഇടപാ ട് ചരി ത്രവും കാ ണി ക്കും . ഗൾഫ് ടി ക്കറ്റ്, അതി ന്റെ സി എസ്ആർ പ്രോ ഗ്രാ മി ന്റെ
                                    ഭാ ഗമാ യി , അതി ന്റെ വി പുലമാ യ പി ആർ കവറേ ജി ലൂടെ നല്ല പൗ രത്വ ത്തി നാ യുള്ള പ്രതി ബദ്ധതയുടെ ഭാ ഗമാ യി
                                    'ഉത്തരവാ ദി ത്വ ത്തോ ടെ കളി ക്കുക' എന്നതി നെ പ്രോ ത്സാ ഹി പ്പി ക്കും .
                                </p>
                            </div>
                        </div>
                        :
                        i18n.language == "ar" ?
                            <div className='addlpage scrollbar'>
                                <div className="launchOfferDiv">
                                    <h3 className="text-base lg:text-xl font-semibold">اللعب بمسؤولية هو القيمة الأساسية لغالف تيكيت.</h3>
                                    <p>
                                        . تتمثل مهمة غالف تيكيتفي زيادة الإيرادات بطريقة ريادية، بما يتوافق مع أعلى معايير المسؤولية العامة والمعايير الاجتماعية الجيدة، من خلال تقديم المنتجاتالتي تدعم القضايا البيئية بشكل مستدام لعملاء غالف تيكيت، وبضمان ثقة الجمهور بتبني قيم النزاهة والأمانة
                                    </p>
                                    <p className="margin-top10">
                                        لتحقيق معايير عالية في حماية العملاء، تلتزم غالف تيكيتبالمبادئ والممارسات التالية:
                                    </p>
                                    <p className="ageVerification">التحقق من العمر:</p>
                                    <p>
                                        نحن ملتزمون بمنع دخول القاصرين (أقل من 18 عامًا). لا يمكن لأحد الدخول إلى غالف تيكيتإلا المستخدمين الذين تم تأكيد أعمارهم وهويتهم. نظرًا لأننا نطلب إعداد "ملف اللاعب"، يمكننا التحقق من عمر اللاعب.
                                    </p>
                                    <p className="ageVerification">التصميم:</p>
                                    <p>
                                        نقوم بتصميم برنامجنا بطريقة مسؤولة، مما يضمن أنه منخفض المخاطر وممتع.
                                    </p>
                                </div>
                                <div className="launchOfferDiv">
                                    <h4>في غالف تيكيت، لا نريد أن ينفق العميل أكثر من قدرته. فقد صُمم نظام غالف تيكيتعبر الإنترنت لمساعدة المشارك على القيام بذلك</h4>
                                    <p>
                                        في غالف تيكيت، لا نريد أن ينفق العميل أكثر من قدرته. فقد صُمم نظام غالف تيكيتعبر الإنترنت لمساعدة المشارك على القيام بذلك.
                                    </p>
                                </div>
                                <div className="launchOfferDiv">
                                    <h4>مكوناتنظاماللعبالمسؤولهي:</h4>
                                    <p>تتبع مشترياتك:</p>
                                    <p className="margin-top10">
                                        سيعرض ملف العميل سجل المعاملات الكامل لجميع مشترياتك. ستعمل غالف تيكيت، كجزء من برنامجها للمسؤولية الاجتماعية للشركات، ومن خلال تغطيتها الواسعة للعلاقات العامة، على الترويج "للعب بمسؤولية" كجزء من التزامها بالمواطنة الصالحة.
                                    </p>
                                </div>
                            </div>
                            :
                            <div className="addlPage scrollbar">
                                <div className="launchOfferDiv">
                                    <h3 className="text-base lg:text-xl font-semibold">Playing Responsibly is a core value of Gulf Ticket.</h3>
                                    <p>
                                        Gulf Ticket aims to increase revenue in an entrepreneurial manner in accordance with
                                        high standards of public good and social responsibility by offering Gulf Ticket
                                        customers products that enhance environmental causes and ensuring public trust through
                                        integrity and honesty.
                                    </p>
                                    <p className="margin-top10">
                                        To achieve the highest standards of customer protection, Gulf Ticket adheres to the
                                        following principles and practices:
                                    </p>
                                    <p className="ageVerification">Age Verification:</p>
                                    <p>
                                        We are committed to preventing entry by minors (under 18). Only users with verified
                                        age that are above 18+ can participate in the games. We require a 'player profile' to
                                        be set up so we can verify the age of players.
                                    </p>
                                    <p className="ageVerification">Design:</p>
                                    <p>
                                        We design our programs in a responsible manner, ensuring that risk is low and fun.
                                    </p>
                                </div>
                                <div className="launchOfferDiv">
                                    <h4>System responsible online gameplay mechanics</h4>
                                    <p>
                                        We don't want customers to spend more than they can afford on Gulf Ticket. Gulf
                                        Ticket's online system is designed to help participants do just that.
                                    </p>
                                </div>
                                <div className="launchOfferDiv">
                                    <h4>The components of the responsible gaming system are:</h4>
                                    <p>Track Your Purchases:</p>
                                    <p className="margin-top10">
                                        The customer profile will show the entire transaction history of all your purchases.
                                        Gulf Ticket, as part of its CSR programme, promotes 'Play Responsibly' as part of its
                                        commitment to good citizenship through its extensive PR coverage.
                                    </p>
                                </div>
                            </div>
                }

            </>
        )
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(ParticipateResponsible))
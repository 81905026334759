import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
class CSR extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title>Gulf Ticket - Greening the Emirates - Sustainability Endeavor</title>
                    <meta name="description" content="Delve into Gulf Ticket's dedication to corporate social responsibility, empowering communities and making a positive impact through its initiatives." />
                    <meta name="keywords" content="Gulf Ticket, lottery, UAE, CSR, community support, raffle, lottery, winners, draw, winning numbers, mega prize" />

                    <meta property="og:title" content="Gulf Ticket - Greening the Emirates - Sustainability Endeavor" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Delve into Gulf Ticket's dedication to corporate social responsibility, empowering communities and making a positive impact through its initiatives." />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />

                </Helmet>}
                <h1 className='headding3'>{t('lblCSR')}</h1>
                {i18n.language === 'ur' ?
                    <div className='addlpageCont scrollbar marginBottomResult'>
                        <div className="givingBack">
                            <div><img src="./assets/img/CSR.png" alt="CSR" /></div>
                            <div>
                                <p>گلف ٹکٹ نے متحدہ عرب امارات کے گرین ایجنڈا 2030 کی حمایت کے لیے خود کو مضبوطی سے وقف کر رکھا ہے جو یو اے ای کو پائیدار ترقی میں عالمی رہنما کے طور پر قائم کرنے پر مرکوز حکومت کے زیرقیادت بلند حوصلہ اقدام ہے۔ اس اہم کوشش میں پرجوش حصہ لینے والے کے طور پر، گلف ٹکٹ ہمارے قیمتی سیارے کے لیے بہتر مستقبل کو فروغ دینے کی خاطر اپنے تہہ دل سے کیے گیے عزم پر بہت فخر محسوس کرتا ہے۔.</p>
                                <p>ہمارے سماج کے حامی اور ماحول دوست اخلاقیات پر عمل کرتے ہوئے گلف ٹکٹ کی بنیادی توجہ کھجور کے درخت لگانے کے منصوبوں میں تعاون کرنا ہے۔ ان اقدامات کی فعال طور پر حمایت کرتے ہوئے، ہمارا مقصد ماحول کو مضبوط بنانے اور آنے والی نسلوں کے لیے سرسبز اور زیادہ قابل رہائش دنیا کو محفوظ بنانے میں اہم کردار ادا کرنا ہے۔.</p>
                                <p>ہم پختہ طور پر یہ یقین رکھتے ہیں کہ ہم میں سے ہر ایک ہمارے قدرتی وسائل کی حفاظت کرنے اور انہیں محفوظ رکھنے کے لیے ذمہ دار ہے اور گلف ٹکٹ پر، ہم اپنے حصے کا کام کرنے کے لیے عہد بند ہیں۔ متحدہ عرب امارات کے گرین ایجنڈا 2030 کے ساتھ ہاتھ ملا کر کام کرتے ہوئے، ہم مثبت تبدیلی کی ترغیب دینے، پائیداری کو فروغ دینے اور ماحولیاتی ذمہ داری کی دیرپا میراث چھوڑنے کی کوشش کرتے ہیں۔.</p>
                                <p>ایک ساتھ مل کر، ہم فروغ پزیر مستقبل کی تعمیر کے مشن پر ہیں جہاں کھجور کے درختوں کے پودے پھلتے پھولتے ہیں جو صحت مند سیارے اور سب کے لیے روشن کل میں تعاون کرتے ہیں۔ اس نیک مقصد میں ہمارے ساتھ شامل ہوں کیونکہ ہم آنے والی نسلوں کے لیے پائیدار اور امید افزا دنیا کو یقینی بنانے کے لیے اپنی کوششوں کو یکجا کرتے ہیں۔.</p>
                            </div>
                        </div>
                    </div>
                    : i18n.language === 'ml' ?
                        <div className='addlpageCont scrollbar marginBottomResult'>
                            <div className="givingBack">
                                <div><img src="./assets/img/CSR.png" alt="CSR" /></div>
                                <div>
                                    <p>സുസ്ഥിരവികസനത്തിൽയുഎഇയെആഗോളനേതാവായിസ്ഥാപിക്കുന്നതിൽശ്രദ്ധകേന്ദ്രീകരിക്കുന്നസർക്കാരിന്റെനേതൃത്വത്തിലുള്ളസംരംഭമായയുഎഇഗ്രീൻഅജണ്ട 2030 നെപിന്തുണയ്ക്കാൻഗൾഫ്ടിക്കറ്റ്പ്രതിജ്ഞാബദ്ധമാണ്.ഈസുപ്രധാനഉദ്യമത്തിലെഉത്സാഹഭരിതനായപങ്കാളിയെന്നനിലയിൽ, നമ്മുടെവിലയേറിയഗ്രഹത്തിന്റെമെച്ചപ്പെട്ടഭാവിപരിപോഷിപ്പിക്കുന്നതിനുള്ളപൂർണ്ണഹൃദയത്തോടെയുള്ളപ്രതിബദ്ധതയിൽഗൾഫ്ടിക്കറ്റ്വളരെയധികംഅഭിമാനിക്കുന്നു.</p>
                                    <p>നമ്മുടെസാമൂഹികഅനുകൂലവുംപരിസ്ഥിതിഅധിഷ്ഠിതവുമായധാർമ്മികതയ്ക്ക്അനുസൃതമായി, ഗൾഫ്ടിക്കറ്റിന്റെപ്രാഥമികശ്രദ്ധഈന്തപ്പനനടീൽപദ്ധതികൾക്ക്സംഭാവനനൽകുന്നതിലാണ്.ഈസംരംഭങ്ങളെസജീവമായിപിന്തുണയ്ക്കുന്നതിലൂടെ, പരിസ്ഥിതിയെസമ്പന്നമാക്കുന്നതിലുംവരുംതലമുറകൾക്കായിഹരിതവുംകൂടുതൽവാസയോഗ്യവുമായഒരുലോകംസുരക്ഷിതമാക്കുന്നതിലുംഒരുപ്രധാനപങ്ക്വഹിക്കാൻഞങ്ങൾലക്ഷ്യമിടുന്നു.</p>
                                    <p>നമ്മുടെപ്രകൃതിവിഭവങ്ങൾസംരക്ഷിക്കുന്നതിനുംപരിപാലിക്കുന്നതിനുംനമുക്കോരോരുത്തർക്കുംഉത്തരവാദിത്തമുണ്ടെന്ന്ഞങ്ങൾഉറച്ചുവിശ്വസിക്കുന്നു, ഗൾഫ്ടിക്കറ്റിൽ, ഞങ്ങളുടെപങ്ക്ചെയ്യാൻഞങ്ങൾപ്രതിജ്ഞാബദ്ധരാണ്.യുഎഇഗ്രീൻഅജണ്ട 2030-യുമായികൈകോർത്ത്പ്രവർത്തിക്കുന്നതിലൂടെ, നല്ലമാറ്റത്തിന്പ്രചോദനംനൽകാനുംസുസ്ഥിരതപ്രോത്സാഹിപ്പിക്കാനുംപരിസ്ഥിതിസംരക്ഷണത്തിന്റെശാശ്വതമായപാരമ്പര്യംനൽകാനുംഞങ്ങൾശ്രമിക്കുന്നു.</p>
                                    <p>ഈന്തപ്പനത്തോട്ടങ്ങൾതഴച്ചുവളരുന്ന, ആരോഗ്യകരമായഒരുലോകത്തിനുംഎല്ലാവർക്കുംശോഭനമായനാളെയ്ക്കുംസംഭാവനനൽകുന്ന, അഭിവൃദ്ധിപ്രാപിക്കുന്നഒരുഭാവികെട്ടിപ്പടുക്കാനുള്ളഒരുമിച്ചുള്ളഒരുദൗത്യത്തിലാണ്ഞങ്ങൾ.ഭാവിതലമുറകൾക്ക്സുസ്ഥിരവുംവാഗ്ദാനപ്രദവുമായഒരുലോകംഉറപ്പാക്കാനുള്ളഞങ്ങളുടെശ്രമങ്ങളെഞങ്ങൾഒന്നിപ്പിക്കുമ്പോൾ, ഈമഹത്തായലക്ഷ്യത്തിൽഞങ്ങളോടൊപ്പംചേരുക.</p>
                                </div>
                            </div>
                        </div>
                        : i18n.language === 'ar' ?
                            <div className='addlpageCont scrollbar marginBottomResult'>
                                <div className="givingBack">
                                    <div><img src="./assets/img/CSR.png" alt="CSR" /></div>
                                    <div>
                                        <p>تلتزم غالف تيكت بدعم الأجندة الخضراء لدولة الإمارات العربية المتحدة 2030، وهي مبادرة طموحة تقودها الحكومة وتركز على ترسيخ مكانة دولة الإمارات العربية المتحدة كدولة رائدة عالميًا في مجال التنمية المستدامة. وباعتبارها مشاركًا متحمسًا في هذا المسعى الحيوي، تفتخر غالف تيكيتبالتزامها الصادقببناء مستقبل أفضل لكوكبنا الثمين.</p>
                                        <p>تماشيًا مع أخلاقياتنا الداعمة للمجتمع والبيئة، يكمن التركيز الأساسي لغالف تيكيتفي المساهمة في مشاريع زراعة أشجار النخيل. ومن خلال الدعم النشط لهذه المبادرات، نهدف إلى لعب دور محوري في إثراء البيئة وتأمين عالم أكثر خضرة وملاءمة للعيش للأجيال القادمة.</p>
                                        <p>نحن نؤمن إيمانًا راسخًا بأنه يجب علينا جميعًا تحمل مسؤولية حماية مواردنا الطبيعية والحفاظ عليها، وفي غالف تيكيت، نحن ملتزمون بالقيام بدورنا. ومن خلال العمل جنبًا إلى جنب مع الأجندة الخضراء لدولة الإمارات العربية المتحدة 2030، نسعى إلى إلهام التغيير الإيجابي وتعزيز الاستدامة وترك إرث دائم من الإدارة البيئية.</p>
                                        <p>معًا، نحن في مهمة لبناء مستقبل مزدهر، تزدهر فيه مزارع النخيل، مما يسهم في كوكب صحي أكثر وغد أفضل للجميع. انضموا إلينا في هذه القضية النبيلة، وفيها نوحد جهودنا لضمان عالم مستدام وواعد للأجيال القادمة.</p>
                                    </div>
                                </div>
                            </div> :
                            <div className='addlPage scrollbar marginBottomResult'>
                                <div className="givingBack">
                                    <div><img src="./assets/img/CSR.png" alt="CSR" /></div>
                                    <div>
                                        <p>Gulf Ticket is committed to supporting the UAE Green Agenda 2030, an ambitious government-led initiative focused on establishing the UAE as a global leader in sustainable development. As an enthusiastic participant in this vital endeavour, Gulf Ticket is proud of its sincere commitment to building a better future for our precious planet.</p>
                                        <p>In line with our pro-social and pro-environmental ethos, Gulf Ticket’s primary focus is to contribute to palm tree plantation projects. By actively supporting these initiatives, we aim to play a pivotal role in enriching the environment and securing a greener, more livable world for future generations.</p>
                                        <p>We strongly believe that we each have a responsibility to protect and care for our natural resources, and at Gulf Ticket, we are committed to doing our part. Working hand-in-hand with the UAE Green Agenda 2030, we strive to inspire positive change, promote sustainability and provide a lasting legacy of environmental stewardship.</p>
                                        <p>We are on a shared mission to build a prosperous future where palm plantations thrive, contributing to a healthy world and a brighter future for all. Join us in this great cause as we unite our efforts to ensure a sustainable and promising world for future generations.</p>
                                        <div className="divBorder width20"></div>
                                    </div>
                                </div>
                            </div>

                }
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(CSR))
import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Launchoffer extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                <h1 className='headding3'>{t("lblLaunchoffer")}</h1>
                {i18n.language === 'ur' ?
                    <div className='addlpageCont'>
                        <h5 className='headding5'>گارنٹی شدہ فروخت بذریعہ قرعہ اندازی</h5>
                        <p>شرائط و ضوابط
                            شرکت کرنے کا طریقہ
                            گلف ٹکٹ کی فرحت بخش لانچ آفر میں شامل ہونا آسان ہے! گارنٹی شدہ فروخت بذریعہ قرعہ اندازی میں حصہ لینے کے لیے، دلچسپ قسمت 5 لانچ آفر گیم کے لیے ٹکٹ خریدیں۔ ہر ٹکٹ ہر ہفتے گارنٹی شدہ AED 1,000,000 کا عظیم الشان انعام جیتنے کا موقع کھولتا ہے!
                        </p>
                        <h5 className='headding5'>گارنٹی شدہ فروخت بذریعہ قرعہ اندازی کے میکینکس</h5>
                        <p>ایک بار جب آپ کو اپنا ٹکٹ مل جائے، تو آپ خود بخود گارنٹی شدہ فروخت بذریعہ قرعہ اندازی کا حصہ بننے کے اہل ہو جاتے ہیں۔ جیسے جیسے ہفتہ آگے بڑھتا ہے، توقع بڑھتی جاتی ہے اور جوش و خروش عروج پر پہنچ جاتا ہے۔ لائیو قرعہ اندازی کا مشاہدہ کرنے کے لیے ہر ہفتے ٹیون ان کرنا یقینی بنائیں جو آپ کی زندگی کو ہمیشہ کے لیے بدل سکتا ہے!</p>
                        <h5 className='headding5'>جیتنے والے اور انعامات</h5>
                        <p>ہر ہفتے کے اختتام پر، لائیو قرعہ اندازی کے دوران بے ترتیب طور پر خوش قسمت جیتنے والے کا انتخاب کیا جائے گا۔ خوش قسمت جیتنے والا ناقابل یقین AED 1,000,000 انعام لے کر جائے گا جو خوابوں کو حقیقت میں بدلنے کا زندگی بدل دینے والا موقع ہے!</p>
                        <h5 className='headding5'>اہلیت کا معیار</h5>
                        <p>گلف ٹکٹ لانچ آفر متحدہ عرب امارات میں مقیم 18 سال اور اس سے زیادہ عمر کے تمام شرکاء کے لیے کھلی ہے۔ گارنٹی شدہ فروخت بذریعہ قرعہ اندازی کے اہل ہونے کے لیے، یقینی بنائیں کہ آپ نے ہفتہ وار قرعہ اندازی ہونے سے قبل قسمت 5 لانچ آفر گیم کے لیے درست ٹکٹ خرید لیا ہے۔.</p>
                        <h5 className='headding5'>ڈس کلیمر</h5>
                        <p>گلف ٹکٹ ہماری تمام پروموشنز اور فروخت بذریعہ قرعہ اندازی میں غیر جانبداری اور شفافیت کو یقینی بنانے کے لیے پرعزم ہے۔ ہم اپنے قابل قدر شرکاء کے لیے گیمنگ کا غیر معمولی تجربہ فراہم کرنے کے لیے خود کو دیانتداری کے اعلی ترین معیار پر قائم رکھتے ہیں۔ براہ کرم اچھی طرح سے باخبر رہنے اور سنسنی خیز، اپنی نوعیت کے گیمنگ ایڈونچر سے لطف اندوز ہونے کے لیے گلف ٹکٹ لانچ آفر ضمانت شدہ فروخت بذریعہ قرعہ اندازی کی مکمل شرائط و ضوابط کا جائزہ لیں۔ اس یادگار جشن میں ہمارے ساتھ شامل ہوں جب کہ ہم غیر معمولی لانچ آفر کے ساتھ گلف ٹکٹ کا سفر شروع کر رہے ہیں جو آپ کو ہر ہفتے کروڑ پتی بننے کا موقع فراہم کرتا ہے! ابھی اپنا ٹکٹ حاصل کریں اور جوش و خروش کا حصہ بنیں!</p>
                    </div> :
                    i18n.language === 'ml' ?
                        <div className='addlpageCont'>
                            <h5 className='headding5'>ഗ്യാരണ്ടീഡ് റാഫിൾ </h5>
                            <p>വ്യവസ്ഥകളുംനിബന്ധനകളുംഎങ്ങനെപങ്കെടുക്കാം ഗൾഫ്ടിക്കറ്റിന്റെആനന്ദകരമായലോഞ്ച്ഓഫറിൽചേരുന്നത്ലളിതമാണ്!ഗ്യാരണ്ടീഡ് റാഫിളിൽപങ്കെടുക്കാൻ, ആവേശകരമായ ഫോർച്യൂൺ 5 ലോഞ്ച്ഓഫർഗെയിമിനായിഒരുടിക്കറ്റ്വാങ്ങുക.ഓരോടിക്കറ്റിനുംഓരോആഴ്ചയും 1,000,000 ദിർഹംഉറപ്പുനൽകുന്നഗ്രാൻഡ്പ്രൈസ്നേടാനുള്ളഅവസരംതുറക്കുന്നു!</p>
                            <h5 className='headding5'>ഗ്യാരണ്ടീഡ്റാഫിളിന്റെമെക്കാനിക്സ്</h5>
                            <p>ഒരിക്കൽനിങ്ങളുടെടിക്കറ്റ്ലഭിച്ചുകഴിഞ്ഞാൽ, ഗ്യാരണ്ടീഡ്റാഫിളിന്റെഭാഗമാകാൻനിങ്ങൾക്ക്സ്വയമേവയോഗ്യതയുണ്ട്.ആഴ്ചപുരോഗമിക്കുമ്പോൾ, പ്രതീക്ഷകൾവർദ്ധിക്കുകയുംആവേശംഅതിന്റെഉച്ചസ്ഥായിയിലെത്തുകയുംചെയ്യുന്നു.നിങ്ങളുടെജീവിതത്തെഎന്നെന്നേക്കുമായിമാറ്റിമറിച്ചേക്കാവുന്നതത്സമയനറുക്കെടുപ്പിന്സാക്ഷ്യംവഹിക്കാൻഎല്ലാആഴ്ചയുംട്യൂൺചെയ്യുന്നത്ഉറപ്പാക്കുക!</p>
                            <h5 className='headding5'>വിജയികളുംസമ്മാനങ്ങളും</h5>
                            <p>ഓരോആഴ്ചയുടെയുംഅവസാനം, തത്സമയനറുക്കെടുപ്പിൽഒരുഭാഗ്യശാലിയെക്രമരഹിതമായിതിരഞ്ഞെടുക്കും.ഭാഗ്യവാനായവിജയിക്ക്അവിശ്വസനീയമായ 1,000,000 ദിർഹംസമ്മാനംലഭിക്കും - സ്വപ്നങ്ങളെയാഥാർത്ഥ്യമാക്കാനുള്ളജീവിതംമാറ്റിമറിക്കുന്നഅവസരം!</p>
                            <h5 className='headding5'>യോഗ്യതാമാനദണ്ഡം</h5>
                            <p>ഗൾഫ്ടിക്കറ്റ്ലോഞ്ച്ഓഫർയുഎഇയിൽതാമസിക്കുന്ന 18 വയസുംഅതിനുമുകളിലുംപ്രായമുള്ളഎല്ലാപങ്കാളികൾക്കുംലഭ്യമാണ്.ഗ്യാരണ്ടീഡ്റാഫിളിന്യോഗ്യതനേടുന്നതിന്, പ്രതിവാരനറുക്കെടുപ്പ്നടക്കുന്നതിന്മുമ്പ് ഫോർച്യൂൺ 5 ലോഞ്ച്ഓഫർഗെയിമിനായിനിങ്ങൾസാധുവായഒരുടിക്കറ്റ്വാങ്ങിയിട്ടുണ്ടെന്ന്ഉറപ്പാക്കുക.</p>
                            <h5 className='headding5'>ബാധ്യതാനിരാകരണം</h5>
                            <p>ഞങ്ങളുടെഎല്ലാപ്രമോഷനുകളിലുംറാഫിൾനറുക്കെടുപ്പുകളിലുംനീതിയുംസുതാര്യതയുംഉറപ്പാക്കാൻഗൾഫ്ടിക്കറ്റ്പ്രതിജ്ഞാബദ്ധമാണ്.ഞങ്ങളുടെമൂല്യവത്തായപങ്കാളികൾക്ക്അസാധാരണമായഗെയിമിംഗ്അനുഭവംനൽകുന്നതിന്സമഗ്രതയുടെഏറ്റവുംഉയർന്നനിലവാരംഞങ്ങൾനിലനിർത്തുന്നു.ഗൾഫ്ടിക്കറ്റ്ലോഞ്ച്ഓഫർഉറപ്പുനൽകുന്നറാഫിളിന്റെപൂർണ്ണമായനിബന്ധനകളുംവ്യവസ്ഥകളുംഅവലോകനംചെയ്യുക, ഒപ്പംആവേശകരമായഒരുതരംഗെയിമിംഗ്സാഹസികതആസ്വദിക്കുകയുംചെയ്യുക.അസാധാരണമായലോഞ്ച്ഓഫറുമായിഞങ്ങൾഗൾഫ്ടിക്കറ്റ്യാത്രആരംഭിക്കുമ്പോൾഈസുപ്രധാനആഘോഷത്തിൽഞങ്ങളോടൊപ്പംചേരൂ - എല്ലാആഴ്ചയുംകോടീശ്വരനാകാനുള്ളനിങ്ങളുടെഅവസരം!നിങ്ങളുടെടിക്കറ്റുകൾഇപ്പോൾനേടുക, ആവേശത്തിന്റെഭാഗമാകുക!</p>
                        </div> :
                        i18n.language === 'ar' ?
                            <div className='addlpageCont'>
                                <h5 className='headding5'>السحبالمضمون</h5>
                                <p>الشروط والأحكام
                                    كيفية المشاركة
                                    الانضمام إلى عرض الإطلاق المبهج لغالف تيكيتأمر بسيط! للمشاركة في السحب المضمون، قم بشراء تذكرة للعبة فورتشن 5 لانش أوفر المثيرة. تعطي كل تذكرة فرصة للفوز بالجائزة الكبرى بقيمة 1,000,000 درهم مضمونة كل أسبوع!
                                </p>
                                <h5 className='headding5'>آلياتالسحبالمضمون</h5>
                                <p>بمجرد حصولك على تذكرتك، تصبح مؤهلاًبشكل تلقائي لتكون جزءًا من السحب المضمون. وبمرور أيام الأسبوع، يتزايد الترقب، وتصل الإثارة إلى ذروتها. تأكد من أن تتابع كل أسبوع لمشاهدة السحب المباشر الذي قد يغير حياتك إلى الأبد!</p>
                                <h5 className='headding5'>الفائزونوالجوائز</h5>
                                <p>في نهاية كل أسبوع، سيتم اختيار فائز محظوظ واحد بشكل عشوائي خلال السحب المباشر. سيحصل الفائز المحظوظ على الجائزة المذهلة التي تبلغ قيمتها مليون درهم إماراتي - وهي فرصة ستغير حياته وتحوّل أحلامه إلى حقيقة!</p>
                                <h5 className='headding5'>معاييرالأهليّة</h5>
                                <p>عرض إطلاق غالف تيكيتمفتوح لجميع المشاركين الذين تبلغ أعمارهم 18 عامًا فما فوق والمقيمين في دولة الإمارات العربية المتحدة. لكي تكون مؤهلاً للسحب المضمون، تأكد من أنك اشتريت تذكرة صالحة للعبة فورتشن 5 لانش أوفر قبل إجراء السحب الأسبوعي.</p>
                                <h5 className='headding5'>تنويه</h5>
                                <p>تلتزم غالف تيكيتبضمان العدالة والشفافية في جميع العروض الترويجية والسحوبات التي نقدمها. نلتزم بأعلى معايير النزاهة لكي يحظى المشاركون الكرام بتجربة ألعاب استثنائية. يرجى مراجعة الشروط والأحكام الكاملة للسحب المضمون لعرض إطلاق غالف تيكيتلتكون على اطلاع وتستمتع بمغامرة ألعاب مثيرة وفريدة من نوعها. انضم إلينا في هذا الاحتفال المهم حيث نبدأ رحلة غالف تيكيتمع عرض الإطلاق الاستثنائي –لديك فرصة لتصبح مليونيرًا كل أسبوع! اشتري التذاكر الآن وشارك في الإثارة!</p>
                            </div> :
                            <div className='addlpageCont'>
                                <h5 className='headding5'>Guaranteed withdrawal</h5>
                                <p>Terms and Conditions How to participate Joining GulfTicket's exciting launch offer is simple! Buy a ticket for the exciting Fortune 5 Launch Offer game to participate in the guaranteed raffle. Every ticket opens up a chance to win a guaranteed grand prize of AED 1,000,000 every week!</p>
                                <h5 className='headding5'>Guaranteed withdrawal mechanisms</h5>
                                <p>Once you get your ticket, you are automatically eligible to be a part of the guaranteed raffle. As the week progresses, anticipation builds and excitement reaches its peak. Make sure you tune in every week to witness the live draw that could change your life forever!</p>
                                <h5 className='headding5'>Winners and Prizes</h5>
                                <p>At the end of each week, one lucky winner will be selected at random in a live draw. The lucky winner will receive an incredible AED 1,000,000 prize - a life-changing opportunity to make dreams come true!</p>
                                <h5 className='headding5'>Eligibility Criteria</h5>
                                <p>The Gulf Ticket launch offer is open to all participants aged 18 years and above and residing in the UAE. To be eligible for the Guaranteed Draw, make sure you have purchased a valid Fortune 5 Lunchover ticket before the weekly draw takes place.</p>
                                <h5 className='headding5'>Disclaimer</h5>
                                <p>GulfTicket is committed to ensuring fairness and transparency in all our promotions and raffle draws. We maintain the highest standards of integrity to deliver an exceptional gaming experience to our valued partners.GulfTicket Launch Offer Check out the complete terms and conditions of the guaranteed raffle and enjoy an exciting gaming adventure. Join us in this momentous celebration as we kick off the GulfTicket journey with an extraordinary launch offer - your chance to become a millionaire every week! Get your tickets now and be part of the excitement!</p>
                            </div>

                }
            </>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Launchoffer))
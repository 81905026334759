import { Component } from 'react'
import { withTranslation } from 'react-i18next';
class BrandTrust extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        return (
            <section className="brand">
                <div className="wrap1">
                    <div className="brandLi">
                        <div className="brandLiImg">
                            <img src="./assets/img/dubai.webp" alt='dubai icon'/>
                        </div>
                        <div className="brandLiImg float-right">
                            <img src="./assets/img/be-right.webp" alt='be-right icon'/>
                        </div>
                    </div>
                    <div className="brandHead">
                        <h2>{t('lblBrandCanTrust')}</h2>
                        <p>{t('lblAllOurDrawsRegulated')}</p>                       
                    </div>
                </div>
            </section>
        )
    }

}
export default withTranslation()(BrandTrust)


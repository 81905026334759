export const appVariables = {
    ACCOUNT: {
        Profile: 1,
        Wallet: 2,
        Transaction: 3,
        KycVerify: 4,
        Certificates: 5,
        Setting: 6,
        UnderConstruction: 0,
        SummaryReport: 7
    },
    LOGIN: {
        Password: 1,
        Otp: 2
    },
    OTP: {
        Login: 2,
        Register: 1,
        ForgetPassword: 3,
        RegisterNew: 59,
    },
    TRANSACTION: {
        Bet: 1,
        Deposit: 2,
        Withdraw: 3,
        PlayerLedger: 4
    },
    GAMETYPE: {
        VirtualQuickWin: 41,
        Quick9: 42,
        Roulette: 40,
        Sicbo: 48,
        Panta: 59,
        Target100: 30,
        Target10: 31,
        Keno: 32,
        SpinWin: 35
    },
    WALLET_TYPE: {
        WTDeposit: 1,
        WTWithdraw: 2,
        BonusOffers: 3,
        WTUnderConstruction: 0
    },
    LANGUAGE_ID: {
        English: 0,
        Arabic: 1,
        Malayalam: 2,
        Urdu: 3,
        Thai: 4,
        Filipino: 5

    },
    SETTING: {
        WebsiteTheme: 0,
        ChangePassword: 1,
        LangPreference: 2,
        SetPassword: 3
    },
    WITHDRAW_FLAG: {
        WDInitiate: 1,
        WDBankSelection: 2,
        WDStatus: 3
    },
    DEPOSIT_FLAG: {
        DPInitiate: 1,
        DPBankSelection: 2,
        DPStatus: 3
    },
    CERTIFICATE: {
        Select: -1,
        Passport: 1,
        BankPassbook: 2,
        DrivingLicience: 3
    },
    SLOT_TYPE: {
        RapidWin: 1,
        Scratch: 2,
        Slots: 3
    },
    REPORT_TYPE: {
        PlayerLedger: 1,
        SummaryReport: 2
    },
    MENU_ID: {
        Home: 0,
        Fortune5: 1,
        Super6: 2,
        Promotion: 3,
        Faqs: 4,
        Login: 5,
        Result: 6,
        HowtoPlay: 7,
        Fortune5draw: 8,
        Super6draw: 9,
        Register: 10,
        GeneralInfo: 11,
        Magic5draw: 12,
        Magic5: 13,
        Others: 14,
        PrevDrawVideos: 15,
        Lucky1: 16,
        Lucky1draw: 17
    },
    PASSWORD_FLAG: {
        ChangePasswordFlag: 1,
        ForgetPasswordFlag: 2
    },
    PRIZE_STRUCT_FORTUNE5: {
        BetAmount: 10,
        BallSelectCount: 4,
        BottleSize: "250ml",
        RaffleWinCount: 5,
        RaffleWinAmount: "1,000",
        MainDrawWin: { FstPrize: "200,000", SndPrize: "100", TrdPrize: "10", FthPrize: "2" },
        MatchBall: { FstPrize: "4", SndPrize: "3", TrdPrize: "2", FthPrize: "1" }

    },
    PRIZE_STRUCT_SUPER6: {
        BetAmount: 30,
        BallSelectCount: 6,
        BottleSize: "500ml",
        RaffleWinCount: 5,
        RaffleWinAmount: "5,000",
        MainDrawWin: { FstPrize: "100", SndPrize: "200,000", TrdPrize: "50,000", FthPrize: "30", FivthPrize: "10" },
        MatchBall: { FstPrize: "6", SndPrize: "5", TrdPrize: "4", FthPrize: "3", FivthPrize: "2" },
    },
    PRIZE_STRUCT_MAGIC5: {
        BetAmount: 5,
        BallSelectCount: 5,
        BottleSize: "100ml",
        RaffleWinCount: 10,
        RaffleWinAmount: "1,000",
        MainDrawWin: { FstPrize: "500,000", SndPrize: "500", TrdPrize: "20", FthPrize: "5", FivthPrize: "1" },
        MatchBall: { FstPrize: "5", SndPrize: "4", TrdPrize: "3", FthPrize: "2", FivthPrize: "1" },
    },
    PRIZE_STRUCT_LUCKY1: {
        BetAmount: 5,
        // BallSelectCount: 5,
        BottleSize: "100ml",
        // RaffleWinCount: 10,
        // RaffleWinAmount: "1,000",
        // MainDrawWin: { FstPrize: "500,000", SndPrize: "500", TrdPrize: "20", FthPrize: "5", FivthPrize: "1" },
        // MatchBall: { FstPrize: "5", SndPrize: "4", TrdPrize: "3", FthPrize: "2", FivthPrize: "1" },
    }

}
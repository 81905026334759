import React, { Component } from 'react'
import { connect } from 'react-redux';
import { gameActions } from '../action/GameAction';
import { withTranslation } from 'react-i18next';
// let last_servertime_update = Date.now();
let RefetchStime = 5 ;
class Common extends Component {
	constructor(props) {
		super(props);
		this.state = { _serverTimeDiff: 0 };
		props.GetServerTime(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);//mobileNo
		props.GameFamily(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);//typeID,mobileno,langID
	}

	componentDidMount() {
		// let mythis = this;
		// window.addEventListener("visibilitychange", function () { mythis.handleFocus(); })
		setInterval(this.getServerTimeF, 1000);
	}
    
	getServerTimeF = () => {
		try {
			if (this.props.serverTime != undefined && this.props.serverTime != null && this.props.serverTime >= 0) {
				this.props.SetServerTime((this.props.serverTime + 1000));
				//let d = new Date();
				//let dd = (d.getMinutes() % 100);
				RefetchStime = RefetchStime - 1  ;
				if (RefetchStime == 0) {
					RefetchStime = 5;
					//console.log("server Time Problem " + d.toString());
					var ss = this;
					setTimeout(() => { ss.getlatestTime() }, 100);
				}
			}
			else {
				return 0;
			}
			// last_servertime_update = Date.now();
		}
		catch (ex) {
			console.log(ex);
			return 0;

		}
	};

	// handleFocus = () => {
	// 	try {
	// 		if (document.visibilityState == "visible") {
	// 			console.log("lastCountDownTime", last_servertime_update)
	// 			let newCountDownTime = Date.now();
	// 			let diffCountDownTime = newCountDownTime - last_servertime_update
	// 			console.log('diffCountDownTime', diffCountDownTime)
	// 			if (diffCountDownTime > 300000) { //time difference in 5min (300000)           
	// 				window.location.reload();
	// 			}
	// 		}
	// 	}
	// 	catch (ex) {
	// 		console.log("document visibilityState", ex)
	// 	}
	// }

	getlatestTime = () => {
		try {
			let date1 = new Date();
			let dateMDY = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
			let dateTime = ("0" + date1.getHours()).slice(-2) + ":" + ("0" + date1.getMinutes()).slice(-2) + ":" + date1.getSeconds();
			var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
			let ss = new Date((dateMDY + " " + dateTime).replace(pattern, '$3-$2-$1').replace(/-/g, '/'));
			let LocalTime = (ss.getTime());
			//console.log("LocalTime from db : " + LocalTime);
			console.log("LocalTime | serverTime : " + LocalTime+" | "+this.props.serverTime);
			let dd = LocalTime - this.props.serverTime;
			//console.log("diff Time from db : " + dd);
			let difTime = Math.abs(dd) / 1000;
			console.log("diff Time from db [S | MS] : " + dd+" | "+difTime);
			if (this.state._serverTimeDiff == 0) {
				this.state._serverTimeDiff = difTime;
			}
			else {
				let _newDiff= Math.abs(this.state._serverTimeDiff - difTime);
				if (_newDiff > 9) {
					console.log("this.state._serverTimeDiff : " + this.state._serverTimeDiff);
					console.log("props.GetServerTime() ");
					this.props.GetServerTime(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code);
 					this.state._serverTimeDiff= 0;
				}
			}
		}
		catch (ex) {
			console.log(ex);
		}
	}

	render() {
		return (
			<>
			</>
		)
	};
}


function mapStateToProps(state) {
	const { serverTime } = state.commonReducer;
	const { appvariables, user } = state.loginReducer;
	return { serverTime, appvariables, user };
}
const mapActionToProps = {
	GameFamily: gameActions.GameFamily,
	SetServerTime: gameActions.SetServerTime,
	GetServerTime: gameActions.GetServerTime
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Common))
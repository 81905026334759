import { combineReducers } from 'redux';
import { loginReducer } from './loginReducer';
import { gameReducer } from './gameReducer';
import { commonReducer } from './commonReducer';
import { accountReducer } from './accountReducer';
import { alertReducer } from './alertReducer';

const rootReducer = combineReducers({
  loginReducer,
  gameReducer,
  commonReducer,
  accountReducer,
  alertReducer
});

export default rootReducer;
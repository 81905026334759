import React, { Component } from 'react';
import { commonClass } from './helpers/commonClass';
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        let _dd = errorInfo ? ", errorInfo : " + JSON.stringify(errorInfo) : "";
        commonClass.writeLog(error + _dd, "componentDidCatch");

        this.setState({
            error: error
        })
        this.state.error = error;

    }

    render() {
        const { t } = this.props;
        if (this.state.error) {

            return (
                <div style={{ textAlign: "center" }}>
                    <h2 style={{ color: "red" }}>{t('lblPleaseTryAgain')}.<a href={`${process.env.REACT_APP_BASENAME}/Home`}> {t('lblClick')}</a></h2>

                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(ErrorBoundary)
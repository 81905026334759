import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { gameActions } from "../../action/GameAction";
import { Helmet } from "react-helmet";
import Viewdetailedresults from "./Viewdetailedresults";
import CommonPopup from "./WinnerPopup";
import { appVariables } from '../../helpers/appVariables';
import { history } from "../../helpers/history";

class Previousresult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawType: props.drawtype,
            fromDate: '',
            toDate: '',
            serverDate: new Date(),
            PastDrawStateObj: {},
            ViewAllResultFlag: false,
            IsValidData: false,
            _dbdrawType: props.send_db_id
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            AnotherPrevResult: props.AnotherPrevResult,
            RaffleResult: props.RaffleResult && props.RaffleResult.RaffleResultLst,
            // RaffleResult: props.RaffleResult,
            breakdownlist: props.breakdownlist
        };
    };
    onchangeDate = (ldate) => {
        var fdate = ldate.split('-')[0].trim();
        var tdate = ldate.split('-')[1].trim();
        let checkdate = this.validatedate(fdate, tdate);
        this.state.fromDate = fdate;
        this.state.toDate = tdate;
        if (checkdate) {
            this.props.GetPreviousResult(this.state._dbdrawType, fdate, tdate, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, 1);
        }
    }
    validatedate = (fromDate, toDate) => {
        let datepattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var fr = (new Date(fromDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var to = (new Date(toDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var currDate = ("0" + this.state.serverDate.getDate()).slice(-2) + "/" + ("0" + (this.state.serverDate.getMonth() + 1)).slice(-2) + "/" + this.state.serverDate.getFullYear();
        var lserverDate = (new Date(currDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        if (fr > lserverDate || to > lserverDate) {
            this.showMsg('lblNoFuturedate');
            return false;
        }
        return true;
        if (fr > to) {
            this.showMsg('lblFrdateGreater');
            return false;
        }
        fr.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        if (fr > to || fr < to) {
            var date = this.state.serverDate;
            var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
            var cdate = new Date(new Date(ss.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var lfr = ("0" + fr.getDate()).slice(-2) + "/" + ("0" + (fr.getMonth() + 1)).slice(-2) + "/" + fr.getFullYear();
            var lto = ("0" + to.getDate()).slice(-2) + "/" + ("0" + (to.getMonth() + 1)).slice(-2) + "/" + to.getFullYear();
            var fdate = new Date(new Date(lfr.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var tdate = new Date(new Date(lto.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            cdate.setHours(0, 0, 0, 0);
            fdate.setHours(0, 0, 0, 0);
            tdate.setHours(0, 0, 0, 0);
            if (fdate > cdate && tdate > cdate) {
                this.showMsg('lblCurrentDate');
                return false;
            }
        }
        return true;
    }

    componentDidMount() {
        window.FavouritesPopUp();
        //window.Loaddaterange(1);
        //var lstrdate = document.getElementById('mydaterange').value;
        var date = new Date();
        var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();

        var date1 = new Date();
        date1.setDate(date1.getDate() - 30);

        var ss1 = ("0" + date1.getDate()).slice(-2) + "/" + ("0" + (date1.getMonth() + 1)).slice(-2) + "/" + date1.getFullYear();
        let _startDate = ss1;
        let _endDate = ss;
        var lstrdate = _startDate + "-" + _endDate
        this.onchangeDate(lstrdate);
        window.getdateFromJS(this.onchangeDate);
        localStorage.setItem('drawtabId', 1);
    }
    componentDidUpdate(prevState, prevProps) {
        if (this.state.AnotherPrevResult != prevProps.AnotherPrevResult && this.state.AnotherPrevResult && this.state.AnotherPrevResult.length > 0 && this.state.AnotherPrevResult != null && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            window.allDrawResultPopUp();
        }
    }
    showMsg = (lstrError) => {
        this.props.alertError(this.props.t(lstrError));
    }
    GotoPastResultPage = () => {
        this.setState({ ViewAllResultFlag: true })
    }
    BactGotoPastResultPage = () => {
        setTimeout(() => {
            window.allDrawResultPopUp();
            window.FavouritesPopUp();
            window.Loaddaterange(2, this.state.fromDate, this.state.toDate);
            window.getdateFromJS(this.onchangeDate);
            //document.getElementById('mydaterange').value = this.state.fromDate + " - " + this.state.toDate;
        });
        this.state.ViewAllResultFlag = false;
        this.setState({ ViewAllResultFlag: false });
    }
    GetLatestWinner = (drawid) => {
        let draw_id = 0;
        if (drawid != 0) {
            draw_id = drawid.draw_id;
            this.setState({ PastDrawStateObj: drawid })
        }
        this.props.GetRaffleResults(this.state._dbdrawType, draw_id, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
    }


    render() {
        const { t } = this.props;
        return (
            <>
                {process.env.REACT_APP_MODE == "production" &&
                    <Helmet>
                        {this.state.drawType == 9 ? <title>Gulf Ticket - Fortune 5 Draw Results - Meet Our Winners</title>
                            : this.state.drawType == 10 ? <title>Gulf Ticket - Super 6 Draw Results - Meet Our Winners</title>
                                : <title>Gulf Ticket - Magic 5 Draw Results - Meet Our Winners</title>}
                        <meta name="description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 5 ,Super 6 and Magic 5 lotteries." />
                        <meta name="keywords" content="Gulf Ticket, lottery, UAE, Fortune 5, Super 6,Magic 5 draw results, raffle, lottery, winners, draw, winning numbers, mega prize, Gulf Ticket schedule and results" />

                        {this.state.drawType == 9 ? <>
                            <meta property="og:title" content="Gulf Ticket - Fortune 4 Draw Results - Meet Our Winners" />
                            <meta property="og:image" content="https://gulfticket.com/assets/img/past-draws/Fortune4.png" />
                        </> :
                            this.state.drawType == 10 ?
                                <>
                                    <meta property="og:title" content="Gulf Ticket - Super 6 Draw Results - Meet Our Winners" />
                                    <meta property="og:image" content="https://gulfticket.com/assets/img/past-draws/super6.jpg" />
                                </>
                                :
                                <>
                                    <meta property="og:title" content="Gulf Ticket - Magic 5 Draw Results - Meet Our Winners" />
                                    <meta property="og:image" content="https://gulfticket.com/assets/img/past-draws/magic-5.jpg" />
                                </>
                        }
                        <meta property="og:site_name" content="Gulf Ticket" />
                        <meta property="og:url" content="https://gulfticket.com" />
                        <meta property="og:description" content="Stay ahead of the game with Gulf Ticket's draw results, featuring the latest winning numbers for both Fortune 5 ,Super 6 and Magic 5 lotteries." />
                        <meta property="og:type" content="website" />


                    </Helmet>
                }
                {/* margin-left-7 true Part */}
                {!this.state.ViewAllResultFlag && <div className={this.state.drawType == 9 ? "profileCont" : "profileCont topAlign"}> 
                    <div className="pastHeaderAlign">
                        <span></span>
                        {
                            this.state._dbdrawType == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune-4-logo" />
                                : this.state._dbdrawType == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png" alt="super-6-logo" />
                                    : this.state._dbdrawType == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="lucky-1-logo" />
                                        : this.state._dbdrawType == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic-5-logo" /> : <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune-5-logo" />
                        }
                        {/* <img src={this.state.drawType == 9 ? "./assets/img/gamesBg/fortune-4-logo.png" 
                        : this.state.drawType == 10 ? "./assets/img/gamesBg/super-6-logo.png" 
                        : "./assets/img/gamesBg/magic-5-logo.png"} alt="fortune 5 logo" className="h-10 w-24" /> */}
                        {/* {(this.state.drawType == 9) ? <h3 className="pastHeadersMin">{t('lblFortune5')}</h3> : <h3 className="pastHeadersMin">{t('lblSuperSix')}</h3>} */}
                        <span></span>
                    </div>
                    <div className="pastDrawTopHead">
                        <div className="width65">
                            <div className={`winnerPost bg-game${this.state.drawType}`}>
                                {this.state.drawType != 13 ? <h3>{t('lblViewWinners')} {this.state.drawType == 9 ? appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount :
                                    this.state.drawType == 10 ? appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount :
                                        appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} {t('lblguranteed')}<br />{t('lblWinner')}</h3> : <h3>{t('lblViewWinners')}{t('lblWinner')}</h3>}
                                <div className="flexCenter gap20">
                                    {this.state.drawType != 13  && <div>
                                        <a id="show-popup" className="show-popup smallpopupopen" onClick={() => { this.GetLatestWinner(0) }}>{t('lblViewLatestRes')}</a>
                                    </div>}
                                    <div>
                                        <a onClick={() => this.GotoPastResultPage()}>{t('lblViewAllDrawHistory')}</a>
                                        {/* className="show-popup" */}
                                    </div>
                                </div>
                                {/* <a id="show-popup" onClick={() => { this.GetLatestWinner(0) }}>{t('lblViewLatestRes')}</a> */}
                            </div>
                        </div>
                        {/* <div className="drawDatesAlign">
                            <button className="inputDateRangeBtn">
                                <img src="./assets/img/icons/calendar.svg" />
                                <input style={{ cursor: "pointer", backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" dir="ltr" readOnly />
                            </button>
                        </div> */}
                        {/* VIEW LATEST WINNERS POPUP */}
                        <div id="popup-container" className="popup-container smallpopup">
                            <div id="popup-content" className="popup-content no-scrollbar" >
                                <div id="rightAlign" className="rightAlign">
                                    <button id="close-popup" className="close-popup smallpopupclose">
                                        <img src="./assets/img/icons/Close-Btn.svg" alt="Close Icon"/>
                                    </button>
                                </div>
                                <div className={`popup-main bg-game${this.state.drawType}`}>
                                    {this.state.drawType != 13 ?
                                        <div className="viewLatestHead1">
                                            {this.state.RaffleResult && this.state.RaffleResult.length > 0 && this.state.RaffleResult[0].draw_date && <div className="dateDraw">
                                                <div>{this.state.RaffleResult && this.state.RaffleResult.length > 0 && this.state.RaffleResult[0].draw_date && this.state.RaffleResult[0].draw_date.length > 0 && <> {this.state.RaffleResult[0].draw_date.split(',')[0].split(' ')[1]} {this.state.RaffleResult[0].draw_date.split(',')[0].split(' ')[2]}</>}</div>
                                                <div>{this.state.RaffleResult && this.state.RaffleResult.length > 0 && this.state.RaffleResult[0].draw_date && this.state.RaffleResult[0].draw_date.length > 0 && this.state.RaffleResult[0].draw_date.split(',')[1].split(' ')[0]}</div>
                                            </div>}
                                            <div className="drawHeader">
                                                {this.state.RaffleResult && this.state.RaffleResult.length > 0 ? <div>{this.state.RaffleResult.length} {t('lblguranteed')}</div> :
                                                    <div>{this.state.drawType == 9 ? appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount : this.state.drawType == 10 ? appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount : appVariables.PRIZE_STRUCT_MAGIC5.RaffleWinCount} {t('lblguranteed')}</div>}
                                                <div>{t('lblRaffleWinners')}</div>
                                            </div>
                                        </div>
                                        : <div className="viewLatestHead1"><div className="drawHeader"><div>{t('lblViewWinners')}{t('lblWinner')}</div></div></div>}
                                    <div className="winnersList bg-white">
                                        {this.state.RaffleResult && this.state.RaffleResult.length == 0 &&
                                            <div>
                                                <p className='marginAuto' style={{ textAlign: "center" }}>{t('lblresultprogress')}</p>
                                                {/* <div><span>{t('lblNoRecordFound')}</span></div> */}
                                            </div>}
                                        {this.state.RaffleResult && this.state.RaffleResult.length > 0 && this.state.RaffleResult.map((result,inx) =>
                                            <div className="winnersListAlign" key={`ai_${inx}`}>
                                                <div className="firstColumnName">
                                                    <div>{result.Player_name}</div>
                                                    <div>{result.Player_age} {result.Player_age ? " " + t('lblOld') : ""}  {result.Player_city ? " " + t('lblFrom') : ""}<span>{result.Player_city}</span></div>
                                                </div>
                                                <div className="secondColumnDetails">
                                                    <div><span>{t('lblRafflaID')}:</span><span> #{result.raffleid}</span></div>
                                                    <div><span>{t('lblWon')}:</span> <span> {process.env.REACT_APP_CURRENCY} {result.winamount}</span></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="capitalize py-1 px-5 !text-white font-semibold mx-auto !bg-brand rounded-md  border-2  close-popup smallpopupclose">
                                        {t('lblclose')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* MAIN DRAW RESULTS CLICK POPUP */}
                        <CommonPopup PastDrawStateObj={this.state.PastDrawStateObj} drawType={this.state.drawType} />
                    </div>
                    {/* <div className="drawDatesAlign">
                         // <input id="mydaterange" className="daterange" type="text" name="daterange" /> 
                            <div className="filterByDate" style={{ cursor:"pointer",backgroundColor: '#df911a', borderRadius: '30px', width: '30vw', textAlign: "center" }}>
                                <img src="./assets/img/icons/calendar-icon.png" />
                                <input style={{ cursor:"pointer",backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" />
                            </div>
                        </div> */}
                    <div className="maindrawContent">
                        <h1 className="mainDrawWinnerText marginBottomNone">{t('lblMainDrawResult')}</h1>
                        <div className="currentlyShowing">{t('lblCurrRec')}</div>
                        {(this.state.AnotherPrevResult == null || (this.state.AnotherPrevResult && this.state.AnotherPrevResult.length == 0)) &&
                            <p className="mainDrawWinnerText" style={{ textTransform: "initial", fontSize: "20px" }}>{t('lblNoRecordFound')}</p>
                        }
                        <div className="row">
                            {this.props.AnotherPrevResult && this.props.AnotherPrevResult.map((prevresult,ine) =>
                                <div className="pastDrawResultsSection" key={`aj_${ine}`}>
                                    <div className="pastDrawResultSubSection bg-white">
                                        <div className="WinningCome dashed-border">
                                            <span>{t('lblWinningCombination')}</span>
                                            {this.state.drawType != 13 && <div className={`WinBall border-game${this.state.drawType}`}>
                                                {prevresult.win_number && prevresult.win_number.split(';').length > 0 && prevresult.win_number.split(';').map((winNos,index) =>
                                                    <span key={`ak_${index}`} className={`bg-game${this.state.drawType} !text-white`}>{winNos}</span>
                                                )}
                                            </div>}
                                            {this.state.drawType == 13 && prevresult.win_number && prevresult.win_number.split(';').length > 0 && prevresult.win_number.split(';').map((winNos,inx) =>
                                                <ul className="zodiacBallSec Latestlukyresult game-13-balls rowGap" key={`aj_${inx}`}>
                                                    <li id="ball_526_2">
                                                                <a className={`game-13-balls-${parseInt(winNos)}-ld game-13`}>
                                                                    <span>{winNos}</span>
                                                                </a></li>
                                                </ul>
                                                // <span className={`bg-game${this.state.drawType} !text-white`}>{winNos}</span>
                                            )}
                                        </div>
                                        <div className="dashed-border itemsCenter flexCol justifyCent">
                                            <div className="text-center">
                                                <span>{t('lblTotalPrize')}:</span>
                                                <p>{process.env.REACT_APP_CURRENCY} {prevresult.total_prize}</p>
                                            </div>
                                            <div className="text-center margin-top10">
                                                <span>{t('lblTotalWinners')}:</span>
                                                <p>{prevresult.total_winner}</p>
                                            </div>
                                        </div>
                                        <div className="detailsSection flex gap-8">
                                            <a className="show-popup1 bigpopupopen" onClick={() => { this.GetLatestWinner(prevresult) }}><img src="./assets/img/icons/Notes-3.svg" alt="details" /></a>
                                        </div>
                                        <div className={`dateAbsoluteSection bg-game${this.state.drawType}`}>{prevresult.draw_date && prevresult.draw_date.split(',')[0]}</div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="pastDrawBtn mt-10">
                            <button onClick={() => { this.GotoPastResultPage() }}
                                className={`border-game${this.state.drawType} border-[1px] border-solid  px-14 py-2  rounded-full hover:bg-game${this.state.drawType} ${this.state.drawType != 11 ? `active:bg-game${this.state.drawType}` : ''} hover:text-white`}
                            // className="!border-game11 hover:!bg-game11 hover:!text-[#494949]"
                            >
                                <a>{t('lblViewAllDrawHistory')}</a>
                            </button>
                        </div>
                    </div>
                </div >}

                {this.state.ViewAllResultFlag && <Viewdetailedresults drawtype={this.state.drawType} showClose={() => { this.BactGotoPastResultPage() }} send_db_id={this.state._dbdrawType} />}
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { breakdownlist } = state.accountReducer;
    const { AnotherPrevResult, RaffleResult } = state.gameReducer;
    return { user, AnotherPrevResult, RaffleResult, breakdownlist };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetPreviousResult: gameActions.GetPreviousResult,
    GetRaffleResults: gameActions.GetRaffleResults,
    ClearGetRaffleResults: gameActions.ClearGetRaffleResults
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Previousresult))
import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loginActions } from "../../action/loginAction";
import { gameActions } from "../../action/GameAction";
import { history } from "../../helpers/history";
import Header from "../../Header/Header";
import { commonClass } from "../../helpers/commonClass";
class MyFavirote extends Component {
    constructor(props) {
        super(props);
        //only allow logged in users
        if (!props.user || props.user.mobileNo == "0") {
            history.push('/login');
        }
        this.state = {
            showdDeleteMsg: false,
            creditStatus: 0,
            creditStatus1: 0,
            creditStatus2: 0,
            creditStatus3: 0,
            allStatus: 0
        }
        this.showFavPop();
    }

    static getDerivedStateFromProps(props, state) {
        return {
            FavNo: props.FavNo && props.FavNo.map(function (ele) {
                return { ...ele, game_group_id_temp: ele.game_group_id == 12 ? 9 : ele.game_group_id };
            })
        }
    }

    PlayAgain = (gameNo, gameID) => {
        gameNo = gameID + '~' + gameNo;
        localStorage.setItem('setfavNo', gameNo);
        if (gameID == 9) {
            history.push('/fortune4');
        }
        else if (gameID == 10) {
            history.push('/super6');
        }
        else if (gameID == 11) {
            history.push('/magic5');
        }
        else {
            history.push('/lucky1');
        }
    }

    showMsg = (Msg) => {
        this.props.alertError(Msg);
    }
    componentDidMount() {
        //window.FavouritesPopUp();
        // localStorage.setItem('atabId', 7); this code is using in Account Component
    }
    componentDidUpdate() {
        if (this.props.ObjDeleteFavNo && this.props.ObjDeleteFavNo.Status_id == 0 && this.state.showdDeleteMsg == true) {
            this.state.showdDeleteMsg = false;
            this.props.GetfavNo(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        }
    }
    showFavPop = () => {
        this.props.GetfavNo(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
    }
    DeletefavNo = (id, Obj) => {
        this.props.DeletefavNo(id, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.state.showdDeleteMsg = true;
        this.SendTracingCode(Obj)
    }

    SendTracingCode = (Obj) => {
        let uObj = {};
        Obj['gamegroupID'] = Obj.game_group_id;
        Obj['BetSelection'] = Obj.numbers.split(';');
        Obj['stakeValue'] = Obj.game_group_id == 9 ? 10 : Obj.game_group_id == 11 ? 5 : Obj.game_group_id == 10 ? 30 : 0;
        let SavemyNo = [Obj]
        commonClass.SendEventTracking(13, uObj, false, SavemyNo);
    }

    chkbtnClick = (id) => {
        if (id == 9) {
            this.setState({ creditStatus: (this.state.creditStatus == 0 ? id : 0) });
            this.state.creditStatus = (this.state.creditStatus == 0 ? id : 0);
        }
        else if (id == 10) {
            this.setState({ creditStatus1: (this.state.creditStatus1 == 0 ? id : 0) });
            this.state.creditStatus1 = (this.state.creditStatus1 == 0 ? id : 0);
        }
        else if (id == 11) {
            this.setState({ creditStatus2: (this.state.creditStatus2 == 0 ? id : 0) });
            this.state.creditStatus2 = (this.state.creditStatus2 == 0 ? id : 0);
        }
        else if (id == 13) {
            this.setState({ creditStatus3: (this.state.creditStatus3 == 0 ? id : 0) });
            this.state.creditStatus3 = (this.state.creditStatus3 == 0 ? id : 0);
        }

        if ((this.state.creditStatus == 0 && this.state.creditStatus1 == 0 && this.state.creditStatus2 == 0 && this.state.creditStatus3 == 0) || (this.state.creditStatus != 0 && this.state.creditStatus1 != 0 && this.state.creditStatus2 != 0 && this.state.creditStatus3 != 0)) {
            this.setState({ allStatus: 0 });
        }
        else {
            this.setState({ allStatus: 1 });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="profileCont">
                    <div className="marTop" id="favouriteNumbers">
                        <div id="">
                            <div id="">
                                <div className="divBorder">
                                    <div className="accHeadCont favouriteFiltersAlign">
                                        <div className="radioInputs">
                                            <div className="filterBlock">{t('lblFilterBy')}:</div>
                                            <div className="checkbox-container" >
                                                <input checked={this.state.creditStatus3 == 13} onClick={() => { this.chkbtnClick(13) }} id="r4" type="checkbox" name="group1" className="custom-checkbox" />
                                                {/* className="gtRadio" */}
                                                <label for="r4" className="font18"></label>
                                                {/* <span htmlFor="r2">{t('lblSuperSix')}</span> */}
                                                <span className="" onClick={() => { this.chkbtnClick(13) }}> <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="super 5 logo" className="h-8 w-20" /> </span>
                                            </div>

                                            <div className="checkbox-container">
                                                <input checked={this.state.creditStatus2 == 11} onClick={() => { this.chkbtnClick(11) }} id="r3" type="checkbox" name="group1" className="custom-checkbox" />
                                                <label for="r3" className="font18"></label>
                                                {/* <span htmlFor="r3">{t('lblmagic5')}</span> */}
                                                <span className=""  onClick={() => { this.chkbtnClick(11) }}> <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="h-8 w-20" /> </span>
                                            </div>
                                            <div className="checkbox-container" >
                                                <input checked={this.state.creditStatus == 9} onClick={() => { this.chkbtnClick(9) }} id="r1" type="checkbox" name="group1" className="custom-checkbox" />
                                                {/* className="gtRadio" */}
                                                <label for="r1" className="font18"></label>
                                                {/* <span>{t('lblFortune5')}</span> */}
                                                <span className="" onClick={() => { this.chkbtnClick(9) }}> <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune-4 logo" className="h-8 w-20" /> </span>
                                            </div>
                                            <div className="checkbox-container" >
                                                <input checked={this.state.creditStatus1 == 10} onClick={() => { this.chkbtnClick(10) }} id="r2" type="checkbox" name="group1" className="custom-checkbox" />
                                                {/* className="gtRadio" */}
                                                <label for="r2" className="font18"></label>
                                                {/* <span htmlFor="r2">{t('lblSuperSix')}</span> */}
                                                <span className="" onClick={() => { this.chkbtnClick(10) }}> <img src="./assets/img/gamesBg/super-6-logo.png" alt="super 5 logo" className="h-8 w-20" /> </span>
                                            </div>



                                            {/* <div className="checkbox-container">
                                                <input checked={this.state.creditStatus3 == 12} onClick={() => { this.chkbtnClick(12) }} id="r4" type="checkbox" name="group1" className="custom-checkbox" />
                                                <label for="r4" className="font18"></label>
                                                <span className=""> <img src="./assets/img/gamesBg/others-logo.png?v0.3" alt="fortune 5 logo" className="h-8 w-20" /> </span>
                                            </div> */}

                                            {/* <img onClick={() => { this.setState({ creditStatus: 0 }) }} src="./assets/img/icons/cross.svg" alt="cancel button" className="crossImg" /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="favourites">
                                    <ul className="ordersSection">
                                        {this.state.FavNo && this.state.FavNo.length == 0 && <p className="text-center mt-5" >{t('lblNoRecordFound')}</p>}
                                        {/* {this.state.FavNo && this.state.FavNo.filter(filterdata => {
                                            return (this.state.creditStatus2 == 0 && this.state.creditStatus == 0 && this.state.creditStatus1 == 0 || this.state.creditStatus2 == 0) || (this.state.creditStatus2 != 0 && this.state.creditStatus != 0 && this.state.creditStatus1 != 0 && this.state.creditStatus3 != 0) ?
                                                filterdata : ((filterdata.game_group_id == this.state.creditStatus2 || filterdata.game_group_id == this.state.creditStatus || filterdata.game_group_id == this.state.creditStatus1 || filterdata.game_group_id == this.state.creditStatus3))
                                        }).map((x, inx) => */}
                                        {this.state.FavNo && this.state.FavNo.filter(fdata => this.state.allStatus == 0 || fdata.game_group_id == this.state.creditStatus || fdata.game_group_id == this.state.creditStatus1 || fdata.game_group_id == this.state.creditStatus2 || fdata.game_group_id == this.state.creditStatus3).map((x,index) =>
                                            <li key={`bh_${index}`}>
                                                <div className="favourtesCont">
                                                    <div className="ordersSelect">
                                                        <div className="favouriteNumbers">
                                                            <span className="">
                                                                {
                                                                    x.game_group_id == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune 4 logo" className="h-8 w-20" />
                                                                        : x.game_group_id == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png" alt="super-6 logo" className="h-8 w-20" />
                                                                            : x.game_group_id == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="h-8 w-20" /> :
                                                                                x.game_group_id == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-8 w-20" /> :
                                                                                    <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune-5-logo" className="h-8 w-20" />
                                                                }
                                                                {/* <img src={x.game_group_id == 9 ? "./assets/img/gamesBg/fortune-5-logo.png"
                                                                    : x.game_group_id == 11 ? "./assets/img/gamesBg/magic-5-logo.png"
                                                                        : "./assets/img/gamesBg/super-6-logo.png"} alt="magic 5 logo" className="h-8 w-20" /> */}
                                                            </span>
                                                            {/* {x.numbers && x.numbers.split(';').length == 5 ?
                                                                <h3>{t('lblFortune5')}</h3> :
                                                                <h3>{t('lblSuperSix')}</h3>
                                                            } */}
                                                            <div
                                                                // className="favouriteBalls !border-game9 border-[1px]"
                                                                className={`favouriteBalls border-game${x.game_group_id_temp} ${x.game_group_id_temp != 10 ? 'border-[1px]' : ''}`}>
                                                                {x.game_group_id_temp != 13 && x.numbers.split(';').map((No, inx) =>
                                                                    <span key={`bj_${inx}`} className={`bg-game${x.game_group_id_temp}`}>{No}</span>
                                                                )}

                                                                {x.game_group_id == 13 && x.numbers.split(';').map((No, inx) =>
                                                                    <div key={`bi_${inx}`} className={`lckmyCartBall lckmyCartBallOrdrs border-game13 game-13-balls-${parseInt(No)}`} >
                                                                        <span style={{ color: '#47BE49' }}>{No}</span>
                                                                    </div>)}
                                                            </div>
                                                            <div className="favouritesFlex">
                                                                {x.game_group_id != 12 && <button className={`bg-game${x.game_group_id_temp}`} onClick={() => this.PlayAgain(x.numbers, x.game_group_id)}>{t('lblBuyAgain')}</button>}
                                                                <button className="deleteBtn" onClick={() => { this.DeletefavNo(x.favorite_id, x) }}>
                                                                    <img src="./assets/img/delete.png?v0.1" alt="delete button" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { FavNo, ObjDeleteFavNo } = state.gameReducer;
    return { user, FavNo, ObjDeleteFavNo };
}
const mapActionToProps = {
    alertError: alertActions.error,
    changepassword: loginActions.changepassword,
    GetfavNo: gameActions.GetfavNo,
    BackFromCart: gameActions.BackFromCart,
    DeletefavNo: gameActions.DeletefavNo
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MyFavirote))
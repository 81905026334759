import React, { Component } from 'react'
import { connect } from 'react-redux';
import { gameActions } from '../action/GameAction'
import Barcode from 'react-barcode';
import { withTranslation } from 'react-i18next';
import { alertActions } from '../../component/action/AlertAction';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import ReactToPrint from "react-to-print";
class BetSlip extends Component {
    constructor(props) {
        super(props);
        this.state = { counter: 1 }
    }

    PrintBetSlip = (id) => {
        window.PrintBetSlip(id);
        return;
    };
    downloadPdfDocument = (downloadFileName) => {
        let downID = "qwinprint1";
        let downDivID = "qwinprint";
        document.getElementById(downID).style.maxHeight = "none"
        let input = document.getElementById(downDivID);
        html2canvas(input)
            .then((canvas) => {
                let imgData = canvas.toDataURL('image/png');
                //let pdf = new jsPDF();
                //pdf.addImage(imgData, 'JPEG', 20, 0);
                let pdf = new jsPDF("p", "mm", "a4");
                var width = pdf.internal.pageSize.getWidth();
                var height = (pdf.internal.pageSize.getHeight() - 10);
                pdf.addImage(imgData, 'JPEG', 3, 3, 140, input.offsetHeight * 0.4);
                // pdf.addImage(imgData, 'JPEG', 3, 3, width, input.offsetHeight*0.6);
                pdf.save(`${downloadFileName}.pdf`);
            })
        document.getElementById(downID).style.removeProperty("max-height")
    }

    getPrintCont = () => {
        let downID = "qwinprint1";
        let downDivID = "qwinprint";
        document.getElementById(downID).style.maxHeight = "none"
        let input = document.getElementById(downDivID).cloneNode(true);
        document.getElementById(downID).style.removeProperty("max-height");
        input.style.marginTop = '30px'
        return input;
    }
    render() {
        const { alertReducer } = this.props;
        const { t } = this.props;
        let flst = [];
        let _transID = "";
        let _gameID = "";
        let _gameTime = "";
        function dd(Id, GameTime, GameID) {
            _transID = Id;
            _gameTime = GameTime;
            _gameID = GameID;
        }
        function tfilte(betLst) {
            flst = [...new Set(betLst.map(item => item.GameID))]
        }
        return (
            <>
                {this.props.myReBet && this.props.myReBet.status == 0 && <>
                    <div className="lightbox" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                        <div className="lightBox-wrap">
                            <a className="lightBoxClose" onClick={() => { this.props.closeBetSlip() }}><img src="./assets/img/icons/close.webp" alt='close icon'/></a>
                            <div className="newTicket" id='qwinprint'>
                                {dd(this.props.myReBet.betLst[0].Id, this.props.myReBet.betLst[0].GameTime, this.props.myReBet.betLst[0].GameID)}
                                <div className="newTicketInner ticketSlipInner no-scrollbar" id='qwinprint1'>
                                    <div className='ticketLogo'><img src="./assets/img/logo.webp" alt='Gulf logo icon'/></div>
                                    <div className='dateAndTime1 gameTicketDetails'>
                                        <h1>{t('lblTicketDet')}</h1>
                                        <span className="time">{t('lblTransId')}: {_transID}</span>
                                        <span className="time !pt-0">{t('lblDrawDate')}: {this.props.myReBet.betLst[0].GameTime}</span>
                                    </div>

                                    {tfilte(this.props.myReBet.betLst)}
                                    {flst.map((x1, idx1) => <>
                                        {this.props.myReBet.betLst.filter(a => a.GameID == x1).map((x, idx) =>
                                            // <div className={x.GameName.toLowerCase().indexOf('super') >= 0 ? 'ticketsContainer super6Ticket' : "ticketsContainer"}>
                                            <div className="ticketsContainer !px-4" key={`r_${idx1}`}>
                                                {/* <section className={x.GameName.toLowerCase().indexOf('super') >= 0 ? "purchasedTicketContainer purchaseTicketContainerRadial2 topTicketMargin game2BackgroundColor" : "purchasedTicketContainer  topTicketMargin bg1 !px-4"}> */}
                                                <section className={`purchasedTicketContainer ticket-cut-game-${x.gamegroupID} topTicketMargin bg-game${x.gamegroupID}`}>
                                                    <div className="ticketsAlign">
                                                        <span className="purchaseTicketHead">{x.GameName}</span>
                                                        <div className="ticketNumber">{t('lblTicket')} #{idx + 1}</div>
                                                        {x.gamegroupID == 13  && <div className="raffleId">{t('lblentryamt')}: {x.StakeValue}</div> 
                                                        // :<div className="raffleId">{t('lblRafflaID')}: {x.RaffleID}</div>
                                                        }
                                                    </div>
                                                    {x.gamegroupID == 13 ?
                                                        <div>
                                                            <div className={`lckmyCartBallTkt game-13-balls-${parseInt(x.BallNo)}`}></div>
                                                            <span className="lckTktNo">{parseInt(x.BallNo)}</span>
                                                        </div>
                                                        : <div>
                                                            <div className="selectedNumbers">
                                                                {x.BallNo.split(';').map((x2, idx2) => <span key={`s_${idx2}`}  className="individualNumber" > {x2}</span>)}
                                                            </div>
                                                            <div className="raffleId text-center">{t('lblRafflaID')}: {x.RaffleID}</div>
                                                        </div>
                                                    }
                                                </section>
                                            </div>
                                        )}
                                        <section className="px-4 pt-2" >
                                            {/* style={{ padding: '15px 0' }}  <p className="ticketSlipcontent">
                                            <span>{t('lblDrawDate')}</span>
                                            <span>{this.props.myReBet.betLst[0].GameTime}</span>
                                        </p> */}
                                            {/* <p className="ticketSlipcontent">
                                            <span>{t('lblGrandPrize')}</span>
                                            <span>{this.props.appvariables.currency} {this.props.myReBet.betLst[0].PrizeAmount}</span>
                                        </p> */}
                                            <p className="ticketSlipcontent">
                                                <span>{t('lblPlays')}</span>
                                                <span>{this.props.myReBet.betLst.length}</span>
                                            </p>
                                            <p className="ticketSlipcontent">
                                                <span>{t('lblStatus')}</span>
                                                <span>{this.props.myReBet.ticketStatus}</span>
                                            </p>
                                            <p className="ticketSlipcontent">
                                                <span>{t('lblSubmitted')}</span>
                                                <span>{this.props.myReBet.betLst[0].TransDateTime}</span>
                                            </p>
                                            <p className="ticketSlipcontent">
                                                <span>{t('lblTicketId')}</span>
                                                <span>{_transID}</span>
                                            </p>
                                            <p className="ticketSlipcontent">
                                                <span>{t('lblTotalSpend')}</span>
                                                <span>{this.props.appvariables.currency} {this.props.myReBet.betLst.reduce((a, b) => a = a + b.StakeValue, 0)}</span>
                                            </p>
                                            {this.props.myReBet.payment_mode != "" &&
                                                <p className="ticketSlipcontent">
                                                    <span>{t('lblModeOfPay')}</span>
                                                    <span>{this.props.myReBet.payment_mode}</span>
                                                </p>}
                                            {this.props.myReBet.winamt != 0 &&
                                                <p className="ticketSlipcontent">
                                                    <span>{t('lblWinnings')}</span>
                                                    <span>{this.props.appvariables.currency} {this.props.myReBet.winamt}</span>
                                                </p>}
                                        </section>
                                        <div className="dateAndTime1" style={{ "width": "0%" }}>
                                            <div className="barCode">
                                                <Barcode displayValue="true" height="50px" format="CODE128" value={_transID} />
                                            </div>
                                        </div>
                                        {this.props.myReBet.buttomMsg && this.props.myReBet.buttomMsg != "" &&
                                            <span className="copyrgtTicket">{this.props.myReBet.buttomMsg}</span>
                                        }
                                    </>
                                    )}
                                </div>
                            </div>
                            <div className="DownLoadBtn">
                                <a onClick={() => { this.downloadPdfDocument(_transID) }}><img src="./assets/img/icons/inbox_download.webp" alt='inbox_download icon'/></a>
                                <ReactToPrint
                                    trigger={() => <a><img src="./assets/img/icons/printer.webp" alt='printer icon'/></a>}
                                    content={() => this.getPrintCont()}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="overlay" style={{ display: "block" }}></div>
                </>}
                {
                    this.props.myReBet && this.props.myReBet.status != 0 && <>
                        <div className="lightbox2" style={{ transform: "translate(-50%, -50%)", backgroundColor: 'transparent', display: "block" }}>
                            <div className="newCo">
                                <div className="newCoInner">
                                    {/* <div className="newCoInnerLft">
                                        <img src="./assets/img/icons/Betslip.png" />
                                        <span>{t('lblgameslip')}</span>
                                    </div> */}
                                    <div className="newCoInnerRgt" onClick={() => { this.props.closeBetSlip() }}>
                                        <i className="fa fa-times" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div className="newCoWrap">
                                    <div className="betSec">
                                        <div className="betValCont">
                                            <div className="playAmount">
                                                <span className="plyAmntTxt" style={{ textAlign: "center", width: "100%", fontSize: "16px", fontFamily: "'helvetica-condensed-bold'" }}>{this.props.myReBet.msg}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="overlaybetsilp" style={{ display: "block" }}></div>
                    </>
                }
                {
                    alertReducer.loading && <div id="divLoading">
                        <div id="center">
                            <div className="lds-dual-ring"><div></div><div></div></div>
                        </div>
                    </div>
                }
                {
                    alertReducer.message && <>
                        <div className="lightboxAlert AlertpopUp" style={{ transform: "translate(-50%, -50%)", display: "block" }}>
                            <div className="lightBox-wrap" style={{ borderColor: alertReducer.alertcolor }}>
                                <div className="userContent">
                                    <h2 style={{ color: alertReducer.alertcolor, textAlign: "center", padding: "20px", fontSize: "16px" }}>{alertReducer.message}</h2>
                                    <div className="altBtns">
                                        {/* <button style={{ backgroundColor: alertReducer.alertcolor, fontSize: "16px" }} onClick={() => { this.props.clearAlerts(alertReducer.loginFlag) }}>{t('lblOk')}</button> */}
                                        <button className="bg3" style={{ backgroundColor: alertReducer.alertcolor, fontSize: "16px" }} onClick={() => { this.props.clearAlerts(alertReducer.loginFlag) }}>{t('lblOk')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: "block" }} className="overlayAlert"></div>
                    </>
                }
            </>
        )
    };
}

function mapStateToProps(state) {
    const { myReBet } = state.gameReducer;
    const { appvariables } = state.loginReducer;
    const { alertReducer } = state;
    return { myReBet, appvariables, alertReducer };
}
const mapActionToProps = {
    closeBetSlip: gameActions.closeBetSlip,
    loading: alertActions.loading,
    clearAlerts: alertActions.clear,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(BetSlip))
import { commonClass } from "../helpers/commonClass";
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);

export const accountService = {
	GetTransactionDel,
	Cashdesposite,
	getProfile,
	updateProfile,
	Cashoutmoney,
	GetConfirmkyc,
	GetAllResult,
	GetPlayerLedger,
	GetPriceBreakdownlst
}

const _apiUrl = process.env.REACT_APP_API_URL;
async function postFetch(_url, data, mobileNo, langID, loginKey, retrycnt = 0) {
	try {
		let _option = await commonClass.requestOptions(data, mobileNo, langID, loginKey);
		return fetch(`${_apiUrl}${_url}/`, {
			..._option,
			//retryDelay: 1000,
			retryOn: function (attempt, error, response) {
				// retry on any network error, or 4xx or 5xx status codes
				// if ((error !== null || ( response && response.status == 403))) {
				if (response && response.status == 401) {
					console.log(`retrying, attempt number ${attempt + 1}`);
					//return attempt >= 2 ? false : true;         
					if (retrycnt < 1) {
						retrycnt++;
						postFetch(_url, data, mobileNo, langID, loginKey, retrycnt);
					}
					return false
				}
			}
		})
			.then(commonClass.handleResponse)
			.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "postFetch=>" + _url);
	}
}
async function getFetch(_url, mobileNo, langID, loginKey, country_code, retrycnt = 0) {
	try {
		// 'getplayerprofile', mobileNo, langID, sessionKey, country_code
		let _option = await commonClass.getRequestOptions(mobileNo, langID, loginKey, country_code);
		return fetch(`${_apiUrl}${_url}/`, {
			..._option,
			retryOn: function (attempt, error, response) {
				// retry on any network error, or 4xx or 5xx status codes
				// if (error !== null || response.status == 403) {
				if (response && response.status == 401) {
					console.log(`retrying, attempt number ${attempt + 1}`);
					//return attempt >= 2 ? false : true;         
					if (retrycnt < 1) {
						retrycnt++;
						getFetch(_url, mobileNo, langID, loginKey, country_code, retrycnt)
					}
					return false
				}
			}
		})
			.then(commonClass.handleResponse)
			.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "getFetch=>" + _url);
	}
}
function GetTransactionDel(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('transactionhistory', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}transactionhistory/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
function Cashdesposite(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('addmoney', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}addmoney/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}

function Cashoutmoney(data, mobileNo, langID, sessionKey) {
	try {
		// return postFetch('withdrawmoney', data, mobileNo, langID, sessionKey)
		return postFetch('withdrawmoney_v1', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}withdrawmoney/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
function getProfile(mobileNo, langID, sessionKey, country_code) {
	try {
		return getFetch('getplayerprofile', mobileNo, langID, sessionKey, country_code)
		// return fetch(`${_apiUrl}getplayerprofile/`, commonClass.getRequestOptions(mobileNo, langID, sessionKey, country_code))
		// 	.then(commonClass.handleResponse)
		// 	.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "getprofile");
	}
}

function updateProfile(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('updplayerprofile', data, mobileNo, langID, sessionKey)
		// return fetch(`${_apiUrl}updplayerprofile/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "profileDetails");
	}
}

function GetConfirmkyc(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('kycverify', data, mobileNo, langID, sessionKey)
		// return fetch(`${_apiUrl}kycverify/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "profileDetails");
	}

}
function GetAllResult(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('getresults', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}getresults/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
function GetPlayerLedger(data, mobileNo, langID, sessionKey) {
	try {
		return postFetch('playerreports', data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}playerreports/`, commonClass.requestOptions(data, mobileNo, langID, sessionKey))
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
function GetPriceBreakdownlst(mobileNo, langID, sessionKey, Ccode) {
	try {
		// get_game_scheme - non catching api url
		// return getFetch('get_game_scheme', mobileNo, langID, sessionKey,Ccode)
		return getFetch('get_scheme', mobileNo, langID, sessionKey, Ccode)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
		// return fetch(`${_apiUrl}get_game_scheme/`, commonClass.getRequestOptions(mobileNo, langID, sessionKey)) //Without catch
		// 	//return fetch(`${_apiUrl}get_scheme/`, commonClass.getRequestOptions(mobileNo, langID, sessionKey)) //with catch
		// 	.then(commonClass.handleResponse)
		// 	.then(results => {
		// 		if (results != null && results.data != null) { results = results.data; }
		// 		return results;
		// 	});
	} catch (ex) {
		commonClass.writeLog(ex);
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class AboutFortureFive extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { i18n } = this.props;
        return (
            <>
                <div className="w-full flex justify-center items-center gap-4 mb-3">
                    <span>{i18n.language === 'ur' ? 'کے بارے میں ' : i18n.language === 'ml' ? 'നെകുറിച്ച്' : i18n.language === 'ar' ? 'حول اللعبة' : 'About'} </span><img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 5 logo" className="h-10 w-24" />
                </div>
                {i18n.language === 'ur' ?
                    <div id="about-me">
                        <div className='wrap2'>
                            {/* <h2 className="aboutGameContentHead">کے بارے میں  <span><img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 5 logo" className="h-10 w-24" /></span></h2> */}
                            <div className="aboutgameContentCont"  style={{ justifyContent: "normal" }}>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBox1">
                                        <div>
                                            <div className="bg-game9">1</div>
                                        </div>
                                        <div className="bg-game9"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 marginLft0 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune_11.svg" alt='Fortune4 Icon'/>
                                        </div>

                                        <div className="text-center margin-top2 text-size-13">
                                            ہمارے ہفتہ وار ریفل ڈرا اور مین ڈرا میں ایک بار میں داخل ہونے کے لیے {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount} اماراتی دینار میں بوتل خریدیں!
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game9">2</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game9"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune-4_12.svg?v0.1" alt='Fortune4 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2 text-size-13">
                                            فی ٹکٹ 1 سے 36 کے درمیان کوئی بھی {appVariables.PRIZE_STRUCT_FORTUNE5.BallSelectCount} نمبر منتخب کریں۔ یا، آپ رینڈمائزیشن "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" آئیکن پر کلک کر سکتے ہیں اور ہم آپ کے لیے آپ کے نمبر منتخب کرتے ہیں۔
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game9">3</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game9"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune_13.svg" alt='Fortune4 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2 text-size-13">
                                            AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} * کا عظیم الشان انعام جیتنے کے لیے کسی بھی ترتیب سے ان کے مین ڈرا سے میچ کریں۔
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game9">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game9"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune-raffle.svg?v0.4" alt='Fortune4 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2 text-size-13">
                                            ریفل ڈرا: ہر ہفتے {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} گارنٹی یافتہ فاتح! ہر فاتح AED {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount} وصول کرتا ہے۔
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game9">5</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune_15.svg" alt='Fortune4 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2 text-size-13">
                                            قرعہ اندازی ہفتہ وار جمعہ کو متحدہ عرب امارات کے وقت کے مطابق رات 8 بجے ہوتی ہے، ہمارے یوٹیوب چینل اور فیس بک پیج پر لائیو۔
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorderLast bg-game9">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game9">6</div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game9 marginRht0 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/fortune_16.svg" alt='Fortune4 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2 text-size-13">
                                            گلف ٹکٹ پر پانی کی بوتل خرید کر آپ متحدہ عرب امارات میں کھجور کے درختوں کی باغبانی کی ترقی میں ہماری مدد کر رہے ہیں۔
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    i18n.language === 'ml' ?
                        <div id="about-me">
                            <div id="wrap2">
                                {/* <h2 className="aboutGameContentHead">നെകുറിച്ച് <span><img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 5 logo" className="h-10 w-24" /></span></h2> */}
                                <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBox1">
                                            <div>
                                                <div className="bg-game9">1</div>
                                            </div>
                                            <div className="bg-game9"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 marginLft0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune_11.svg" alt='Fortune4 Icon'/>
                                            </div>

                                            <div className="text-center margin-top2 text-size-13">
                                                ഞങ്ങളുപ്പട രപതിൊര റാഫിൾ ട്രഡാ ുിം പ്പമ ിൻ ട്രഡാ ുിം ഒറ്റ ടിക്ക് നൽകുന്നതി {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount}് 5 ദിർഹത്തിന് ഒരു കുെി ൊങ്ങുക!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game9">2</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game9"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune-4_12.svg?v0.1" alt='Fortune4 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2 text-size-13">
                                                ഓരോ ടിക്കറ്റിനും 1 മുതൽ 36 വരെയുള്ള ഏതെങ്കിലും {appVariables.PRIZE_STRUCT_FORTUNE5.BallSelectCount} നമ്പറുകൾ തിരഞ്ഞെടുക്കുക. അല്ലെങ്കിൽ, നിങ്ങൾക്ക് റാൻഡമൈസേഷൻ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ഐക്കണിൽ ക്ലിക്ക് ചെയ്യാം, ഞങ്ങൾ നിങ്ങൾക്കായി നിങ്ങളുടെ നമ്പറുകൾ തിരഞ്ഞെടുക്കും.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game9">3</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game9"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune_13.svg" alt='Fortune4 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2 text-size-13">
                                                രപധാന നറുപ്പക്കടുെ്: {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} * ദിർഹത്തിനപ്പ്റ രഗാൻഡ് സരപസ് ട്നടുന്നതിന് ഏത് രകമത്തിലുിം അെ മാച്ച് പ്പെയ്യുക
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game9">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game9"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune-raffle.svg?v0.4" alt='Fortune4 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2 text-size-13">
                                                റാഫിൾ ഡ്രോ: എല്ലാ ആഴ്‌ചയും {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} വിജയികൾ ഉറപ്പ്! ഓരോ വിജയിക്കും {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount} ദിർഹം ലഭിക്കും.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game9">5</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune_15.svg" alt='Fortune4 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2 text-size-13">
                                                ആഴ്ചതോറും വെള്ളിയാഴ്ചകളിൽ യുഎഇ സമയം രാത്രി 8 മണിക്ക് നറുക്കെടുപ്പ് നടക്കുന്നു, ഞങ്ങളുടെ YouTube ചാനലിലും Facebook പേജിലും തത്സമയം.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorderLast bg-game9">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game9">6</div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game9 marginRht0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/fortune_16.svg" alt='Fortune4 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2 text-size-13">
                                                ഗൾഫ് ടിക്കറ്റിൽ ഒരു ൊേർ കുെി ൊങ്ങുന്നതിലൂപ്പട, ുസണറ്റഡ് അറബ് എമിട്ററ്റിപ്പല ഈന്തെന ട്താേങ്ങളുപ്പട െികസനത്തിൽ നിങ്ങൾ ഞങ്ങപ്പള സഹാ ിക്കുന്നു.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> :
                        i18n.language === 'ar' ?
                            <div id="about-me">
                                <div id="wrap2">
                                    {/* <h2 className="aboutGameContentHead">حول اللعبة <span><img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 5 logo" className="h-10 w-24" /></span></h2> */}
                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBox1" >
                                                <div>
                                                    <div className="bg-game9">1</div>
                                                </div>
                                                <div className="bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 marginLft0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_11.svg" alt='Fortune4 Icon'/>
                                                </div>

                                                <div className="text-center margin-top2 text-size-13">
                                                    قم بشراء زجاجة بقيمة {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount} دراهم للدخول في السحب الأسبوعي والسحب الرئيسي دفعة واحدة!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">2</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune-4_12.svg?v0.1" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    اختر أي {appVariables.PRIZE_STRUCT_FORTUNE5.BallSelectCount} أرقام بين 1 إلى 36 لكل تذكرة. أو يمكنك النقر على أيقونة التوزيع العشوائي "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" وسنختار أرقامك لك.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">3</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_13.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    السحب الرئيسي: قم بمطابقة هذه العناصر بأي ترتيب للفوز بالجائزة الكبرى بقيمة {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize} *
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune-raffle.svg?v0.4" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    : {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} فائزين مضمونين كل أسبوع! يحصل كل فائز على {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount} درهم إماراتي.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">5</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_15.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    يتم إجراء السحوبات أسبوعياً أيام الجمعة الساعة 8 مساءً بتوقيت الإمارات، مباشرة على قناتنا على اليوتيوب وصفحتنا على الفيسبوك.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorderLast bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">6</div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 marginRht0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_16.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    من خلال شراء زجاجة مياه من موقع تذكرة الخليج، فإنك تساعدنا في تطوير مزارع أشجار النخيل في دولة الإمارات العربية المتحدة.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div id="about-me">
                                <div className='wrap2'>
                                    {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 5 logo" className="h-10 w-24" /></span></h2> */}
                                    <div className="aboutgameContentCont" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBox1">
                                                <div>
                                                    <div className="bg-game9">1</div>
                                                </div>
                                                <div className="bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 marginLft0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_11.svg" alt='Fortune4 Icon'/>
                                                </div>

                                                <div className="text-center margin-top2 text-size-13">
                                                    Purchase a Bottle for AED {appVariables.PRIZE_STRUCT_FORTUNE5.BetAmount} to enter our weekly RAFFLE DRAW and MAIN DRAW in one
                                                    go!
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">2</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune-4_12.svg?v0.1" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    Choose any {appVariables.PRIZE_STRUCT_FORTUNE5.BallSelectCount} numbers between 1 to 36 per ticket. Or, you can click on the randomisation "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" icon and we select your numbers for you.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">3</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_13.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    Main Draw: Match them in ANY ORDER to win the Grand Prize of AED {appVariables.PRIZE_STRUCT_FORTUNE5.MainDrawWin.FstPrize}*.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">4</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9"></div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune-raffle.svg?v0.4" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    Raffle Draw: {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_FORTUNE5.RaffleWinAmount}.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">5</div>
                                                </div>
                                                <div className="gameAboutBorder bg-game9">&nbsp;</div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_15.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    Draws take place weekly on Fridays at 8 PM UAE Time, Live on our YouTube channel and Facebook page.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox">
                                            <div className="aboutGameContentBoxType2">
                                                <div className="gameAboutBorderLast bg-game9">&nbsp;</div>
                                                <div className="aboutGameContentBoxType2Div">
                                                    <div className="bg-game9">6</div>
                                                </div>
                                            </div>
                                            <div className="aboutGameContentBox2 border-[1px] border-game9 marginRht0 rem9">
                                                <div className="topDetailDiv">
                                                    <img src="./assets/img/icons/fortune_16.svg" alt='Fortune4 Icon'/>
                                                </div>
                                                <div className="text-center margin-top2 text-size-13">
                                                    By purchasing a water bottle on Gulf Ticket, you are assisting us in the development
                                                    of palm tree plantations in the United Arab Emirates.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                }
            </>
        )
    }
}
function mapStateToProps(state) {
    return {};
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutFortureFive))

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { loginActions } from '../../action/loginAction';
import { appVariables } from '../../helpers/appVariables';


class AboutSuperSix extends Component {

    render() {
        const { i18n } = this.props;
        return (
            <>
                <div className="w-full flex justify-center items-center gap-4 mb-3">
                    <span>{i18n.language === 'ur' ? 'کے بارے میں ' : i18n.language === 'ml' ? 'നെകുറിച്ച്' : i18n.language === 'ar' ? 'حول اللعبة' : 'About'} </span><img src="./assets/img/gamesBg/super-6-logo.png?v0.3" alt="magic 1 logo" className="h-10 w-24" />
                </div>
                {i18n.language === 'ur' ?
                    <div className='wrap2'>
                        {/* <h2 className="aboutGameContentHead">کے بارے میں  <span><img src="./assets/img/gamesBg/super-6-logo.png" alt="Super 6 logo" className="h-10 w-24" /></span></h2> */}
                        <div className="aboutgameContentCont" id="about-me">
                            <div className="aboutGameContentBox" style={{ justifyContent: "normal" }}>
                                <div className="aboutGameContentBox1">
                                    <div>
                                        <div className="bg-game10">1</div>
                                    </div>
                                    <div className="bg-game10"></div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginLft0 rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_11.svg" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        ہمارے ہفتہ وار ریفل ڈرا اور مین ڈرا میں ایک بار میں داخل ہونے کے لیے {appVariables.PRIZE_STRUCT_SUPER6.BetAmount} اماراتی دینار میں بوتل خریدیں!
                                    </div>
                                </div>
                            </div>
                            <div className="aboutGameContentBox">
                                <div className="aboutGameContentBoxType2">
                                    <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                    <div className="aboutGameContentBoxType2Div">
                                        <div className="bg-game10">2</div>
                                    </div>
                                    <div className="gameAboutBorder bg-game10"></div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_12.svg" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        فی ٹکٹ 1 سے 49 کے درمیان کوئی بھی {appVariables.PRIZE_STRUCT_SUPER6.BallSelectCount} نمبر منتخب کریں۔ یا، آپ رینڈمائزیشن "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" آئیکن پر کلک کر سکتے ہیں اور ہم آپ کے لیے آپ کے نمبر منتخب کرتے ہیں۔
                                    </div>
                                </div>
                            </div>
                            <div className="aboutGameContentBox">
                                <div className="aboutGameContentBoxType2">
                                    <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                    <div className="aboutGameContentBoxType2Div">
                                        <div className="bg-game10">3</div>
                                    </div>
                                    <div className="gameAboutBorder bg-game10"></div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_13.svg" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} ملین* کا عظیم الشان انعام جیتنے کے لیے کسی بھی ترتیب سے ان کے مین ڈرا سے میچ کریں۔
                                    </div>
                                </div>
                            </div>
                            <div className="aboutGameContentBox">
                                <div className="aboutGameContentBoxType2">
                                    <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                    <div className="aboutGameContentBoxType2Div">
                                        <div className="bg-game10">4</div>
                                    </div>
                                    <div className="gameAboutBorder bg-game10"></div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_14.svg?v0.1" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} گارنٹی یافتہ فاتح! ہر فاتح AED {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} وصول کرتا ہے۔
                                    </div>
                                </div>
                            </div>
                            <div className="aboutGameContentBox">
                                <div className="aboutGameContentBoxType2">
                                    <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                    <div className="aboutGameContentBoxType2Div">
                                        <div className="bg-game10">5</div>
                                    </div>
                                    <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_15.svg" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        قرعہ اندازی ہفتہ کو متحدہ عرب امارات کے وقت کے مطابق رات 8 بجے ہوگی، ہمارے یوٹیوب چینل اور فیس بک پیج پر لائیو۔
                                    </div>
                                </div>
                            </div>
                            <div className="aboutGameContentBox">
                                <div className="aboutGameContentBoxType2">
                                    <div className="gameAboutBorderLast bg-game10">&nbsp;</div>
                                    <div className="aboutGameContentBoxType2Div">
                                        <div className="bg-game10">6</div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginRht0 rem9">
                                    <div className="topDetailDiv">
                                        <img src="./assets/img/icons/super_16.svg" alt='Super6 Icon'/>
                                    </div>
                                    <div className="text-center margin-top2">
                                        گلف ٹکٹ پر پانی کی بوتل خرید کر آپ متحدہ عرب امارات میں کھجور کے درختوں کی باغبانی کی ترقی میں ہماری مدد کر رہے ہیں
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    : i18n.language === 'ml' ?
                        <div className='wrap2'>
                            {/* <h2 className="aboutGameContentHead">നെകുറിച്ച് <span><img src="./assets/img/gamesBg/super-6-logo.png" alt="Super 6 logo" className="h-10 w-24" /></span></h2> */}
                            <div className="aboutgameContentCont" id="about-me">
                                <div className="aboutGameContentBox" style={{ justifyContent: "normal" }}>
                                    <div className="aboutGameContentBox1">
                                        <div>
                                            <div className="bg-game10">1</div>
                                        </div>
                                        <div className="bg-game10"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginLft0 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_11.svg" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            ഞങ്ങളുപ്പട രപതിൊര റാഫിൾ ട്രഡാ ുിം പ്പമ ിൻ ട്രഡാ ുിം ഒറ്റ ടിക്ക് നൽകുന്നതിന് {appVariables.PRIZE_STRUCT_SUPER6.BetAmount} ദിർഹത്തിന് ഒരു കുെി ൊങ്ങുക!
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game10">2</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game10"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_12.svg" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            ഓരോ ടിക്കറ്റിനും 1 മുതൽ 49 വരെയുള്ള ഏതെങ്കിലും {appVariables.PRIZE_STRUCT_SUPER6.BallSelectCount} നമ്പറുകൾ തിരഞ്ഞെടുക്കുക. അല്ലെങ്കിൽ, നിങ്ങൾക്ക് റാൻഡമൈസേഷൻ "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" ഐക്കണിൽ ക്ലിക്ക് ചെയ്യാം, ഞങ്ങൾ നിങ്ങൾക്കായി നിങ്ങളുടെ നമ്പറുകൾ തിരഞ്ഞെടുക്കും.
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game10">3</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game10"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_13.svg" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            രപധാന നറുപ്പക്കടുെ്: {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} ദശലക്ഷം* ദിർഹത്തിനപ്പ്റ രഗാൻഡ് സരപസ് ട്നടുന്നതിന് ഏത് രകമത്തിലുിം അെ ുമാ ി മാച്ച് പ്പെയ്യുക
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game10">4</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game10"></div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_14.svg?v0.1" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            റാഫിൾ നറുക്കെടുപ്പ്: എല്ലാ ആഴ്ചയും {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} വിജയികൾ ഉറപ്പ്! ഓരോ വിജയിക്കും {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} ദിർഹം ലഭിക്കും.
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game10">5</div>
                                        </div>
                                        <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_15.svg" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            നറുക്കെടുപ്പ് ആഴ്ചതോറും ശനിയാഴ്ചകളിൽ യുഎഇ സമയം രാത്രി 8 മണിക്ക് ഞങ്ങളുടെ YouTube ചാനലിലും ഫേസ്ബുക്ക് പേജിലും തത്സമയം നടക്കുന്നു
                                        </div>
                                    </div>
                                </div>
                                <div className="aboutGameContentBox">
                                    <div className="aboutGameContentBoxType2">
                                        <div className="gameAboutBorderLast bg-game10">&nbsp;</div>
                                        <div className="aboutGameContentBoxType2Div">
                                            <div className="bg-game10">6</div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginRht0 rem9">
                                        <div className="topDetailDiv">
                                            <img src="./assets/img/icons/super_16.svg" alt='Super6 Icon'/>
                                        </div>
                                        <div className="text-center margin-top2">
                                            ഗൾഫ് ടിക്കറ്റിൽ ഒരു ൊേർ ട്ബാേിൽ ൊങ്ങുന്നതിലൂപ്പട,  ുസണറ്റഡ് അറബ് എമിട്ററ്റിപ്പല ഈന്തെന ട്താേങ്ങളുപ്പട െികസനത്തിൽ നിങ്ങൾ ഞങ്ങപ്പള സഹാ ിക്കുന്നു.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : i18n.language === 'ar' ?
                            <div className='wrap2'>
                                {/* <h2 className="aboutGameContentHead">حول اللعبة <span><img src="./assets/img/gamesBg/super-6-logo.png" alt="Super 6 logo" className="h-10 w-24" /></span></h2> */}
                                <div className="aboutgameContentCont" id="about-me">
                                    <div className="aboutGameContentBox" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox1">
                                            <div>
                                                <div className="bg-game10">1</div>
                                            </div>
                                            <div className="bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginLft0 marginLft0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_11.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                قم بشراء زجاجة بقيمة {appVariables.PRIZE_STRUCT_SUPER6.BetAmount} درهماً للدخول في السحب الأسبوعي والسحب الرئيسي دفعة واحدة!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">2</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_12.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                اختر أي {appVariables.PRIZE_STRUCT_SUPER6.BallSelectCount} أرقام بين 1 إلى 49 لكل تذكرة. أو يمكنك النقر على أيقونة التوزيع العشوائي "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />" وسنختار أرقامك لك.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">3</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_13.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                السحب الرئيسي: قم بمطابقة هذه العناصر بأي ترتيب للفوز بالجائزة الكبرى بقيمة {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} مليون درهم *
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_14.svg?v0.1" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                سحب السحب: {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} فائزين مضمونين كل أسبوع! يحصل كل فائز على {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount} درهم إماراتي.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">5</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_15.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                يتم إجراء السحوبات أسبوعياً أيام السبت الساعة 8 مساءً بتوقيت الإمارات، مباشرة على قناتنا على اليوتيوب وصفحتنا على الفيسبوك.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorderLast bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">6</div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginRht0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_16.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                من خلال شراء زجاجة مياه من موقع تذكرة الخليج، فإنك تساعدنا في تطوير مزارع أشجار النخيل في دولة الإمارات العربية المتحدة.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='wrap2'>
                                {/* <h2 className="aboutGameContentHead">About <span><img src="./assets/img/gamesBg/super-6-logo.png" alt="Super 6 logo" className="h-10 w-24" /></span></h2> */}
                                <div className="aboutgameContentCont" id="about-me">
                                    <div className="aboutGameContentBox" style={{ justifyContent: "normal" }}>
                                        <div className="aboutGameContentBox1">
                                            <div>
                                                <div className="bg-game10">1</div>
                                            </div>
                                            <div className="bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginLft0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_11.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                Purchase a Bottle for AED {appVariables.PRIZE_STRUCT_SUPER6.BetAmount} to enter our weekly RAFFLE DRAW and MAIN DRAW in one
                                                go!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">2</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_12.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                Choose any {appVariables.PRIZE_STRUCT_SUPER6.BallSelectCount} numbers between 1 to 49 per ticket. Or, you can click on the randomisation "<img src="./assets/img/icons/shuffle.webp" alt="shuffle icon" className="randomIcon" />"  icon and we select your numbers for you.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">3</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_13.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                Main Draw: Match them in ANY ORDER to win the Grand Prize of AED {appVariables.PRIZE_STRUCT_SUPER6.MainDrawWin.FstPrize} MILLION*
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">4</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10"></div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_14.svg?v0.1" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                Raffle Draw: {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinCount} Guaranteed Winners every week! Each winner receives AED {appVariables.PRIZE_STRUCT_SUPER6.RaffleWinAmount}.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">5</div>
                                            </div>
                                            <div className="gameAboutBorder bg-game10">&nbsp;</div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_15.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                {/* Draws take place weekly on Fridays at 8 PM UAE Time, Live on our YouTube channel and Facebook page. */}
                                                Draws take place weekly on Saturdays at 8 PM UAE Time, Live on our YouTube channel and Facebook page.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="aboutGameContentBox">
                                        <div className="aboutGameContentBoxType2">
                                            <div className="gameAboutBorderLast bg-game10">&nbsp;</div>
                                            <div className="aboutGameContentBoxType2Div">
                                                <div className="bg-game10">6</div>
                                            </div>
                                        </div>
                                        <div className="aboutGameContentBox2 border-[1px] border-game10 before:!rounded-[12px] marginRht0 rem9">
                                            <div className="topDetailDiv">
                                                <img src="./assets/img/icons/super_16.svg" alt='Super6 Icon'/>
                                            </div>
                                            <div className="text-center margin-top2">
                                                By purchasing a water bottle on Gulf Ticket, you are assisting us in the development
                                                of palm tree plantations in the United Arab Emirates.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </>
        )
    }
}



function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(AboutSuperSix))
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../helpers/history';
import Header from '../Header/Header';
import { gameActions } from '../action/GameAction';
import { commonClass } from "../helpers/commonClass";
import { alertActions } from '../action/AlertAction';
class MyCart extends Component {
    constructor(props) {
        super(props);
        //only allow logged in users
        if (!props.user || props.user.mobileNo == "0") {
            history.push('/login');
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        }
    }

    gotoPayment = () => {
        if (this.state.cartItems.length > 60) {
            this.showMsg('lblmaxticket');
            return;
        }
        commonClass.SendPageDataLayer(8);
        commonClass.SendEventTracking(14, {}, false, this.state.cartItems);
        history.push('/payment');
        localStorage.removeItem('prevCartItem');
    }
    removeTicket = (obj) => {
        let gamePadID = obj.GamePadId;
        let gameID = obj.gamegroupID
        this.props.RemoveFromCart(gamePadID, gameID);
        let cobj = {};
        let tcartitem = [obj]
        commonClass.SendEventTracking(10, cobj, false, tcartitem);
        //as per issue #246 .
        // let cartLength = nJSON.parse(localStorage.getItem('mycart')).length;
        // if (cartLength == 0) {
        //     if (gameID == 9) {
        //         history.push('/fortune4');
        //     }
        //     else {
        //         history.push('/super6');
        //     }
        // }
    }
    backtogame = (gameID) => {
        let prevCartitem = this.state.cartItems;
        this.props.BackFromCart(prevCartitem, gameID);
    }
    showMsg = (msg, flag) => {
        this.props.alertError(this.props.t(msg), flag);
    }
    render() {
        const { t } = this.props
        let trasnIDs = [...new Set(this.state.cartItems.map(item => item.gamegroupID))];
        return (
            <>
                <Header />
                <div className="wrap1">
                    <h2 className="headding3 text-center">{t('lblMyCart')}</h2>
                </div>
                <div className="wrap1">
                    {(this.state.cartItems && this.state.cartItems.length > 0) ? <section>
                        <div className="col-lg-7 col-sm-12 col-xs-12 cartItems scrollbar2">
                            {trasnIDs.map((objId, inx) =>
                                <div key={`t_${inx}`} className="myCartLft" id='myCartDiv'>
                                    <div className="myCartLftTxt">
                                        {objId == 9 ?
                                            <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="Fortune 4 logo" className="h-10 w-24" /> :
                                            objId == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png" alt="super 6 logo" className="h-10 w-24" />
                                                : objId == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="h-10 w-24" />
                                                    : <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune 5 logo" className="h-10 w-24" />}
                                        <p>{t('lblCurrDraw')}:</p>
                                        <p>{this.state.cartItems.filter(a => a.gamegroupID == objId)[0].DrawShowTime}</p>
                                    </div>
                                    <ul>
                                        {this.state.cartItems.filter(a => a.gamegroupID == objId).map((obj, inx) =>
                                            // <li className={objId == 9 ? 'row ticketLineFlex' : 'ticketLineFlex'}>
                                            <li className='ticketLineFlex' key={`u_${inx}`}>
                                                <div className={`myCartLine bg-game${objId} col-xs-12 col-sm-3 col-md-3`} >{t('lblLine')} {inx + 1}</div>
                                                <div className={`myCartBall border-game${objId}`}>
                                                    {obj.BetSelection && obj.BetSelection.map((_bal, inx) =>
                                                        <span key={`v_${inx}`} className={`bg-game${objId}`}>{_bal}</span>
                                                    )}
                                                </div>
                                                <button type="button" className="text-center deleteBtn marginLeft1">
                                                    <img src="./assets/img/icons/delete.webp" alt="delete button" className="margin5Top" onClick={() => this.removeTicket(obj)} />
                                                </button>
                                                {/* <div style={{ width: "30%", marginRight: "-18%", float: "right", fontFamily: "SourceSans3-Bold", fontSize: "16px", textAlign: "center", marginTop: "-20px" }}><a style={{  marginLeft:  "-62px"  }} onClick={() => this.removeTicket(obj.GamePadId, obj.gamegroupID)}>X</a></div> */}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className='payNowBtn myCartPayNow'>
                                <button className="payNowBtn" onClick={() => this.backtogame(this.state.cartItems[0].gamegroupID)}>{t('lblBack')}</button>
                            </div>
                        </div>
                        <div className="col-lg-5 col-sm-12 col-xs-12">
                            <div className="myCartRht">
                                <div className="BetslipTop">
                                    <span className="span1">{t('lblItem')}</span>
                                    <span className="span2">{t('lblQuantity')}</span>
                                    <span className="span3">{t('lblAmt')}</span>
                                </div>
                                {trasnIDs.map((objId, inx) =>
                                    <div className="BetslipLi" key={`w_${inx}`}>
                                        <span className="span1">{this.state.cartItems.filter(a => a.gamegroupID == objId)[0].LotName}</span>
                                        <span className="span2">{this.state.cartItems.filter(a => a.gamegroupID == objId).length}</span>
                                        <span className="span3">{this.props.appvariables.currency} {this.state.cartItems.filter(a => a.gamegroupID == objId).reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                                    </div>
                                )}
                                <div className="BetslipLi">
                                    <span className="span4">{t('lblTotalamtPay')}</span>
                                    <span className="span3">{this.props.appvariables.currency} {this.state.cartItems.reduce((a, b) => a = a + b.stakeValue, 0)}</span>
                                </div>
                                <div className="payNowBtn myCartPayNow">
                                    <button onClick={() => { this.gotoPayment() }}>{t('lblPaynow')}</button>
                                </div>
                            </div>
                        </div>
                    </section> :
                        <section>
                            <div className="col-lg-7 col-sm-12 col-xs-12 cartItems">
                                {t('lblCartempty')}

                            </div>
                            <div className='payNowBtn myCartPayNow'>
                                <button className="payNowBtn" onClick={() => history.push('/home')}>{t('lblBactToHome')}</button>
                            </div>
                        </section>
                    }
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { cartItems } = state.gameReducer;
    return { user, cartItems, appvariables };
}
const mapActionToProps = {
    RemoveFromCart: gameActions.RemoveFromCart,
    BackFromCart: gameActions.BackFromCart,
    alertError: alertActions.error,

}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MyCart))
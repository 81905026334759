import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { loginActions } from './action/loginAction';
import { withTranslation } from 'react-i18next';
import { alertActions } from './action/AlertAction';
import Header from './Header/Header';
import { Helmet } from "react-helmet";
import AllCountries from './Pages/Home/Nationality.json';
// const countryLst = [{ id: 0, name: "Select Nationality" }, { id: 1, name: "India" }, { id: 2, name: "UAE" }, { id: 3, name: "Ban" }, { id: 4, name: "Philippines" }]
// const countryLst = [{ id: 0, name: "Select Nationality" }, { id: 1, name: "India" }, { id: 2, name: "UAE" }, { id: 3, name: "Qatar" }, { id: 4, name: "Oman" }]
const countryLst = AllCountries.Nationality;  // 177 Countries Loaded 
class RegisterActivation extends Component {
    constructor(props) {
        super(props);
        this.state = { regEmail: '', regNationality: 0 }
    }
    handleTextChange(e) {
        try {
            let value = e.target.value
            if (e.target.name == "regNationality") {
                const regex = /^[0-9]*$/;
                if (!regex.test(value) && !value == '') { return; }
            }
            this.setState({ [e.target.name]: value });
        } catch (ex) { }
    }
    btnActivation() {
        if (this.state.regEmail && this.state.regEmail.trim() != "") {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.regEmail)) {
                if (this.state.regNationality > 0) {
                    let nationname = countryLst.filter(Fobj => Fobj.id == this.state.regNationality)[0].name
                    this.props.RegisterActivate(this.props.otp.mobileNo, this.state.regEmail, parseInt(this.state.regNationality), this.props.i18n.language, "", this.props.otp.Ccode,nationname);
                } else { this.showMsg('lblNation'); }
            } else { this.showMsg('lblEnterValidEmail'); }
        } else { this.showMsg('lblenterEmail'); }
    }
    showMsg = (msg) => { this.props.alertError(this.props.t(msg)); }
    setSelected = (val) => {
        this.setState({ regNationality: val });
    }
    render() {
        const { t } = this.props;
        return (
            <>
               {process.env.REACT_APP_MODE == "production" && <Helmet>
                    <title>Gulf Ticket - Register Activation</title>
                    <meta name="description" content="Securely Access Your Gulf Ticket Account: Register Now" />
                    <meta name="keywords" content="Gulf Ticket, online raffle draw, Biggest Online Draw" />

                    <meta property="og:title" content="Gulf Ticket - Register Activation" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Securely Access Your Gulf Ticket Account: Register Now" />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
                </Helmet>}
                <Header />
                <div className="wrap2">
                    <div className="logReg">
                        <div className="logRegLi">
                            <div className="logRegTitle"><img src="./assets/img/icons/register.webp" alt='Register Activation Icon'/><h2>{t('lblSignActive')}</h2></div>
                            <div className='form'>
                                <input type="text" placeholder={`${t('lblEmail')}*`} name='regEmail' value={this.state.regEmail} onChange={this.handleTextChange.bind(this)} maxLength={50} autocomplete="off" />
                                {/* <input type="text" placeholder={`${t('lblNationality')}*`} name='regNationality' value={this.state.regNationality} onChange={this.handleTextChange.bind(this)} maxLength={20} autocomplete="off" /> */}
                                <div className="nationality">
                                    <select className="nationalityDropDown" onChange={e => this.setSelected(e.target.value)} defaultValue={this.state.regNationality}>
                                        {countryLst && countryLst.map((obj, inx) => (
                                            <option key={`q_${inx}`} value={obj.id}>
                                                {obj.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <button onClick={() => { this.btnActivation() }}>{t('lblSubmit')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user, otp } = state.loginReducer;
    return { user, otp };
}
const mapActionToProps = {
    RegisterActivate: loginActions.RegisterActivate,
    alertError: alertActions.error,
    //alertsucc: alertActions.success
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(RegisterActivation))
import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { accountActions } from "../../action/AccountAction";
import { commonClass } from "../../helpers/commonClass";
// import countrycode from '../Home/countrycode.json';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            player_name: '',
            phone_no: '',
            last_name: '',
            email: '',
            city: '',
            // country_code: countrycode,
            country_code: [],
            dob: '',
            password: '',
            saveSuccess: false,
            // Ccname: countrycode.default.Ccname,
            // mobnoLength: countrycode.default.mobNolength,
            // setCountryFlag: countrycode.default.flag,
            // setCountryCode: countrycode.default.code,
            Ccname: '',
            mobnoLength: '',
            setCountryFlag: '',
            setCountryCode: '',
            serverDate: new Date()
        };
        props.GetProfile(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code)
    }
    static getDerivedStateFromProps(props, state) {
        return { details: props.details }
    }
    componentDidMount() {
        this.GetCountryList()
        window.datepicker((this.state.details && this.state.details.dob) ? this.state.details.dob : this.state.serverDate);
        document.getElementById("datepicker1").value = (this.state.details ? this.state.details.dob : "");
        this.state.dob = document.getElementById("datepicker1").value;
        // localStorage.setItem('atabId', 1); this code is using in Account Component
    };
    componentDidUpdate(prevProps, prevState) {
        if (this.state.phone_no == "" && this.state.details && this.state.saveSuccess == false) {
            this.setState({
                player_name: this.state.details.player_name,
                phone_no: this.state.details.phone_no,
                last_name: this.state.details.last_name,
                email: this.state.details.email,
                city: this.state.details.city,
                setCountryCode: this.state.details.country_code,
                dob: this.state.details.dob,
                submitted: false,
                saveSuccess: true
            })
            let Country = this.state.country_code && this.state.country_code.other && this.state.country_code.other.filter(jsonobj => jsonobj.flag == this.state.details.country_code)
            if (Country == undefined || Country.length == 0) {
                this.state.Ccname = "";
            }
            else {
                this.state.Ccname = Country[0].Ccname;
            }
            window.datepicker((this.state.details && this.state.details.dob) ? this.state.details.dob : this.state.serverDate);
            document.getElementById("datepicker1").value = (this.state.details ? this.state.details.dob : "");
            this.state.dob = document.getElementById("datepicker1").value;
        }
    }
    ProfileUpdate = () => {
        if (this.isDate21orMoreYearsOld(document.getElementById("datepicker1").value)) {
            this.state.dob = document.getElementById("datepicker1").value;
            this.setState({ submitted: true })
            let { player_name, phone_no, last_name, email, city, country_code, dob } = this.state;
            if (player_name && phone_no && last_name && email && country_code && dob) {
                if (player_name.length <= 2) {
                    this.showMsg('lblFnamelength');
                }
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                    this.showMsg('lblEnterValidEmail')
                }
                else {
                    let DobTemp = document.getElementById("datepicker1").value;
                    this.setState({ saveSuccess: false });
                    player_name = player_name[0].toUpperCase() + player_name.slice(1);
                    last_name = last_name[0].toUpperCase() + last_name.slice(1);
                    this.props.UpdateProfile('profile-1', player_name, last_name, email, "000000000", DobTemp, city, document.getElementsByClassName("countryImg")[0].innerText, phone_no, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey)
                    //this.props.GetProfile(this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey, this.props.user.Country_code)
                }
            }
            else {
                this.showMsg('lblPleaseFillDet');
            }
        }
        else { this.showMsg('lblEnterValidDate'); }
    }


    isDate21orMoreYearsOld = (birthday) => {
        var lst = birthday.split('/');
        var optimizedBirthday = lst[2] + "/" + lst[1] + "/" + lst[0];
        var myBirthday = new Date(optimizedBirthday + ' 01:00:00');
        var currentDate = new Date().toJSON().slice(0, 10) + ' 01:00:00';
        var myAge = ~~((Date.now(currentDate) - myBirthday) / (31557600000));
        if (myAge <= 18) {
            return false;
        } else {
            return true;
        }
    }


    toggleCountry = () => {
        window.setCountryToggle();
    }
    countryChange = (lMobnolength, lcode, lflag) => {
        this.setState({ mobnoLength: lMobnolength, setCountryCode: lcode, setCountryFlag: lflag });
        window.setCountryToggle();
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name.toLowerCase().includes("phoneno") && value && (/^[0-9\b]+$/.test(value)) == false) { return; }
        else if ([e.target.name] == "player_name" || [e.target.name] == "last_name") {
            const regex = /^[a-zA-Z ]*$/;
            if (!regex.test(value) && !value == '') { return; }
            // value = value.replace(/[^A-Za-z]/ig, '')
        }
        this.setState({ [name]: value });
    }
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }

    GetCountryList = () => {
        let new_Version = 0;
        let Version_old = localStorage.getItem('countryver');
        if (this.props.appvariables && this.props.appvariables.bVersion) {
            let totalversion = this.props.appvariables.bVersion.split("~")
            if (totalversion.length > 0) {
                new_Version = totalversion[3];
            }
        }
        if (Version_old != new_Version) {
            commonClass.GetCountry(new_Version)
                .then(response =>
                    response.json()
                )
                .then(data => {
                    localStorage.setItem('countryver', new_Version);
                    localStorage.setItem('countrylst', JSON.stringify(data));
                    this.setState({ country_code: data })
                    this.setState({ Ccname: data.default.Ccname })
                    this.setState({ mobnoLength: data.default.mobNolength })
                    this.setState({ setCountryFlag: data.default.flag })
                    this.setState({ setCountryCode: data.default.code })
                });
        }
        else {
            let CountryList = JSON.parse(localStorage.getItem('countrylst')); // get from localstorage for countrylist
            this.setState({ country_code: CountryList })
            this.setState({ Ccname: CountryList.default.Ccname })
            this.setState({ mobnoLength: CountryList.default.mobNolength })
            this.setState({ setCountryFlag: CountryList.default.flag })
            this.setState({ setCountryCode: CountryList.default.code })
        }
    }
    render() {
        const { t } = this.props;
        return (
            <div className="profileCont">
                <div className="logReg myProfileDiv">
                    <h2 className="headding4 !float-none">{this.props.user.Player_name}</h2>
                    <div className="formContainer">
                        <div>
                            <div className="form grid grid-cols-2 gap-x-10 gap-y-3">
                                <div className="form-group">
                                    <label for="FullName">{t('lblFirstName')}<small className="requiredSymbol">*</small></label>
                                    <input type="text" className="form-control" id="txtfullname" placeholder={`${t('lblEnterFirstname')}*`} maxLength={30} autoComplete="off" name="player_name" value={this.state.player_name} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="Lastname">{t('lblLastName')}<small className="requiredSymbol">*</small></label>
                                    <input type="text" className="form-control" id="txtlastname" placeholder={`${t('lblEnterLastname')}*`} name="last_name" maxLength={30} autoComplete="off" value={this.state.last_name} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="Dob">{t('lblDOB')}<small className="requiredSymbol">*</small></label>
                                    <input type="text" className="form-control daterange" id="datepicker1" placeholder="dd/mm/yyyy" maxLength={10} autoComplete="off" name="dob" />
                                </div>
                                <div className="form-group">
                                    <label for="password">{t('lblPwd')}<small className="requiredSymbol">*</small></label>
                                    <input type="text" className="form-control" id="txtpassword" placeholder="************" name="password" maxLength={15} autoComplete="off" disabled value={this.state.password} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label for="Email">{t('lblEmail')}<small className="requiredSymbol">*</small></label>
                                    <input type="text" className="form-control" id="txtemail" placeholder={`${t('lblenterEmail')}*`} maxLength={50} autoComplete="off" name="email" value={this.state.email} onChange={this.handleChange} />
                                </div>
                                {/* <div className="form-group">
                                    <label for="contact">{`${t('lblContact')}*`}</label>
                                    <div className="userLogCont">
                                        <div className="countryCode">
                                            <a className="countryImg" onClick={() => this.toggleCountry()}>
                                                <img src={`./assets/img/icons/flags/${this.state.setCountryFlag}.png`} /><small>{this.state.Ccname}</small>{this.state.setCountryCode}
                                                <i className="fa fa-angle-down"></i>
                                            </a>
                                            <ul className="selContry" style={{ display: "none" }}>
                                                {this.state.country_code && this.state.country_code.other.map(data =>
                                                    <li><a onClick={() => this.countryChange(data.mobNolength, data.code, data.flag)}><img src={`./assets/img/icons/flags/${data.flag}.png`} /><small>{data.Ccname} </small>{data.code}</a></li>
                                                )}
                                            </ul>
                                        </div>
                                        <input type="text" className="form-control acntprof" id="" name="phone_no" value={this.state.phone_no} placeholder={t('lblMobileNo')} maxLength={this.state.mobnoLength} autoComplete="off" onChange={this.handleChange} disabled />
                                    </div>
                                </div> */}

                                {/* <div className="form-group">
                                    <label for="City">{`${t('lblCity')}*`}</label>
                                    <input type="text" className="form-control" id="txtCity" placeholder={`${t('lblEntrCity')}*`} name="city" maxLength={15} autoComplete="off" value={this.state.city} onChange={this.handleChange} />
                                </div> */}

                                <div className="form-group">
                                    <label for="contact">{t('lblContact')}</label>
                                    <div className="userLogCont">
                                        <div className="countryCode">
                                            {this.state.Ccname != "" && <a className="countryImg !w-10">
                                                <img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${this.state.setCountryCode.replace("+", "")}.png?v0`} />+{this.state.setCountryCode} <small>{this.state.Ccname}</small>
                                                {/* <i className="fa fa-angle-down"></i> */}
                                            </a>}
                                            <ul className="selContry" style={{ display: "none" }}>
                                                {this.state.country_code && this.state.country_code.other && this.state.country_code.other.map((data,index) =>
                                                    <li key={`bn_${index}`}><a onClick={() => this.countryChange(data.mobNolength, data.code, data.flag)} disabled><img src={`${process.env.REACT_APP_CDN_URL}/assets/flags/${data.flag}.png?v0`} />{data.code}</a></li>
                                                )}
                                            </ul>
                                        </div>
                                        {this.state.mobnoLength != '' && <input type="text" className="form-control acntprof" id="" name="phone_no" value={this.state.phone_no} placeholder={t('lblMobileNo')} maxLength={this.state.mobnoLength} autoComplete="off" onChange={this.handleChange} disabled />}
                                        {/* acntprof */}
                                    </div>
                                </div>
                            </div>

                            <div className="payNowBtn">
                                <button className="btn2" onClick={() => this.ProfileUpdate()}>{t('lblUpdate')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    const { details } = state.accountReducer;
    return { user, details, appvariables };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetProfile: accountActions.GetProfile,
    UpdateProfile: accountActions.UpdateProfile,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Profile))
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class SaleClose extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { t } = this.props;
        // Home Page 1 ,Lotto Page == 2
        return (<>
            {this.props.Pagetype == 1 && <h3 className={`text-game${this.props.lottotype} font-[portilla] !mt-4`}>
                <a>
                    <span className={`${this.props.lottotype == 10 ? "text-nowrap" : ""}`}>{this.props.currency} {this.props.drawswise.replace('#', t('lblMillion'))}*</span>
                </a>
            </h3>}
           <div className={`${this.props.Pagetype == 1 ? '' : 'padd2 gamesWidth'}`}>
                <div className={`${this.props.Pagetype == 1 ? '' : `gameCont bg-img${this.props.lottotype} gameCont2 marBottom gamesContainerHeight`}`}>  
                    <h4 className="text-center">{t('lblsaleclosed')}</h4>
                    <div className={`mt-${this.props.Pagetype == 1 ? '5' : '12'} w-full`}></div>
                    <div className="topBallSec"></div>
                    <div className="circlePosn">
                        <div className="half-circle"></div>
                        <div className="half-circle circle2"></div>
                    </div>
                    <div className="flex flex-col items-center my-16">
                        {this.props.lottotype == 9 ? <img
                            src="./assets/img/gamesBg/fortune-5-paused.svg"
                            alt="Fortune 5 Paused"
                            className={`${this.props.Pagetype == 1 ? '!w-60':'w-48'}`}
                            style={{width:'auto'}}
                        /> : this.props.lottotype == 10 ? <img
                            src="./assets/img/gamesBg/super-6-paused.svg"
                            alt="Fortune 5 Paused"
                            className={`${this.props.Pagetype == 1 ? '!w-60':'w-48'}`}
                            style={{width:'auto'}}
                        /> : <img
                            src="./assets/img/gamesBg/magic-5-paused.svg"
                            alt="Fortune 5 Paused"
                            className={`${this.props.Pagetype == 1 ? '!w-60':'w-48'}`}
                            style={{width:'auto'}}
                        />}
                        <p dangerouslySetInnerHTML={{ __html: t(`lblsalecldtext`) }} className={`text-lg font-semibold text-game${this.props.lottotype} mt-5 text-center`}>
                            
                        </p>
                    </div>
               </div>
            </div> 
        </>
        );
    }
}

function mapStateToProps(state) {

}
const mapActionToProps = {

}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(SaleClose))

import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { appVariables } from '../../helpers/appVariables';

class NewsLetter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PublishNews: [],
            currentPageNo: 0,
            NumberofRecord: 3,
            totalPage: 0,
            startinx: 0,
            endinx: 6,
            lfilterdt: [],
            IsValidData: false,
        }
    }

    componentDidMount() {
        this.setjsondata();
    }

    FormJsonintoArray(Publish) {
        let NewAr = [];
        let Exchange = [];
        for (let index = 0; index < Publish.length; index = index + 2) {
            for (let j = index; j < index + 2; j++) {
                if (Publish[j]) {
                    Exchange.push(Publish[j]);
                }
            }
            NewAr.push(Exchange);
            Exchange = [];
        }
        this.setState({ PublishNews: NewAr })
    }


    componentDidUpdate(prevState, prevProps) {
        if (this.state.PublishNews && this.state.PublishNews !== prevState.PublishNews && this.state.PublishNews.length != 0 && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            this.setState({ IsValidData: true })
            this.LoadTransactionData()
            // window.allDrawResultPopUp();
        }
    }


    setjsondata = () => {
        try {
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('pressnews');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[5];//5
                    localStorage.setItem('pressnews', hbanner);
                }
            }
            if (hbanner_old != hbanner) { // hbanner_old != hbannertrue
                var s = process.env.REACT_APP_CDN_URL + 'web/newsletter.json' + "?V" + hbanner;
                fetch(s).then(response => response.json()).then(data => {
                    localStorage.setItem('NewsLetterData', JSON.stringify(data.NewsLetterData));
                    this.FormJsonintoArray(data.NewsLetterData)
                })
            } else {
                let NewsletterDate = JSON.parse(localStorage.getItem('NewsLetterData'));
                this.FormJsonintoArray(NewsletterDate);

            }
        }
        catch (ex) {
            console.log("setjsondata => ", ex)
        }
    };


    LoadTransactionData = () => {
        if (this.state.PublishNews && this.state.PublishNews.length > 0) {
            this.setState({ lfilterdt: this.state.PublishNews });
            let ltotpage = Math.trunc(this.state.PublishNews.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(this.state.PublishNews.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }



    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <h2 className="headding3">{t('lblournews')}</h2>
                <section className="news">
                    <div className="wrap1 newsSec">
                        <ul>
                            {this.state.PublishNews && this.state.PublishNews.length > 0 && this.state.PublishNews.slice(this.state.startinx, this.state.endinx).map((x, inx) =>
                                <>
                                    <li key={`be_${inx}`}>
                                        {x.map((y, i) =>
                                            <div className="newsCont" key={`bf_${i}`}>
                                                <h2>{y.pressTitle}</h2>
                                                <img src={y.imagPath} alt="News Image" />
                                                <a href={y.pressUrl} target="_blank" rel="noopener">{y.pressUrl}</a>
                                            </div>
                                        )}
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className="pagination paginationWidth mt-16">
                            <div className="paginationBtn">
                                <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                                <div>
                                    <span>{this.state.PublishNews && this.state.PublishNews.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                    <span>/</span>
                                    <span>{this.state.PublishNews && this.state.PublishNews.length > 0 ? this.state.totalPage + 1 : 0}</span>
                                </div>
                                <button><img src="./assets/img/icons/right-arrow.png" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} alt="right arrow" onClick={() => { this.loadnxtdata("+") }} /></button>
                            </div>
                        </div>
                    </div> 
            </section >


                 
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user, appvariables } = state.loginReducer;
    return { user, appvariables };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(NewsLetter))
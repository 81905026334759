import React, { Component } from 'react';
import { gameActions } from '../action/GameAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from '../helpers/history';
import Header from '../Header/Header';
import { alertActions } from '../action/AlertAction';


class PaymentVerify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: ['', '', '', '', '', ''],
        }
    }
    handleChange = (value, e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState(obj => ({ list: obj.list.map((obj, inx) => (inx === value ? e.target.value : obj)) }));
            if (((this.state.list.length - 1) > value) || e.target.value == "")
                document.querySelector(`input[name='${e.target.value !== "" ? (parseInt(value) + 1) : ((value !== 0) ? parseInt(value) - 1 : parseInt(value))}']`).focus();
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            cartItems: props.cartItems
        }
    }
    gotoPaymentStatus = () => {
        let _otp = this.state.list.join('');
        if (_otp.length === 6) { this.BuyLotteryBet(); }
        else { this.showMsg('lblEnterValidOtp'); return; }

    }
    BuyLotteryBet = () => {
        if (this.props.user == undefined || this.props.user.mobileNo == "0") {
            this.showMsg('lblPlsLoginOrSignUp');
            return;
        }
        if (this.state.cartItems.length == 0) {
            this.showMsg('lblplcurbet');
            return;
        }
        let gamestring = "";
        let totStake = 0;
        this.state.cartItems.forEach(x => {
            if (x.BetSelection.filter(a => a != '?').length != x.no_of_balls.split('~')[2]) {
                this.showMsg('lblplcurbet');
                return;
            }
            let cartList = [(x.BetSelection.map(i => ("0" + i).slice(-2))).join(';')];
            let inx = 1;
            cartList.forEach(item => {
                totStake = +totStake + x.stakeValue;
                gamestring += inx;
                gamestring += "~" + item;
                gamestring += "~" + 0;//oddsValue;
                gamestring += "~" + x.minStake;//stakeValue;
                gamestring += "~" + x.minStake + "|";//total
            })
        });
        let x = this.state.cartItems[0];
        var sTime = new Date(this.props.serverTime);
        let TransID = ("0" + sTime.getDate()).slice(-2) + ("0" + (sTime.getMonth() + 1)).slice(-2) + sTime.getFullYear() + ("0" + sTime.getHours()).slice(-2) + ("0" + sTime.getMinutes()).slice(-2) + ("0" + sTime.getSeconds()).slice(-2) + this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 4);
        let ObjBuy = {
            "reflot": x.GameId,
            "group_id": parseInt(x.gamegroupID),
            "draw_time": x.DrawTime,
            "bet_info": gamestring,
            "client_transid": TransID,
            "amount": totStake,
            "type_id": this.props.payTypeID //1 Payment  2 Wallet
        }
        this.props.Buybet(ObjBuy, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        this.props.ClearCart();
    };
    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }

    render() {
        const { t } = this.props
        return (
            <>
                <Header />
                <div className="wrap2">
                    <div className="otp">
                        <h2 className="headding3 cartHead">{t('lblOrderVerify')}</h2>
                        <div className="otpCont">
                            <h3>{t('lblOTP')}</h3>
                            <p>{t('lblSendSixDigitCode')} <span>{this.props.user.Country_code} {this.props.user.mobileNo.substring(0, 3)}****{this.props.user.mobileNo.substring(this.props.user.mobileNo.length - 2, this.props.user.mobileNo.length)}</span> {t('lblVlaidateNo')}.</p>
                            <div className="otpSec flex gap-4">
                                {this.state.list.map((digit, idx) => (
                                    <input autoFocus={idx === 0} key={idx} name={idx} type="text" inputMode="numeric" autoComplete="one-time-code" pattern="\d{1}" maxLength={1} value={digit.value} onChange={this.handleChange.bind(this, idx)} />
                                ))}
                            </div>
                            <div className="reSendBtn rtl:flex"><p>{t('lblDidnotReceiveSMS')}</p> <a href="#">{t('lblResend')}</a></div>
                            <button onClick={() => { this.gotoPaymentStatus() }} className="verifyBtn bg3" id="verifyBtn">{t('lblVerify')}</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { cartItems, payTypeID } = state.gameReducer;
    const { serverTime } = state.commonReducer;
    return { user, cartItems, serverTime, payTypeID };
}
const mapActionToProps = {
    ClearCart: gameActions.ClearCart,
    Buybet: gameActions.Buybet,
    alertError: alertActions.error
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PaymentVerify))
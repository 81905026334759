import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


class PreviousDrawShows extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawtype: props.drawtype,
            pastDrawShow:
                [
                    // { VideoUrl: "https://www.youtube.com/embed/thZYo2WV_b8?si=GExvbXAg5wdat6fR", DrawDate: "15 Mar 2024", TotalWinners: "779", TotalPrizes: "124,380", GameTypeId: "9" },
                    // { VideoUrl: "https://www.youtube.com/embed/3Z7jIgNeVLo?si=Yz0V5RsLnqnbPDrm", DrawDate: "16 Mar 2024", TotalWinners: "472", TotalPrizes: "80,450", GameTypeId: "10" },
                    // { VideoUrl: "https://www.youtube.com/embed/retd7nAn_Sw?si=kdb-O4Pcez7fp-YV", DrawDate: "8 Mar 2024", TotalWinners: "579", TotalPrizes: "156,870", GameTypeId: "9" },
                    // { VideoUrl: "https://www.youtube.com/embed/zRZUyW2B3WU?si=CiYAVIYG0kdBSxPL", DrawDate: "9 Mar 2024", TotalWinners: "329", TotalPrizes: "104,870", GameTypeId: "10" },
                    // { VideoUrl: "https://www.youtube.com/embed/6jyRuZRiPq4?si=VSyJR9RYQHfHmXC4", DrawDate: "1 Mar 2024", TotalWinners: "433", TotalPrizes: "154,930", GameTypeId: "9" },
                    // { VideoUrl: "https://www.youtube.com/embed/0uQJAS247mc?si=irp8EAMiwps8KoFK", DrawDate: "2 Mar 2024", TotalWinners: "279", TotalPrizes: "103,820", GameTypeId: "10" },
                    // { VideoUrl: "https://www.youtube.com/embed/Rzj_vbur3rA?si=ODV99mYnMrz5tg66", DrawDate: "23 Feb 2024", TotalWinners: "370", TotalPrizes: "154,360", GameTypeId: "9" },
                    // { VideoUrl: "https://www.youtube.com/embed/mHm-t2DWLrM?si=3T7TGGsFidG3PCjj", DrawDate: "24 Feb 2024", TotalWinners: "297", TotalPrizes: "104,080", GameTypeId: "10" }
                ],
            iframedrawurl: "",
            // PastDrawShowFlag: false,
            currentPageNo: 0,
            NumberofRecord: 4,
            totalPage: 0,
            startinx: 0,
            endinx: 6,
            lfilterdt: [],
            IsValidData: false,
            _dbdrawType: props.send_db_id
        }
    }


    componentDidMount() {
        // window.allDrawResultPopUp();
        localStorage.setItem('drawtabId', 2);
        this.setjsondata()
    }

    componentDidUpdate(prevState, prevProps) {
        // if (this.state.pastDrawShow.length > 0 && this.state.PastDrawShowFlag == false) {
        //     // window.allDrawResultPopUp();
        //     this.state.PastDrawShowFlag = true;
        // }
        if (this.state.pastDrawShow && this.state.pastDrawShow !== prevProps.pastDrawShow && this.state.pastDrawShow.length != 0 && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            this.setState({ IsValidData: true })
            this.LoadPastDrawShowData()
        }
    }

    SetiframeUrl = (url) => {
        this.setState({ iframedrawurl: url })
    }
    setjsondata = () => {
        try {
            let hbanner = 0
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 2) {
                    hbanner = hbannerVer[3]
                }
            }
            fetch(process.env.REACT_APP_CDN_URL + 'Web/ResultUrlUpload.json' + "?V" + hbanner)
                .then(response => response.json())
                .then(data =>
                    this.setState({ pastDrawShow: data.ResultUrlList.filter(a => a.GameTypeId == this.state._dbdrawType) })
                );
        }
        catch (ex) {
            console.log("setjsondata => " + ex)
        }
    };

    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }


    LoadPastDrawShowData = () => {
        if (this.state.pastDrawShow && this.state.pastDrawShow.length > 0) {
            this.setState({ lfilterdt: this.state.pastDrawShow });
            let ltotpage = Math.trunc(this.state.pastDrawShow.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(this.state.pastDrawShow.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
            setTimeout(() => {
                window.allDrawResultPopUp();
            }, 1000);
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }

    render() {
        // let iframeUrl = this.props.drawtype == 9 ? "https://www.youtube.com/embed/Rzj_vbur3rA?si=ODV99mYnMrz5tg66" : "https://www.youtube.com/embed/mHm-t2DWLrM?si=3T7TGGsFidG3PCjj"
        const { t } = this.props
        return (
            <>
                {/* <div className={this.props.drawType == 9 ? "profileCont2 drawResults2" : "profileCont topAlign"}>
                    <div className="row">
                        <h3 style={{ textAlign: "center", fontSize: "30px", padding: "20px", marginTop: "20px" }}>{t('lblUnderConst')}</h3>
                    </div>
                </div> */}
                {/* margin-left-7 else Part */}

                {<div className={`profileCont ${this.props.drawtype == 10 ? "topAlign" : ""} pb-14`}>
                    <div className="popup-container bigpopup" id="popup-container1" >
                        <div className="popup-content !w-fit !min-w-min px-10 no-scrollbar">
                            <div className="rightAlign">
                                <button className="close-popup crossBtn borderFade bigpopupclose" id="close-popup1" >
                                    <img src="./assets/img/icons/Close-Btn.svg" alt="Close Icon"/>
                                </button>
                            </div>
                            <div className="popup-main !px-8 flex justify-center items-center">
                                <iframe
                                    width="700"
                                    height="455"
                                    src={this.state.iframedrawurl+'&rel=0'}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    className="rounded-md"
                                    style={{border:"0px"}}
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-md my-12 mx-auto flex justify-center w-4/5">

                        {this.state._dbdrawType == 9 ? <img src="./assets/img/past-draws/fortune-4-extended.png?v0.2" alt="draw date" className="rounded-xl" />
                            : this.state._dbdrawType == 10 ? <img src="./assets/img/past-draws/super-6-extended.jpg?v0.1" alt="draw date" className="rounded-xl" />
                                : this.state._dbdrawType == 11 ? <img src="./assets/img/past-draws/magic-5-extended.jpg?v0.1" alt="draw date" className="rounded-xl" />
                                    : this.state._dbdrawType == 13 ? <img src="./assets/img/past-draws/lucky-1-extended.jpg" alt="draw date" className="rounded-xl" />
                                        : <img src="./assets/img/past-draws/fortune-5-extended.jpg?v0.1" alt="draw date" className="rounded-xl" />}
                    </div>

                    <div className="flex flex-wrap justify-center gap-10 items-start w-full">
                        {this.state.IsValidData && this.state.pastDrawShow && this.state.pastDrawShow.length > 0 && this.state.pastDrawShow.slice(this.state.startinx, this.state.endinx).map((x, inx) =>
                            <div className="flex gap-8 items-center border-slate-500 border-[1.5px] p-10 rounded-xl shadow-lg bg-neutral-50" key={`ah_${inx}`}>
                                <div className="flex flex-col gap-2 items-start">
                                    <h2 className="text-lg md:text-xl lg:text-2xl font-bold uppercase mx-auto">
                                        {x.DrawDate}
                                    </h2>
                                    <div>
                                        <div className="flex gap-6">
                                            <h4 className="text-base font-semibold md:text-lg uppercase min-w-36">
                                                {t('lblTotwinner')} :
                                            </h4>
                                            <p className="text-base md:text-lg">{x.TotalWinners}</p>
                                        </div>
                                        <div className="flex gap-6">
                                            <h4 className="text-base font-semibold md:text-lg uppercase min-w-36">
                                                {t('lblTotalPrizes')} :
                                            </h4>
                                            <p className="text-base md:text-lg">{process.env.REACT_APP_CURRENCY} {x.TotalPrizes}</p>
                                        </div>
                                    </div>
                                    <button onClick={() => { this.SetiframeUrl(x.VideoUrl) }} className={`rounded-3xl bg-game${this.state.drawtype} text-white font-bold text-base md:text-lg px-7 show-popup mx-auto bigpopupopen`} >
                                        {t('lblwatchnow')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="pagination mt-16">
                        <div className="paginationBtn">
                            <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                            <div>
                                <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                <span>/</span>
                                <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.totalPage + 1 : 0}</span>
                            </div>
                            <button><img src="./assets/img/icons/right-arrow.png" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} alt="right arrow" onClick={() => { this.loadnxtdata("+") }} /></button>
                        </div>
                    </div>
                </div>
                }
            </>

        );
    }
}


function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    return { appvariables };

}
export default connect(mapStateToProps)(withTranslation()(PreviousDrawShows))
import React, { Component } from 'react';
import Header from '../../Header/Header';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import TermsCondition from './TermsCondition';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Legal from './Legal';
import GivingBack from './GivingBack';
import CSR from './CSR';
import ParticipateResponsible from './ParticipateResponsible';
import LaunchOffer from './LaunchOffer';
import GameRules from './GameRules';
import ContactUs from './ContactUs';
import Feedback from './Feedback';
import Faqs from './Faqs';
import NewsLetter from './NewsLetter';
class GeneralInfo extends Component {
    constructor(props) {
        super(props);
        //const gtid = this.props.history.location.state ? this.props.history.location.state.gtid : 1
        const tabid = localStorage.getItem('tabId') ? localStorage.getItem('tabId') : 1;
        if (tabid == null || tabid == "")
            this.state = { sectionID: 0 }
        else
            this.state = { sectionID: tabid }
    }
    setSectionID = (id) => {
        this.setState({ sectionID: id });
    }

    render() {
        const { t } = this.props;
        return (<>
            <Header />
            <div className={`${this.state.sectionID == 8 || this.state.sectionID == 7 || this.state.sectionID == 11 || this.state.sectionID == 1 || this.state.sectionID == 12 || this.state.sectionID == 13
                || this.state.sectionID == 14 || this.state.sectionID == 15 ? "wrap1" : "wrap2"} ${this.state.sectionID == 11 || this.state.sectionID == 6 || this.state.sectionID == 9 || this.state.sectionID == 5 || this.state.sectionID == 4 ? 'contentalign' : ''}`}   >
                {this.state.sectionID == 0 && <div>
                    <span></span>
                    <h3 style={{ textAlign: "center", fontSize: "30px", padding: "20px", marginTop: "20px" }}>{t('lblUnderConst')}</h3>
                </div>}
                {this.state.sectionID == 1 && <AboutUs />}
                {this.state.sectionID == 4 && <TermsCondition />}
                {this.state.sectionID == 5 && <PrivacyPolicy />}
                {this.state.sectionID == 6 && <Legal />}
                {this.state.sectionID == 7 && <GivingBack />}
                {this.state.sectionID == 8 && <CSR />}
                {this.state.sectionID == 9 && <ParticipateResponsible />}
                {this.state.sectionID == 10 && <LaunchOffer />}
                {this.state.sectionID == 11 && <GameRules />}
                {this.state.sectionID == 12 && < ContactUs />}
                {this.state.sectionID == 13 && < Feedback />}
                {this.state.sectionID == 14 && < Faqs />}
                {this.state.sectionID == 15 && < NewsLetter />}
            </div>
        </>);
    }
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(GeneralInfo))
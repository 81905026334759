import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { history } from '../../helpers/history';

class PromotionRules extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <div className="mainContainer">
                <Header />
                <div className="wrap2 contentalign">
                    <button type="button" className="backBtn" onClick={() => { history.goBack() }}>
                        <img src="./assets/img/icons/Back-Icon.svg" alt='Back-Icon'/>
                        <span>{t('lblBack')}</span>
                    </button>
                    {/* {i18n.language === 'en' ? */}
                    <div className="addlPage">
                        <div className="text-center text-brand text-2xl md:text-3xl font-bold tracking-wide">LAUNCH EXTRAVAGANZA</div>
                        <div className="text-xl text-center text-smallCaps font-bold">Offer lasts only 3 Weeks after launch.</div>
                        <div className="text-center font-semibold">Terms & Conditions</div>
                        <div className="flex gap-5 flex-col items-start mt-4">
                            {/* <div>
                                <h4 className="text-lg font-bold">Introduction</h4>
                                <p>
                                    Get ready to strike gold! The Gold Rush Launch Extravaganza is here, bringing you a
                                    thrilling way to celebrate the launch of Gulf Ticket's online draws. From February
                                    19th to every day is a chance to win a dazzling a 5gms Gold Coin! Whether
                                    you're a seasoned online draw player or just looking for a shot at instant fortune,
                                    this promotion offers excitement for everyone.
                                </p>
                            </div> */}
                            <div>
                                <h4 className="text-lg font-bold">How to Participate:</h4>
                                <p>It's simple! Just follow these steps:</p>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>Register on the Gulf Ticket website. It's free and quick!</li>
                                    <li>All registered users are automatically entered into the launch offer draw.</li>
                                    <li>
                                        Sit back, relax, and dream big! Winners will be announced during Live Show at 8:00 PM UAE
                                        time.
                                    </li>
                                </ul>
                            </div>

                            {/* <div>
                                <h4 className="text-lg font-bold">More Ways to Win:</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        Registered before February 16th? You're automatically entered into the daily
                                        draws!
                                    </li>
                                </ul>
                            </div> */}

                            <div>
                                <h4 className="text-lg font-bold">Claiming Your Prize:</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>
                                        Winners will be notified via email and phone number provided during registration.
                                    </li>

                                    <li>
                                        Winning amount will be credited to your Gulf Ticket wallet. This can be withdrawn
                                        at any time.
                                    </li>
                                    <li>Claim your prize within 10 days from the notification date.</li>
                                </ul>
                            </div>

                            <div>
                                <h4 className="text-lg font-bold">Additional Information:</h4>
                                <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                    <li>For full terms and conditions, please visit the dedicated page.</li>

                                    <li>Play responsibly and within financial limits.</li>
                                    <li>Visit our website for more information on responsible gaming practices.</li>
                                    <li>For any questions, contact our friendly customer support team.</li>
                                </ul>
                            </div>

                            <button className="bg-brand text-white rounded-3xl font-bold px-4 py-1 mx-auto">
                                <a onClick={() => { history.push('/register') }}>Register Now</a>
                            </button>
                            <p className="text-center w-full -mt-3">
                                For offer terms and conditions, click &nbsp;
                                <a onClick={() => { history.push('/promotionterms') }} className="underline text-blue-700">here</a>.
                            </p>
                        </div>
                    </div>
                    {/* :
                        i18n.language === 'ml' ?
                            <div className="addlPage">
                                <div className="page-title text-center">ഗൾഫ് ടിക്കറ്റ് യൊഞ്ച് സ്്ടപഷയൽ </div>
                                <div className="text-center page-subHead1">ഉറപ്പുള്ള റാഫിൾ മിെയണയർ </div>
                                <div className="text-center page-subHead2">നിബന്ധനകളും വയവസ്ഥകളും</div>

                                <div className="rules">
                                    <div>
                                        <h4 className="section-title">എങ്ങടന പടങ്കടുക്കാം </h4>
                                        <p>
                                            ഗൾഫ് ടിക്കറ്റിനപ്പ്റ ആനന്ദകരമാ  ട്ലാഞ്ച് സ്പ്പപഷ്യലിൽ ട്െരുന്നത് ലളിതമാണ്! ഗയാരണ്ടീഡ് റാഫിൾ മിലയണ റിൽ പപ്പങ്കടുക്കാൻ, ആട്െശകരമാ  ട്ഫാർെയൂൺ 5 പ്പഗ ിമിനാ ി ഒരു ടിക്കറ്റ് ൊങ്ങുക. ആദയപ്പത്ത നാലാഴ്ചട്ത്തക്ക്, ഓട്രാ ടിക്കറ്റുിം 1 ദശലക്ഷിം ദിർഹത്തിനപ്പ്റ രഗാൻഡ് സരപസ് ട്നടാനുള്ള അെസരിം തുറക്കുന്നു, ഇത് എൊ ആഴ്ച ുിം ഉറെുനൽകുന്നു!
                                        </p>
                                    </div>
                                    <div>
                                        <h4 className="section-title">ഉറപ്പുള്ള റാഫിളിന്ടറ ര്ീതി </h4>
                                        <p>
                                            നിങ്ങളുപ്പട ടിക്കറ്റ് ലഭിച്ചുക ിൊൽ, ഗയാരണ്ടീഡ് റാഫിൾ മിലയണ ർ ഓഫറിനപ്പ്റ ഭാഗമാകാൻ നിങ്ങൾക്ക് സവ ട്മെ ട് ാഗയത ുണ്ട്. ആഴ്ച പുട്രാഗമിക്കുട്പാൾ, രപതീക്ഷ െർദ്ധിക്കുന്നു, ആട്െശിം അതിനപ്പ്റ ഉച്ചസ്ഥാ ി ിപ്പലത്തുന്നു. നിങ്ങളുപ്പട ജീെിതപ്പത്ത എപ്പന്നട്ന്നക്കുമാ ി മാറ്റിമറിട്ച്ചക്കാെുന്ന തത്സമ  നറുപ്പക്കടുെിന് സാക്ഷയിം െഹിക്കാൻ എൊ ആഴ്ച ുിം ടയൂൺ പ്പെയ്യുന്നത് ഉറൊക്കുക!
                                        </p>
                                    </div>

                                    <div>
                                        <h4 className="section-title">വിജയികളും സ്മ്മാനങ്ങളും </h4>
                                        <p>
                                            ഓട്രാ ആഴ്ച ുപ്പട ുിം അെസാനിം, തത്സമ  നറുപ്പക്കടുെിൽ രകമരഹിതമാ ി ഒരു ഭാഗയ െിജ ിപ്പ  തിരപ്പെടുക്കുിം. സവപ്നങ്ങൾ  ാഥാർത്ഥയമാക്കുന്നതിനുള്ള ജീെിതിം മാറ്റിമറിക്കുന്ന അെസരമാ  അെിശവസനീ മാ  1 ദശലക്ഷിം ദിർഹിം സമ്മാനെുമാ ി ഭാഗയൊനാ  െിജ ി ട്പാകുിം!
                                        </p>
                                    </div>

                                    <div>
                                        <h4 className="section-title">യയാഗയതാ മാനദണ്ഡം </h4>
                                        <p>
                                            ഗൾഫ് ടിക്കറ്റ് ട്ലാഞ്ച് സ്പ്പപഷ്യൽ  ുഎഇ ിൽ താമസിക്കുന്ന 18 െ സുിം അതിൽ കൂടുതലുിം രപാ മുള്ള എൊ പങ്കാളികൾക്കുിം ലഭയമാണ്. ഗയാരണ്ടീഡ് റാഫിൾ മിലയണ ർ ട് ാഗയത ട്നടുന്നതിന്, രപതിൊര നറുപ്പക്കടുെ് നടക്കുന്നതിന് മുപ് ട്ഫാർെയൂൺ 5 പ്പഗ ിമിനാ ി നിങ്ങൾ സാധുൊ  ടിക്കറ്റ് ൊങ്ങി ിേുപ്പണ്ടന്ന് ഉറൊക്കുക.
                                        </p>
                                    </div>

                                    <div>
                                        <h4 className="section-title">നിര്ാകര്ണം </h4>
                                        <p>
                                            ഞങ്ങളുപ്പട എൊ രപട്മാഷ്നുകളിലുിം നറുപ്പക്കടുെുകളിലുിം നയാ െുിം സുതാരയത ുിം ഉറൊക്കാൻ ഗൾഫ് ടിക്കറ്റ് രപതിജ്ഞാബദ്ധമാണ്. ഞങ്ങളുപ്പട മൂലയെത്താ  പങ്കാളികൾക്ക് അസാധാരണമാ  പ്പഗ ിമിിംഗ് അനുഭെിം നൽകുന്നതിന് സമരഗത ുപ്പട ഏറ്റെുിം ഉ ർന്ന നിലൊരിം ഞങ്ങൾ നിലനിർത്തുന്നു. ഗൾഫ് ടിക്കറ്റ് ട്ലാഞ്ച് സ്പ്പപഷ്യൽ ഗയാരണ്ടീഡ് റാഫിൾ മിലയണ ർ നന്നാ ി അറി ുന്നതിനുിം ആട്െശകരമാ , ഒരു പ്പഗ ിമിിംഗ് സാഹസികത ആസവദിക്കുന്നതിനുിം പൂർണ്ണമാ  നിബന്ധനകളുിം െയെസ്ഥകളുിം അെട്ലാകനിം പ്പെയ്യുക. അസാധാരണമാ  ട്ലാഞ്ച് സപ്പ്പഷ്യൽ ഉപട് ാഗിച്ച് ഗൾഫ് ടിക്കറ്റ്  ാരത ആരിംഭിക്കുട്പാൾ ഈ സുരപധാന ആട്ഘാഷ്ത്തിൽ ഞങ്ങട്ളാപ്പടാെിം ട്െരുക - എൊ ആഴ്ച ുിം ഒരു ട്കാടീശവരനാകാനുള്ള നിങ്ങളുപ്പട അെസരിം! നിങ്ങളുപ്പട ടിക്കറ്റുകൾ ഇട്ൊൾ ട്നടുക, ആട്െശത്തിനപ്പ്റ ഭാഗമാകുക!
                                        </p>
                                    </div>
                                </div>
                            </div> :
                            i18n.language === 'ar' ?
                                <div className="addlPage">
                                    <div className="page-title text-center">إطلاق تذكرة الخليج الخاص</div>
                                    <div className="text-center page-subHead1">السحب المليونيري المضمون</div>
                                    <div className="text-center page-subHead2">الشروط والأحكام</div>

                                    <div className="rules">
                                        <div>
                                            <h4 className="section-title">كيفية المشاركة</h4>
                                            <p>
                                                الانضمام إلى العرض الخاص المبهج لـتذكرة الخليج أمر بسيط! للمشاركة في جائزة المليونير المضمونة، قم بشراء تذكرة للعبة الحظ المثيرة. خلال الأسابيع الأربعة الأولى، ستفتح كل تذكرة فرصة للفوز بالجائزة الكبرى بقيمة مليون درهم إماراتي، مضمونة كل أسبوع!
                                            </p>
                                        </div>
                                        <div>
                                            <h4 className="section-title">آليات السحب المضمون</h4>
                                            <p>
                                                بمجرد حصولك على تذكرتك، تصبح مؤهلاً تلقائيًا لتكون جزءًا من عرض مليونير السحب المضمون. مع تقدم الأسبوع، يتزايد الترقب، وتصل الإثارة إلى ذروتها. تأكد من متابعتك كل أسبوع لمشاهدة السحب المباشر الذي قد يغير حياتك إلى الأبد!
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">الفائزون والجوائز</h4>
                                            <p>
                                                في نهاية كل أسبوع، سيتم اختيار فائز محظوظ واحد بشكل عشوائي خلال السحب المباشر. سيحصل الفائز المحظوظ على الجائزة المذهلة التي تبلغ قيمتها مليون درهم إماراتي، وهي فرصة ستغير حياته لتحويل أحلامه إلى حقيقة!
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">معايير الأهلية</h4>
                                            <p>
                                                إن العرض الخاص لإطلاق تذكرة الخليج مفتوح لجميع المشاركين الذين تبلغ أعمارهم 18 عامًا فما فوق والمقيمين في دولة الإمارات العربية المتحدة. لكي تكون مؤهلاً للحصول على مليونير السحب المضمون، تأكد من شراء تذكرة صالحة للعبة الحظ قبل إجراء السحب الأسبوعي.
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">تحذير</h4>
                                            <p>
                                                تلتزم شركة تذكرة الخليج بضمان العدالة والشفافية في جميع العروض الترويجية والسحوبات التي نقدمها. نحن نلتزم بأعلى معايير النزاهة لتقديم تجربة ألعاب استثنائية للمشاركين الكرام. يرجى مراجعة الشروط والأحكام الكاملة للسحب الخاص المضمون لإطلاق تذكرة الخليج لتكون على اطلاع جيد وتستمتع بمغامرة ألعاب مثيرة وفريدة من نوعها. انضم إلينا في هذا الاحتفال المهم حيث نبدأ رحلة تذكرة الخليج مع إطلاق خاص استثنائي - فرصتك لتصبح مليونيرًا كل أسبوع! احصل على التذاكر الخاصة بك الآن وكن جزءا من الإثارة!
                                            </p>
                                        </div>
                                    </div>
                                </div> :
                                <div className="addlPage">
                                    <div className="page-title text-center">گلف ٹکٹ لانچ اسپیشل</div>
                                    <div className="text-center page-subHead1">گارنٹی شدہ ریفل ملینیئر</div>
                                    <div className="text-center page-subHead2">شرائط و ضوابط</div>

                                    <div className="rules">
                                        <div>
                                            <h4 className="section-title">حصہ لینے کا طریقہ</h4>
                                            <p>
                                                گلف ٹکٹ کے پرجوش لانچ اسپیشل میں شامل ہونا آسان ہے! گارنٹی شدہ ریفل ملینیئر میں حصہ لینے کے لیے، دلچسپ فورچیون  5 گیم کا ٹکٹ خریدیں۔ پہلے چار ہفتوں کے لیے، ہر ٹکٹ 1 ملین اماراتی درہم کا عظیم الشان انعام جیتنے کا موقع کھولتا ہے جس کی ہر ہفتے ضمانت دی جاتی ہے!
                                            </p>
                                        </div>
                                        <div>
                                            <h4 className="section-title">گارنٹی شدہ ریفل کے میکینکس</h4>
                                            <p>
                                                ایک بار جب آپ اپنا ٹکٹ حاصل کر لیتے ہیں، تو آپ خود بخود گارنٹی شدہ ریفل ملینیئر پیشکش کا حصہ بننے کے اہل ہو جاتے ہیں۔ جیسے جیسے ہفتہ آگے بڑھتا ہے، امید پیدا ہوتی ہے اور جوش و خروش عروج پر پہنچ جاتا ہے۔ لائیو ڈرا کا مشاہدہ کرنے کے لیے ہر ہفتے ٹیون ان کرنا یقینی بنائیں جو آپ کی زندگی کو ہمیشہ کے لیے بدل سکتا ہے!
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">فاتحین اور انعامات</h4>
                                            <p>
                                                ہر ہفتے کے اختتام پر، لائیو ڈرا کے دوران بے ترتیب طور پر ایک خوش قسمت فاتح کا انتخاب کیا جائے گا۔ خوش قسمت فاتح ناقابل یقین 1 ملین اماراتی درہم کا انعام جیتے گا جو خوابوں کو حقیقت بنانے کے لیے زندگی بدل دینے والا موقع ہے!
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">اہلیت کا معیار</h4>
                                            <p>
                                                گلف ٹکٹ لانچ اسپیشل متحدہ عرب امارات میں مقیم 18 سال اور اس سے زیادہ عمر کے تمام شرکاء کے لیے کھلا ہے۔ گارنٹی شدہ ریفل ملینیئر کے اہل ہونے کے لیے، یقینی بنائیں کہ آپ نے ہفتہ وار ڈرا منعقد ہونے سے پہلے فورچیون  5 گیم کا درست ٹکٹ خرید لیا ہے۔
                                            </p>
                                        </div>

                                        <div>
                                            <h4 className="section-title">ڈس کلیمر</h4>
                                            <p>
                                                گلف ٹکٹ ہماری تمام پروموشن اور ریفل ڈرا میں غیر جانبداری اور شفافیت کو یقینی بنانے کے لیے پرعزم ہے۔ ہم اپنے قابل قدر شرکاء کو گیمنگ کا غیر معمولی تجربہ فراہم کرنے کے لیے خود کو دیانتداری کے اعلیٰ ترین معیار پر فائز کرتے ہیں۔ براہ کرم اچھی طرح سے آگاہ ہونے اور سنسنی خیز، اپنی نوعیت کے اکلوتے گیمنگ ایڈونچر سے لطف اندوز ہونے کے لیے گلف ٹکٹ لانچ اسپیشل گارنٹی شدہ ریفل ملینیئر کے مکمل شرائط و ضوابط کا جائزہ لیں۔ اس نہایت اہم جشن میں ہمارے ساتھ شامل ہوں کیوں کہ ہم غیر معمولی لانچ اسپیشل کے ساتھ گلف ٹکٹ کا سفر شروع کر رہے ہیں – جہاں آپ کو ہر ہفتے کروڑ پتی بننے کا موقع ملتا ہے! ابھی اپنے ٹکٹ حاصل کریں اور جوش و خروش کا حصہ بنیں!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                    } */}
                </div>
            </div>
        )
    }

}

export default withTranslation()(PromotionRules)
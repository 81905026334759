import React, { Component } from 'react';
import { loginActions } from '../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { accountActions } from '../action/AccountAction';
import Header from '../Header/Header';
import { appVariables } from '../helpers/appVariables';
class Transaction extends Component {
	constructor(props) {
		super(props);
		this.state = {
			serverDate: new Date(),
			startDate: "",
			rdoStatus: appVariables.GAMETYPE.VirtualQuickWin
		}
	}

	ondateChange = () => {
		this.state.startDate = document.getElementById("datepicker1").value;
		this.props.GetAllResult(this.state.rdoStatus, this.props.user.mobileNo, this.state.startDate, this.props.i18n.language, this.props.user.loginKey);
	}
	rdoChange = (id) => {
		this.setState({ rdoStatus: id });
		this.state.rdoStatus = id;
		this.ondateChange();
	}
	static getDerivedStateFromProps(props, state) {
		return {
			ResultReport: props.ResultReport
		};
	};

	componentDidMount() {
		window.Loaddate(this.state.serverDate);
		this.state.startDate = document.getElementById("datepicker1").value;
		this.props.GetAllResult(this.state.rdoStatus, this.props.user.mobileNo, this.state.startDate, this.props.i18n.language, this.props.user.loginKey);
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<Header />
				<div className="col-md-12">
					<div className="MyaccountRht">
						<div className="tabSec">
							<ul>
								<li><a className="tabActive" >{t('lblResult')}</a></li>
							</ul>
						</div>
						<div className="accHeadSecRht">
							<div className='accHeadCont'>
								<input type='radio' className='radio1' id='r1' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.VirtualQuickWin)} checked={this.state.rdoStatus == appVariables.GAMETYPE.VirtualQuickWin ? 'checked' : ""} /><label for='r1'>Virtual Quick Win</label>
								<input type='radio' className='radio1' id='r2' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Quick9)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Quick9 ? 'checked' : ""} /><label for='r2'>Quick 9</label>
								<input type='radio' className='radio1' id='r3' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Roulette)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Roulette ? 'checked' : ""} /><label for='r3'>Roulette</label>
								<input type='radio' className='radio1' id='r4' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Sicbo)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Sicbo ? 'checked' : ""} /><label for='r4'>Sic Bo</label>
								<input type='radio' className='radio1' id='r5' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Panta)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Panta ? 'checked' : ""} /><label for='r5'>Panta</label>
								<input type='radio' className='radio1' id='r6' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Target100)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Target100 ? 'checked' : ""} /><label for='r6'>Target 100</label>
								<input type='radio' className='radio1' id='r7' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Target10)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Target10 ? 'checked' : ""} /><label for='r7'>Target 10</label>
								<input type='radio' className='radio1' id='r8' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.Keno)} checked={this.state.rdoStatus == appVariables.GAMETYPE.Keno ? 'checked' : ""} /><label for='r8'>Keno</label>
								<input type='radio' className='radio1' id='r9' name='rdoResult' onClick={() => this.rdoChange(appVariables.GAMETYPE.SpinWin)} checked={this.state.rdoStatus == appVariables.GAMETYPE.SpinWin ? 'checked' : ""} /><label for='r9'>Spin Win</label>
							</div>
						</div>
						<div className="tabDateSel" style={{ marginLeft: '36%', marginBottom: '5%', float: 'none' }}>
							<span><input className="custDate" autoComplete='off' id="datepicker1" type="text" style={{ padding: '5px 11px 8px 30px', border: '.5px solid #005907', backgroundPosition: '10% 6px' }} /></span>
							<span><button onClick={this.ondateChange} style={{ "width": "100px", "background": "#005907", "color": "white", "height": "28px" }}>{t('lblSubmit')}</button></span>
						</div>
						<div className="forScroll">
							<table className="tble">
								<tbody>
									<tr className="brRadius">
										<th>{t('lblGameName')}</th>
										<th>{t('lblDrawDateTime')}</th>
										<th>{t('lblResult')}</th>
										<th></th>
									</tr>
									{this.state.ResultReport && this.state.ResultReport.map((transdata,idx2) =>
										<tr key={`af_${idx2}`}>
											<td>{transdata.game_name}</td>
											<td>{transdata.draw_date}</td>
											<td>{transdata.result}</td>
											<td></td>
										</tr>
									)}
								</tbody>

							</table>
						</div>
					</div >
				</div >
			</>
		);
	}
}
function mapStateToProps(state) {
	const { user } = state.loginReducer;
	const { ResultReport } = state.accountReducer;
	return { user, ResultReport };
}
const mapActionToProps = {
	logout: loginActions.logout,
	GetAllResult: accountActions.GetAllResult
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Transaction))
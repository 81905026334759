import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './component/helpers/store';
import './i18n';
// import { commonClass } from './component/helpers/commonClass';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.REACT_APP_MODE != 'development') {
  disableReactDevTools();
}

// const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
// console.log(width);
// const mobileViewWidth = 900;
// let locationurl = "";
// let d=process.env.REACT_APP_DESKTOP_URL;
// let d1=process.env.REACT_APP_MOBILE_URL;
// if (width < mobileViewWidth) {//check mobile
//   //mobile  
//   if (process.env.REACT_APP_MOBILE_URL && window.location.href.toLowerCase().indexOf('mobile') < 0) {
//     locationurl = process.env.REACT_APP_MOBILE_URL;
//   }
// }
// else {
//   //Desktop  
//   if (process.env.REACT_APP_DESKTOP_URL && window.location.href.toLowerCase().indexOf('mobile') >= 0) {
//     locationurl = process.env.REACT_APP_DESKTOP_URL;
//   }
// }
//  if (locationurl != "") 
//  { window.location = locationurl; }

//  if ((window.location.host.indexOf('www') >= 0 || window.location.protocol == 'http:') && process.env.REACT_APP_MODE == "production") {
//   window.location = "https://" + window.location.host.replace("www.", "") + window.location.pathname + window.location.search;
// }

if (process.env.REACT_APP_MODE == "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
}
// const { setItem } = localStorage;
// localStorage.setItem = function (key, value) {
//   console.log('localStorage.setItem', key);
//   let lstrencryptdt = "";
//   if (key == 'user') {
//     try {
//       lstrencryptdt = commonClass.encrypt(value);
//     }
//     catch (ex) {
//       lstrencryptdt = value
//     }
//   }
//   else {
//     lstrencryptdt = value;
//   }
//   setItem.call(this, key, lstrencryptdt);
// };

// if (process.env.REACT_APP_MODE == "production") {
//   function ctrlShiftKey(e, t) {
//     return e.ctrlKey && e.shiftKey && e.keyCode === t.charCodeAt(0)
//   }
//   document.addEventListener("contextmenu", (e => e.preventDefault())),
//     document.onkeydown = e => {
//       if (123 === event.keyCode || ctrlShiftKey(e, "I") || ctrlShiftKey(e, "J") || ctrlShiftKey(e, "C") || e.ctrlKey && e.keyCode === "U".charCodeAt(0))
//         return !1
//     }
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    {/* <Suspense fallback={<span>Loading...</span>}> */}
    <Suspense>
      <App />
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

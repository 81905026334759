import { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Header from '../../Header/Header';
import { Helmet } from "react-helmet";
import HowToPlay from '../Home/HowToPlay';
class HowToPlayPage extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const { t } = this.props;
		return (
			<>
				{process.env.REACT_APP_MODE == "production" && <Helmet>
					<title>Gulf Ticket - Greening the Emirates - Sustainability Endeavor</title>
					<meta name="description" content="Delve into Gulf Ticket's dedication to corporate social responsibility, empowering communities and making a positive impact through its initiatives." />
					<meta name="keywords" content="Gulf Ticket, lottery, UAE, CSR, community support, raffle, lottery, winners, draw, winning numbers, mega prize" />

					
                    <meta property="og:title" content="Gulf Ticket - Greening the Emirates - Sustainability Endeavor" />
                    <meta property="og:site_name" content="Gulf Ticket" />
                    <meta property="og:url" content="https://gulfticket.com" />
                    <meta property="og:description" content="Delve into Gulf Ticket's dedication to corporate social responsibility, empowering communities and making a positive impact through its initiatives." />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content="https://gulfticket.com/assets/img/logo.png" />
				</Helmet>}
				<Header />
				<HowToPlay ispage={true}/>
			</>
		)
	}

}
export default withTranslation()(HowToPlayPage)


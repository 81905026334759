import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const LangSelector = () => {

    const reloadLangCssFile = (event) => {
        if (event == "ar" ||event == "ur") {
            let myid = document.getElementById('ar_css');
            if (myid == null || myid == undefined) {
                let css = document.createElement('link');
                css.id = "ar_css";
                css.href = './assets/css/custom-arabic1.css?v1.0';
                css.rel = 'stylesheet';
                css.type = 'text/css';
                document.getElementById('myscpt').appendChild(css);

                let ml = document.getElementById('ml_css');
                if (ml != null && ml != undefined)
                    document.getElementById('ml_css').remove();
            }
        }
        else if (event == "ml") {
            let myid1 = document.getElementById('ml_css');
            if (myid1 == null || myid1 == undefined) {
                let css = document.createElement('link');
                css.id = "ml_css";
                css.href = './assets/css/custom-malayalam.css?v0.5';
                css.rel = 'stylesheet';
                css.type = 'text/css';
                document.getElementById('myscpt').appendChild(css);

                let ar = document.getElementById('ar_css');
                if (ar != null && ar != undefined)
                    document.getElementById('ar_css').remove();
            }
        }
        else {
            let ml = document.getElementById('ml_css');
            let ar = document.getElementById('ar_css');
            if (ml != null && ml != undefined)
                document.getElementById('ml_css').remove();
            if (ar != null && ar != undefined)
                document.getElementById('ar_css').remove();
        }
    }

    const getCookie = (name) => {
        try {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            let lang_val = process.env.REACT_APP_DEFAULTLANGUAGE;
            //if (parts.length === 2)
            if (parts.length >= 2) {
                //lang_val =parts.pop().split(';').shift();
                let lst = value.split(`;`);
                let fobj = lst.filter(a => a.indexOf('gtlang=') >= 0);
                if (fobj && fobj.length > 0) {
                    let fobj1 = fobj[fobj.length-1].split(`=`);
                    lang_val = fobj1[1];
                }
            }
            console.log("selected lang : " + lang_val);
            reloadLangCssFile(lang_val);
            return lang_val;
        }
        catch (ex) {
            return process.env.REACT_APP_DEFAULT_LANGUAGE;
        }
    };

    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(getCookie("gtlang"));


    const changeLanguage = (event) => {
        reloadLangCssFile(event)
        i18n.changeLanguage(event);
        document.cookie = "gtlang=" + event;
        setSelectedLang(event);
        window.location.reload(); //for get some translation from db
    }
    useEffect(() => {
        window.setLangDoggle();
    }, [])


    return (
        <div className="selLanguage bg4" onChange={changeLanguage} id="lstLang">
            <a className="selLangBtn">
                <img src="./assets/img/icons/language.webp" />
                <span style={{ textTransform: 'uppercase' }} className="selectedLanguageName">{selectedLang}</span>
            </a>
            <ul className="selLangCont">
                <li><a onClick={() => changeLanguage('en')}>English</a></li>
                <li><a onClick={() => changeLanguage('ar')}>عربي</a></li>
                <li><a onClick={() => changeLanguage('ml')}>മലയാളം</a></li>
                <li><a onClick={() => changeLanguage('ur')}>اردو</a></li>
                <li><a onClick={() => changeLanguage('th')}>แบบไทย</a></li>
                <li><a onClick={() => changeLanguage('fili')}>Filipino</a></li>
            </ul>
        </div>
    )
}

export default LangSelector;
import React from "react";
import ReactDOM from "react-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";

function SetCookie({ launguage }) {
    const { t, i18n } = launguage;
    return (
        <>
            <CookieConsent 
                buttonText={`${i18n.language === 'ur'  ?  'قبول کریں۔' : i18n.language === 'ml' ? 'സ്വീകരിക്കുക' : i18n.language === 'ar' ? 'يقبل' :'Accept'}`}
                enableDeclineButton
                declineButtonText="-"
                cookieName="myAppCookieConsent"
                containerClasses="cookie-content"
                buttonClasses="cookieBtn2"
                declineButtonClasses="cookieClose"
                contentClasses="cookies cookieCont"
                expires={150}
                disableStyles={true}
            >
                <a className="cookieClose"><img src="./assets/img/icons/cookieClose.png" alt="Cookie Close"/></a>
                <span className="cookieContentTop">{t('lblAcCookies')}</span>
                <p className="cookieContent"> {t('lblCookieContent')} <a href="#" className="cookieContentatag">{t('lblCookieterms')}</a></p>
            </CookieConsent>
        </>
    );
}

export default SetCookie;
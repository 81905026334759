import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class Legal extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <div>
                {/* className="wrap2" */}
                <div>
                    <h2 className="headding3 float-none">{t('lblLegal')}</h2>
                    {/* {i18n.language === 'en' && <h2 className="headding3 float-none">Legal Disclaimer  </h2>}
                    {i18n.language === 'ml' && <h2 className="headding3 float-none">നിയമപരമായ നിരാകരണം</h2>}
                    {i18n.language === 'ar' && <h2 className="headding3 float-none">إخلاء المسؤولية القانونية</h2>}
                    {i18n.language === 'ur' && <h2 className="headding3 float-none"> لیگل ڈس کلیمر</h2>} */}
                    {/* {i18n.language === 'ur' ?
                        <div className="addlPage scrollbar">
                            <div className="addlpageCont">
                                <ul className="faqAccordian">
                                    <li>
                                        <a >شرائط کی قبولیت</a>
                                        <p>
                                            گلف ٹکٹ کی ویب سائٹ یا خدمات استعمال کرکے، آپ اس لیگل ڈس کلیمر کی شرائط کے پابند ہونے سے
                                            اتفاق کرتے ہیں۔ اگر آپ اس لیگل ڈس کلیمر کی شرائط سے اتفاق نہیں کرتے ہیں، تو آپ کو گلف
                                            ٹکٹ کی ویب سائٹ یا خدمات کا استعمال نہیں کرنا چاہیے۔
                                        </p>
                                    </li>
                                    <li>
                                        <a >وارنٹیوں کا انکار</a>
                                        <p>
                                            گلف ٹکٹ کی ویب سائٹ اور خدمات "جیسی ہے" اور "جیسے ہی دستیاب ہوتی ہے" کی بنیاد پر فراہم
                                            کی جاتی ہیں۔ گلف ٹکٹ کی ویب سائٹ یا خدمات کے حوالے سے گلف ٹکٹ کسی بھی قسم کی کوئی ظاہر
                                            یا مضمر نمائندگی یا وارنٹی نہیں دیتا، جن میں مندرجہ ذیل شامل ہیں لیکن انہیں تک محدود
                                            نہیں ہیں:
                                        </p>
                                        <ul>
                                            <li>
                                                <p> گلف ٹکٹ کی ویب سائٹ یا خدمات پر موجود مواد یا معلومات کا درست، مکمل، بروقت یا
                                                    قابل اعتماد ہونا۔</p>
                                            </li>
                                            <li>
                                                <p> تجارتی قابلیت، کسی خاص مقصد کے لیے موزونیت یا کسی بھی دانشورانہ ملکیت کے حقوق یا
                                                    تیسرے فریق کے دیگر حقوق کی خلاف ورزی نہ کرنا۔</p>
                                            </li>
                                            <li>
                                                <p> گلف ٹکٹ کی ویب سائٹ یا خدمات کی سیکیورٹی یا آپ کی ذاتی معلومات یا دیگر ڈیٹا تک
                                                    غیر مجاز رسائی کو روکنے کے لیے گلف ٹکٹ کی صلاحیت۔</p>
                                            </li>
                                            <li>
                                                <p> گلف ٹکٹ کی ویب سائٹ یا خدمات کا بے روک یا غلطی سے پاک عمل۔</p>
                                            </li>

                                        </ul>
                                    </li>
                                    <li>
                                        <a >ذمہ داری کی حد </a>
                                        <p>قانون کی طرف سے اجازت یافتہ مکمل حد تک، گلف ٹکٹ کسی بھی براہ راست، بالواسطہ، واقعاتی،
                                            خاص، نتیجہ خیز یا مثالی نقصان کے لیے ذمہ دار نہیں ہوگا جو آپ کے گلف ٹکٹ کی ویب سائٹ یا
                                            خدمات کے استعمال سے یا اس کے سلسلے میں پیدا ہوتا ہے، جن میں مندرجہ ذیل شامل ہیں لیکن
                                            انہیں تک محدود نہیں ہیں:
                                        </p>
                                        <ul>
                                            <li>
                                                <p> منافع، خیر سگالی، استعمال، ڈیٹا کے نقصان یا دیگر غیر محسوس نقصانات۔</p>
                                            </li>
                                            <li>
                                                <p> ذاتی چوٹ کے نقصانات یا ملکیت کو پہنچنے والے نقصانات۔</p>
                                            </li>
                                            <li>
                                                <p> کارکردگی کی کسی بھی ناکامی، غلطی، بھول چوک، رکاوٹ، خرابی، عمل یا منتقلی میں
                                                    تاخیر، کمپیوٹر وائرس یا مواصلات کی لائن کی ناکامی کے لیے نقصانات۔</p>
                                            </li>
                                            <li>
                                                <p> گلف ٹکٹ کی ویب سائٹ یا خدمات کے آپ کے استعمال سے پیدا ہونے والے کسی تیسرے فریق
                                                    کے دعوے کے نقصانات۔</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a >معاوضہ </a>
                                        <p>
                                            آپ گلف ٹکٹ، اس کے افسران، ڈائریکٹرز، ملازمین، کارندوں اور ملحقہ اداروں کو کسی بھی اور
                                            تمام دعووں، ذمے داریوں، نقصانات، خسارے، خرچ اور اخراجات کے خلاف ہرجانہ ادا کرنے، ان کا
                                            دفاع کرنے اور انہیں ضرر سے پاک رکھنے پر اتفاق کرتے ہیں، جن میں مندرجہ ذیل سے یا ان کے
                                            نتیجے میں پیش آنے والی وکلاء کی فیس بھی شامل ہیں:
                                        </p>
                                        <ul>
                                            <li>
                                                <p> گلف ٹکٹ کی ویب سائٹ یا خدمات کا آپ کا استعمال۔</p>
                                            </li>
                                            <li>
                                                <p> اس لیگل ڈس کلیمر کی آپ کی خلاف </p>
                                            </li>
                                            <li>
                                                <p> آپ کی طرف سے کسی بھی دانشورانہ ملکیت کے حقوق یا فریق ثالث کے دیگر حقوق کی خلاف
                                                    ورزی۔</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a >لاگو قانون اور دائرہ اختیار </a>
                                        <p>اس لیگل ڈس کلیمر پر قانون کی دفعات کے ساتھ اس کے تصادم کی پرواہ کیے بغیرمتحدہ عرب امارات
                                            کے قوانین کے تحت عمل کیا جائے گا اور انہیں کے مطابق انہیں بنایا جائے گا۔ اس لیگل ڈس
                                            کلیمر سے یا اس کے تعلق سے پیدا ہونے والے تمام تنازعات متحدہ عرب امارات کی عدالتوں کے
                                            خصوصی دائرہ اختیار کے تابع ہوں گے۔</p>
                                    </li>
                                    <li>
                                        <a >مکمل معاہدہ</a>
                                        <ul>
                                            <li>
                                                <p>
                                                    یہ لیگل ڈس کلیمر گلف ٹکٹ کی ویب سائٹ اور خدمات کے آپ کے استعمال کے حوالے سے آپ
                                                    اور گلف ٹکٹ کے درمیان ہونے والے مکمل معاہدے کو تشکیل دیتا ہے۔ یہ لیگل ڈس کلیمر
                                                    آپ اور گلف ٹکٹ کے درمیان ہونے والے کسی بھی تحریری یا زبانی سابقہ معاہدے،
                                                    نمائندگی اور مفاہمت کی جگہ لے لیتا ہے۔
                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a >علیحدگی پذیری </a>
                                        <p>
                                            اگر اس لیگل ڈس کلیمر کے کسی بھی دفعہ کو غلط یا ناقابل نفاذ قرار دیا جاتا ہے، تو ایسا
                                            دفعہ اس لیگل ڈس کلیمر سے ہٹ جائے گا اور بقیہ دفعات مکمل طور پر نافذ رہیں گے۔
                                        </p>
                                    </li>
                                    <li>
                                        <a >دست برداری </a>
                                        <p>
                                            اس لیگل ڈس کلیمر کی کسی بھی دفعہ سے دست برداری اس وقت تک موثر نہیں ہوگی جب تک کہ یہ
                                            تحریری شکل میں نہ ہو اور اس پر آپ اور گلف ٹکٹ دونوں کے دستخط نہ ہوں۔
                                            .</p>
                                    </li>
                                    <li>
                                        <a >نوٹس </a>
                                        <p>اس لیگل ڈس کلیمر کے تحت تمام نوٹس اور دیگر مواصلات تحریری شکل میں ہوں گے اور انہیں اسی
                                            صورت میں مکمل طور پر حوالہ کردہ سمجھا جائے گا جب کہ وہ ڈاک کا محصول ادا کردہ، تصدیق شدہ
                                            یا رجسٹرڈ، واپسی کی رسید کی درخواست والے متحدہ عرب امارات کے ڈاک کے سپرد کیے جانے کے بعد
                                            پہلے کاروباری دن شخصی طور پر حوالہ کئے گئے ہوں اور ان پر مندرجہ ذیل کے طور پر پتہ لکھا
                                            گیا ہو:</p>
                                        <p>اگر آپ کے نام سے ہو:</p>
                                        <p>[آپ کا نام]</p>
                                        <p>[آپ کا پتہ]</p>
                                        <p>اگر گلف ٹکٹ کے نام سے ہو:</p>
                                        <p>گلف ٹکٹ</p>
                                        <p>206، الحوائی ٹاور، شیخ زائد روڈ، فائننشیئل سینٹر، دبئی، یو اے ای۔</p>
                                        <p>یا ایسے دوسرے پتوں پر بھیجا گیا ہو جنہیں دونوں فریق وقتا فوقتا تحریری طور پر مقرر کر سکتے
                                            ہیں۔</p>
                                    </li>
                                    <li>
                                        <a >لیگل ڈس کلیمر میں تبدیلیاں</a>
                                        <p>
                                            گلف ٹکٹ کسی بھی وقت اس لیگل ڈس کلیمر کو تبدیل کرنے کا حق محفوظ رکھتا ہے۔ کسی بھی اپ ڈیٹ
                                            سے باخبر رہنے کے لیے ہم آپ کو وقتا فوقتا اس لیگل ڈس کلیمر کا جائزہ لینے کی ترغیب دیتے
                                            ہیں۔
                                        </p>
                                    </li>
                                    <li>
                                        <a >ہم سے رابطہ کریں</a>
                                        <p>اگر آپ کے پاس اس لیگل ڈس کلیمر کے بارے میں کوئی سوال ہے، تو براہ کرم ہم سے
                                            [support@gulfticket.com] پر رابطہ کریں</p>
                                    </li>
                                    <li>
                                        <a >اضافی شرائط و ضوابط</a>
                                        <ul>
                                            <li>
                                                <p>گلف ٹکٹ اپنے کھلاڑیوں کی رازداری کے تحفظ کے لیے پرعزم ہے۔ آپ کی ذاتی معلومات کو
                                                    اکٹھا، استعمال اور اس کا اشتراک کرنے کے ہمارے طریقے کے بارے میں مزید معلومات کے
                                                    لیے براہ کرم ہماری رازداری کی پالیسی کا جائزہ لیں۔</p>
                                            </li>
                                            <li>
                                                <p> گلف ٹکٹ کسی ایسی تکنیکی دشواری کے لیے ذمہ دار نہیں ہے جو آپ کو ڈرا کھیلنے یا
                                                    اپنی جیت کا دعوی کرنے سے روک سکتی ہے۔</p>
                                            </li>
                                            <li>
                                                <p> گلف ٹکٹ کسی بھی وقت کسی بھی گلف ٹکٹ ڈرا کو منسوخ یا معطل کرنے کا حق محفوظ رکھتا
                                                    ہے۔</p>
                                            </li>
                                            <li>
                                                <p> اگر کوئی کھلاڑی اس لیگل ڈس کلیمر کی خلاف ورزی کرتا ہے یا اگر وہ کسی دھوکہ دہی یا
                                                    غیر قانونی سرگرمی میں ملوث ہوتا ہے، تو گلف ٹکٹ اسے ڈرا کھیلنے یا اپنی جیت کا
                                                    دعوی کرنے سے نااہل قرار دینے کا حق محفوظ رکھتا ہے ۔</p>
                                            </li>
                                            <li>
                                                <p>گلف ٹکٹ کھلاڑیوں کی شرکت کے نتیجے میں ہونے والے کسی نقصان یا خسارے کے لیے ذمہ دار
                                                    نہیں ہے۔

                                                </p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        : i18n.language === 'ml' ?
                            <div className="addlPage scrollbar">
                                <div className="addlpageCont">

                                    <ul className="faqAccordian">
                                        <li>
                                            <a >നിബന്ധനകളുടെ സ്വീകാര്യത</a>
                                            <p>ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റോ സേവനങ്ങളോ ഉപയോഗിക്കുന്നതിലൂടെ, ഈ നിയമപരമായ നിരാകരണത്തിന്റെ നിബന്ധനകൾ പാലിക്കാൻ നിങ്ങൾ സമ്മതിക്കുന്നു. ഈ നിയമപരമായ നിരാകരണത്തിന്റെ നിബന്ധനകൾ നിങ്ങൾ അംഗീകരിക്കുന്നില്ലെങ്കിൽ, നിങ്ങൾ ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റോ സേവനങ്ങളോ ഉപയോഗിക്കരുത്.</p>
                                        </li>
                                        <li>
                                            <a >വാറന്റികളുടെ നിരാകരണം </a>
                                            <p>ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റും സേവനങ്ങളും "ഉള്ളതുപോലെ", "ലഭ്യമായതുപോലെ" അടിസ്ഥാനത്തിൽ നൽകുന്നു. ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിനെയോ സേവനങ്ങളെയോ കുറിച്ച് ഗൾഫ് ടിക്കറ്റ് ഏതെങ്കിലും തരത്തിലുള്ള പ്രാതിനിധ്യങ്ങളോ വാറന്റികളോ നൽകുന്നില്ല, ഇനിപ്പറയുന്നവ ഉൾപ്പെടെ എന്നാൽ അതിൽ മാത്രം പരിമിതപ്പെടുത്തുന്നില്ല:</p>
                                            <ul>
                                                <li>
                                                    <p>	ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിലോ സേവനങ്ങളിലോ ഉള്ളടക്കത്തിന്റെയോ വിവരങ്ങളുടെയോ കൃത്യത, പൂർണ്ണത, സമയബന്ധിതത അല്ലെങ്കിൽ വിശ്വാസ്യത.</p>
                                                </li>
                                                <li>
                                                    <p>വ്യാപാരക്ഷമത, ഒരു പ്രത്യേക ഉദ്ദേശ്യത്തിനുള്ള യോഗ്യത, അല്ലെങ്കിൽ ഏതെങ്കിലും ബൗദ്ധിക സ്വത്തവകാശങ്ങളോ മൂന്നാം കക്ഷികളുടെ മറ്റ് അവകാശങ്ങളോ ലംഘിക്കാതിരിക്കുക.</p>
                                                </li>
                                                <li>
                                                    <p>	ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിന്റെയോ സേവനങ്ങളുടെയോ സുരക്ഷ, അല്ലെങ്കിൽ നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങളോ മറ്റ് ഡാറ്റയോ അനധികൃതമായി കൈകാര്യം ചെയ്യുന്നത് തടയുന്നതിനുള്ള ഗൾഫ് ടിക്കറ്റിന്റെ കഴിവ്.</p>
                                                </li>
                                                <li><p>ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിന്റെയോ സേവനങ്ങളുടെയോ തടസ്സമില്ലാത്ത അല്ലെങ്കിൽ പിശകില്ലാത്ത പ്രവർത്തനം.</p></li>

                                            </ul>
                                        </li>
                                        <li>
                                            <a >ബാധ്യതയുടെ പരിമിതി </a>
                                            <p>നിയമം അനുവദിക്കുന്ന പരമാവധി പരിധി വരെ, ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റിന്റെയോ സേവനങ്ങളുടെയോ ഉപയോഗത്തിൽ നിന്നോ അതുമായി ബന്ധപ്പെട്ടോ ഉണ്ടാകുന്ന പ്രത്യക്ഷമോ പരോക്ഷമോ ആകസ്മികമോ സവിശേഷമോ അനന്തരഫലമോ അനുകരണീയമോ ആയ നാശനഷ്ടങ്ങൾക്ക് ഗൾഫ് ടിക്കറ്റ് ബാധ്യസ്ഥമല്ല.</p>
                                            <ul>
                                                <li>
                                                    <p>ലാഭ നഷ്ടങ്ങൾ, ഗുഡ് വിൽ, ഉപയോഗം, ഡാറ്റ നഷ്ടങ്ങൾ അല്ലെങ്കിൽ മറ്റ് അദൃശ്യ നഷ്ടങ്ങൾ എന്നിവയ്ക്കുള്ള നാശനഷ്ടങ്ങൾ.</p>
                                                </li>
                                                <li>
                                                    <p>വ്യക്തിഗത പരിക്കുകൾക്കോ വസ്തുവകകൾക്കോ ഉള്ള നാശനഷ്ടങ്ങൾ.</p>
                                                </li>
                                                <li>
                                                    <p>പ്രകടനത്തിലെ ഏതെങ്കിലും പരാജയം, പിശക്, ഒഴിവാക്കൽ, തടസ്സം, തകരാറ്, പ്രവർത്തനത്തിലോ ട്രാൻസ്മിഷനിലോ കാലതാമസം, കമ്പ്യൂട്ടർ വൈറസ് അല്ലെങ്കിൽ ആശയവിനിമയ ലൈൻ പരാജയം എന്നിവയ്ക്കുള്ള കേടുപാടുകൾ.</p>
                                                </li>
                                                <li>
                                                    <p> ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റോ സേവനങ്ങളോ നിങ്ങൾ ഉപയോഗിക്കുന്നതിൽ നിന്ന് ഉണ്ടാകുന്ന ഏതെങ്കിലും മൂന്നാം കക്ഷി ക്ലെയിമിനുള്ള നാശനഷ്ടങ്ങൾ.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a >നഷ്ടപരിഹാരം </a>
                                            <p>നിരുപദ്രവകരമായ ഗൾഫ് ടിക്കറ്റ്, അതിന്റെ ഓഫീസർമാർ, ഡയറക്ടർമാർ, ജീവനക്കാർ, ഏജന്റുമാർ, അഫിലിയേറ്റുകൾ എന്നിവയിൽ നിന്ന് നഷ്ടപരിഹാരം നൽകാനും പ്രതിരോധിക്കാനും കൈവശം വയ്ക്കാനും നിങ്ങൾ സമ്മതിക്കുന്നു, ഇനിപ്പറയുന്നവയുമായി ബന്ധപ്പെട്ട് ഉണ്ടാകുന്ന ഏതെങ്കിലും ക്ലെയിമുകൾ, ബാധ്യതകൾ, നാശനഷ്ടങ്ങൾ, നഷ്ടങ്ങൾ, ചെലവുകൾ, ചെലവുകൾ എന്നിവയിൽ നിന്ന് നഷ്ടപരിഹാരം നൽകാനും പ്രതിരോധിക്കാനും കൈവശം വയ്ക്കാനും നിങ്ങൾ സമ്മതിക്കുന്നു:</p>
                                            <ul>
                                                <li>
                                                    <p>ഗൾഫ് ടിക്കറ്റ് വെബ്സൈറ്റ് അല്ലെങ്കിൽ സേവനങ്ങളുടെ നിങ്ങളുടെ ഉപയോഗം.</p>
                                                </li>
                                                <li>
                                                    <p>ഈ നിയമപരമായ നിരാകരണത്തിന്റെ നിങ്ങളുടെ ലംഘനം.</p>
                                                </li>
                                                <li>
                                                    <p>ഏതെങ്കിലും ബൗദ്ധിക സ്വത്തവകാശങ്ങളോ മൂന്നാം കക്ഷികളുടെ മറ്റ് അവകാശങ്ങളോ നിങ്ങൾ ലംഘിക്കുകയാണെങ്കിൽ.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a >ഭരണ നിയമവും അധികാരപരിധിയും </a>
                                            <p>ഈ നിയമപരമായ നിരാകരണം യുണൈറ്റഡ് അറബ് എമിറേറ്റിലെ നിയമങ്ങൾക്കനുസൃതമായി, അതിന്റെ നിയമ വ്യവസ്ഥകളുടെ വൈരുദ്ധ്യം കണക്കിലെടുക്കാതെ നിയന്ത്രിക്കുകയും വ്യാഖ്യാനിക്കുകയും ചെയ്യും. ഈ നിയമപരമായ നിരാകരണം മൂലമോ അതുമായി ബന്ധപ്പെട്ടോ ഉണ്ടാകുന്ന ഏതൊരു തർക്കവും യുണൈറ്റഡ് അറബ് എമിറേറ്റിലെ കോടതികളുടെ പ്രത്യേക അധികാരപരിധിക്ക് വിധേയമായിരിക്കും.</p>
                                        </li>
                                        <li>
                                            <a >മുഴുവൻ കരാർ</a>
                                            <ul>
                                                <li>
                                                    <p>ഗൾഫ് ടിക്കറ്റ് വെബ് സൈറ്റിന്റെയും സേവനങ്ങളുടെയും നിങ്ങളുടെ ഉപയോഗവുമായി ബന്ധപ്പെട്ട് നിങ്ങളും ഗൾഫ് ടിക്കറ്റും തമ്മിലുള്ള മുഴുവൻ കരാറും ഈ നിയമപരമായ നിരാകരണം ഉൾക്കൊള്ളുന്നു. രേഖാമൂലമോ വാക്കാലോ നിങ്ങളും ഗൾഫ് ടിക്കറ്റും തമ്മിലുള്ള ഏതെങ്കിലും മുൻ കരാറുകൾ, പ്രാതിനിധ്യങ്ങൾ, ധാരണകൾ എന്നിവ ഇതിനാൽ ഈ നിയമപരമായ നിരാകരണം മറികടക്കുന്നു.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a >വേർപിരിയൽ </a>
                                            <p>ഈ നിയമപരമായ നിരാകരണത്തിലെ ഏതെങ്കിലും വ്യവസ്ഥ അസാധുവാണെന്നോ നടപ്പാക്കാൻ കഴിയില്ലെന്നോ കരുതുകയാണെങ്കിൽ, അത്തരം വ്യവസ്ഥ ഈ നിയമപരമായ നിരാകരണത്തിൽ നിന്ന് നീക്കം ചെയ്യപ്പെടുകയും ശേഷിക്കുന്ന വ്യവസ്ഥകൾ പൂർണ്ണ ശക്തിയിലും പ്രാബല്യത്തിലും തുടരുകയും ചെയ്യും.</p>
                                        </li>
                                        <li>
                                            <a >ഇളവ് </a>
                                            <p>നിങ്ങളും ഗൾഫ് ടിക്കറ്റും രേഖാമൂലം ഒപ്പിടാതെ ഈ നിയമപരമായ നിരാകരണത്തിന്റെ ഏതെങ്കിലും വ്യവസ്ഥ ഒഴിവാക്കുന്നത് ഫലപ്രദമാകില്ല.</p>
                                        </li>

                                        <li>
                                            <a >അറിയിപ്പുകൾ </a>
                                            <p>ഈ നിയമപരമായ നിരാകരണത്തിന് കീഴിലുള്ള എല്ലാ അറിയിപ്പുകളും മറ്റ് ആശയവിനിമയങ്ങളും രേഖാമൂലമായിരിക്കും, യുണൈറ്റഡ് അറബ് എമിറേറ്റ്സ് മെയിൽ, തപാൽ പ്രീപെയ്ഡ്, സാക്ഷ്യപ്പെടുത്തിയ അല്ലെങ്കിൽ രജിസ്റ്റർ ചെയ്ത, റിട്ടേൺ രസീത് അഭ്യർത്ഥിക്കുകയും ഇനിപ്പറയുന്ന വിലാസത്തിൽ അഭിസംബോധന ചെയ്യുകയും ചെയ്ത ആദ്യത്തെ ബിസിനസ്സ് ദിവസം, നേരിട്ട് ഡെലിവറി ചെയ്യുമ്പോൾ ശരിയായി നൽകിയതായി കണക്കാക്കും:</p>
                                            <p>നിങ്ങളാണെങ്കിൽ </p>
                                            <p>[നിങ്ങളുടെ പേര്]</p>
                                            <p>[നിങ്ങളുടെ വിലാസം]</p>
                                            <p>ഗൾഫ് ടിക്കറ്റാണെങ്കിൽ:</p>
                                            <p>ഗൾഫ് ടിക്കറ്റ്</p>
                                            <p>206, അൽ ഹവായ് ടവർ, ഷെയ്ഖ് സായിദ് റോഡ്, ഫിനാൻഷ്യൽ സെന്റർ, ദുബായ്, യുഎഇ.</p>
                                            <p>അല്ലെങ്കിൽ ഏതെങ്കിലും കക്ഷിക്ക് കാലാകാലങ്ങളിൽ രേഖാമൂലം നിർദ്ദേശിക്കാവുന്ന മറ്റ് വിലാസങ്ങൾ.

                                            </p>
                                        </li>
                                        <li>
                                            <a >നിയമപരമായ നിരാകരണത്തിലെ മാറ്റങ്ങൾ </a>
                                            <p>ഏത് സമയത്തും ഈ നിയമപരമായ നിരാകരണം മാറ്റാനുള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്. എന്തെങ്കിലും അപ്ഡേറ്റുകളെ കുറിച്ച് അറിയുന്നതിന് ഇടയ്ക്കിടെ ഈ നിയമപരമായ നിരാകരണം അവലോകനം ചെയ്യാൻ ഞങ്ങൾ നിങ്ങളെ പ്രോത്സാഹിപ്പിക്കുന്നു.</p>
                                        </li>
                                        <li>
                                            <a >ഞങ്ങളെ ബന്ധപ്പെടുക</a>
                                            <p>ഈ നിയമപരമായ നിരാകരണത്തെ കുറിച്ച് നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യങ്ങൾ ഉണ്ടെങ്കിൽ, ദയവായി ഞങ്ങളെ ബന്ധപ്പെടുക [support@gulfticket.com]	</p>
                                        </li>
                                        <li>
                                            <a >അധിക നിബന്ധനകളും വ്യവസ്ഥകളും</a>
                                            <ul>
                                                <li>
                                                    <p>കളിക്കാരുടെ സ്വകാര്യത പരിരക്ഷിക്കാൻ ഗൾഫ് ടിക്കറ്റ് പ്രതിജ്ഞാബദ്ധമാണ്. നിങ്ങളുടെ വ്യക്തിഗത വിവരങ്ങൾ ഞങ്ങൾ എങ്ങനെ ശേഖരിക്കുകയും ഉപയോഗിക്കുകയും പങ്കിടുകയും ചെയ്യുന്നു എന്നതിനെ കുറിച്ചുള്ള കൂടുതൽ വിവരങ്ങൾക്ക് ഞങ്ങളുടെ സ്വകാര്യതാ നയം അവലോകനം ചെയ്യുക.</p>
                                                </li>
                                                <li>
                                                    <p>നറുക്കെടുപ്പ് കളിക്കുന്നതിൽ നിന്നോ നിങ്ങളുടെ വിജയങ്ങൾ അവകാശപ്പെടുന്നതിൽ നിന്നോ നിങ്ങളെ തടഞ്ഞേക്കാവുന്ന ഏതെങ്കിലും സാങ്കേതിക ബുദ്ധിമുട്ടുകൾക്ക് ഗൾഫ് ടിക്കറ്റ് ഉത്തരവാദിയല്ല.</p>
                                                </li>
                                                <li>
                                                    <p>ഏത് സമയത്തും ഏതെങ്കിലും ഗൾഫ് ടിക്കറ്റ് നറുക്കെടുപ്പ് റദ്ദാക്കാനോ താൽക്കാലികമായി നിർത്താനോ ഉള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്.</p>
                                                </li>
                                                <li>
                                                    <p>ഈ നിയമപരമായ നിരാകരണം ലംഘിക്കുകയോ ഏതെങ്കിലും വഞ്ചനാപരമോ നിയമവിരുദ്ധമോ ആയ പ്രവർത്തനങ്ങളിൽ ഏർപ്പെടുകയോ ചെയ്താൽ ഏതെങ്കിലും കളിക്കാരനെ നറുക്കെടുപ്പ് കളിക്കുന്നതിൽ നിന്നോ അവരുടെ വിജയങ്ങൾ അവകാശപ്പെടുന്നതിൽ നിന്നോ അയോഗ്യനാക്കാനുള്ള അവകാശം ഗൾഫ് ടിക്കറ്റിൽ നിക്ഷിപ്തമാണ്.</p>
                                                </li>
                                                <li>
                                                    <p>പങ്കാളിത്തത്തിന്റെ ഫലമായി കളിക്കാർക്ക് ഉണ്ടാകുന്ന എന്തെങ്കിലും നഷ്ടത്തിനോ നാശനഷ്ടങ്ങൾക്കോ ഗൾഫ് ടിക്കറ്റ് ഉത്തരവാദിയല്ല</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div> : i18n.language === 'ar' ?
                                <div className="addlPage scrollbar">
                                    <div className="addlpageCont">
                                        <ul className="faqAccordian">
                                            <li>
                                                <a > الموافقة على الشروط</a>
                                                <p>
                                                    باستخدام موقع أو خدمات تذكرة الخليج، فإنك توافق على الالتزام بشروط إخلاء المسؤولية القانونية هذا. إذا كنت لا توافق على شروط إخلاء المسؤولية القانونية، فيجب عليك عدم استخدام موقع تذكرة الخليج أو خدماته.
                                                </p>
                                            </li>
                                            <li>
                                                <a >إخلاء المسؤولية عن الضمانات</a>
                                                <p>
                                                    يتم توفير موقع وخدمات تذكرة الخليج على أساس "كما هي" و"كما هو متاح". لا تقدم تذكرة الخليج أي تعهدات أو ضمانات من أي نوع، صريحة أو ضمنية، فيما يتعلق بموقع أو خدمات تذكرة الخليج، بما في ذلك على سبيل المثال لا الحصر ما يلي:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <p>	دقة أو اكتمال أو توقيت أو موثوقية المحتوى أو المعلومات الموجودة على موقع أو خدمات موقع تذكرة الخليج.</p>
                                                    </li>
                                                    <li>
                                                        <p>	القابلية للتسويق أو الملاءمة لغرض معين أو عدم انتهاك أي حقوق ملكية فكرية أو حقوق أخرى لأطراف ثالثة.</p>
                                                    </li>
                                                    <li>
                                                        <p>	أمن موقع أو خدمات تذكرة الخليج، أو قدرة تذكرة الخليج على منع الوصول غير المصرح به إلى معلوماتك الشخصية أو البيانات الأخرى.</p>
                                                    </li>
                                                    <li>
                                                        <p>التشغيل المتواصل أو الخالي من الأخطاء لموقع أو خدمات تذكرة الخليج.</p>
                                                    </li>

                                                </ul>
                                            </li>
                                            <li>
                                                <a > حدود المسؤوليات القانونية </a>
                                                <p>
                                                    إلى أقصى حد يسمح به القانون، لن تكون شركة تذكرة الخليج مسؤولة عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة أو تبعية أو نموذجية تنشأ عن أو فيما يتعلق باستخدامك لموقع أو خدمات تذكرة الخليج، بما في ذلك على سبيل المثال لا الحصر:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <p>	الأضرار الناجمة عن خسارة الأرباح أو الشهرة أو الاستخدام أو البيانات أو غيرها من الخسائر غير الملموسة.</p>
                                                    </li>
                                                    <li>
                                                        <p>	الأضرار الناجمة عن الإصابة الشخصية أو الأضرار في الممتلكات.</p>
                                                    </li>
                                                    <li>
                                                        <p>	الأضرار الناجمة عن أي فشل في الأداء أو خطأ أو إغفال أو انقطاع أو عيب أو تأخير في التشغيل أو الإرسال أو فيروس الكمبيوتر أو فشل خط الاتصال.</p>
                                                    </li>
                                                    <li>
                                                        <p>	الأضرار الناجمة عن أي مطالبة من طرف ثالث تنشأ عن استخدامك لموقع أو خدمات تذكرة الخليج.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a >التعويض </a>
                                                <p>
                                                    أنت توافق على تعويض شركة تذكرة الخليج والدفاع عنها وحمايتها ومسؤوليها ومديريها وموظفيها ووكلائها والشركات التابعة لها من وضد أي وجميع المطالبات والالتزامات والأضرار والخسائر والتكاليف والنفقات، بما في ذلك على سبيل المثال لا الحصر المحامين. الرسوم الناشئة عن أو فيما يتعلق بما يلي:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <p>	استخدامك لموقع أو خدمات تذكرة الخليج.</p>
                                                    </li>
                                                    <li>
                                                        <p>	انتهاكك لهذا إخلاء المسؤولية القانونية.</p>
                                                    </li>
                                                    <li>
                                                        <p>	أي انتهاك من جانبك لأي من حقوق الملكية الفكرية أو حقوق أخرى لأطراف ثالثة.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a >القانون والسلطة القضائية </a>
                                                <p>يخضع إخلاء المسؤولية القانونية هذا ويفسر وفقًا لقوانين دولة الإمارات العربية المتحدة، بغض النظر عن تعارضها مع أحكام القانون. تخضع أي وكل النزاعات التي تنشأ عن أو فيما يتعلق بإخلاء المسؤولية القانونية هذا للاختصاص القضائي الحصري لمحاكم دولة الإمارات العربية المتحدة.</p>
                                            </li>
                                            <li>
                                                <a >  مجمل الاتفاقية</a>
                                                <ul>
                                                    <li>
                                                        <p>يشكل إخلاء المسؤولية القانونية هذا الاتفاقية الكاملة بينك وبين تذكرة الخليج فيما يتعلق باستخدامك لموقع وخدمات تذكرة الخليج. أي اتفاقيات أو إقرارات أو تفاهمات سابقة بينك وبين شركة تذكرة الخليج ، سواء كانت مكتوبة أو شفهية، تحل محلها إخلاء المسؤولية القانونية هذا.</p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a >الاستقلالية </a>
                                                <p>إذا تبين أن أي بند من إخلاء المسؤولية القانونية هذا غير صالح أو غير قابل للتنفيذ، فسيتم حذف هذا الشرط من إخلاء المسؤولية القانونية هذا وتظل الأحكام المتبقية سارية المفعول والتأثير الكامل.</p>
                                            </li>
                                            <li>
                                                <a >التنازلات </a>
                                                <p>لن يكون أي تنازل عن أي حكم من أحكام إخلاء المسؤولية القانونية هذا ساريًا ما لم يكن كتابيًا وموقعًا من قبلك أنت وتذكرة الخليج.</p>
                                            </li>

                                            <li>
                                                <a >الإشعارات والمراسلات </a>
                                                <p>
                                                    يجب أن تكون جميع الإشعارات والمراسلات الأخرى بموجب إخلاء المسؤولية القانونية كتابية وتعتبر قد تم تسليمها حسب الأصول عند تسليمها شخصيًا في أول يوم عمل بعد الإيداع في بريد دولة الإمارات العربية المتحدة أو رسوم البريد المدفوعة مسبقًا أو المعتمدة أو المسجلة وإيصال الإرجاع المطلوب، وذلك على النحو التالي:
                                                </p>
                                                <p>
                                                    إذا لك:
                                                </p>
                                                <p>[اسمك]</p>
                                                <p>[عنوانك]</p>
                                                <p>إذا إلى تذكرة الخليج:</p>
                                                <p>تذكرة الخليج</p>
                                                <p>206، برج هاواي ، طريق الشيخ زايد، المركز المالي، دبي، الإمارات العربية المتحدة.</p>
                                                <p>أو إلى العناوين الأخرى التي قد يحددها أي من الطرفين كتابيًا من وقت لآخر.

                                                </p>
                                            </li>
                                            <li>
                                                <a > التغييرات في إخلاء المسؤولية القانونية</a>
                                                <p>تحتفظ شركة تذكرة الخليج بالحق في تغيير إخلاء المسؤولية القانونية هذا في أي وقت. نحن نشجعك على مراجعة إخلاء المسؤولية القانونية هذا بشكل دوري للبقاء على اطلاع بأية تحديثات.</p>
                                            </li>
                                            <li>
                                                <a >  اتصل بنا  </a>
                                                <p>إذا كانت لديك أي أسئلة حول إخلاء المسؤولية القانونية هذا، يرجى الاتصال بنا على [support@gulfticket.com]</p>
                                            </li>
                                            <li>
                                                <a >أحكام وشروط إضافية</a>
                                                <ul>
                                                    <li>
                                                        <p>تلتزم شركة تذكرة الخليج بحماية خصوصية لاعبيها. يرجى مراجعة سياسة الخصوصية الخاصة بنا للحصول على مزيد من المعلومات حول كيفية جمع معلوماتك الشخصية واستخدامها ومشاركتها..</p>
                                                    </li>
                                                    <li>
                                                        <p>تذكرة الخليج ليست مسؤولة عن أي صعوبات فنية قد تمنعك من لعب القرعة أو المطالبة بأرباحك.</p>
                                                    </li>
                                                    <li>
                                                        <p>تحتفظ شركة تذكرة الخليج بالحق في إلغاء أو تعليق أي سحب على تذكرة الخليج في أي وقت.</p>
                                                    </li>
                                                    <li>
                                                        <p>تحتفظ شركة تذكرة الخليج بالحق في استبعاد أي لاعب من لعب القرعة أو المطالبة بأرباحه إذا انتهك إخلاء المسؤولية القانوني هذا أو إذا شارك في أي نشاط احتيالي أو غير قانوني.</p>
                                                    </li>
                                                    <li>
                                                        <p>تذكرة الخليج ليست مسؤولة عن أي خسائر أو أضرار يتكبدها اللاعبون نتيجة مشاركتهم في اللعبة</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div> : */}
                                <>
                                    <div className="mt-5">
                                        <h3 className="text-base lg:text-xl font-semibold">Legal</h3>
                                        <div className="text-sm">Last updated January 24, 2024</div>

                                        <p className="mt-3">
                                            The Platform, along with any correspondence initiated by or on our behalf and the
                                            information enclosed, is exclusively intended for individuals eligible to establish an
                                            Account and participate in Games. It is not designed to allure or motivate individuals
                                            who do not conform to our eligibility criteria to engage in Games, and it does not
                                            constitute an invitation or offer by the Manager to any person to partake in such
                                            activities.
                                        </p>

                                        <p className="mt-3">
                                            Upon accessing the Platform, registering for an Account, and/or participating in Games,
                                            you acknowledge your responsibility to ensure that creating and/or accessing your
                                            Account and/or entering Games is legally permissible in the jurisdiction where you are
                                            physically located during transactions with us. We are not obligated to award a Prize to
                                            any Participant for an Entry made while the Participant was physically located in a
                                            jurisdiction where such Entry is deemed unlawful.
                                        </p>
                                        <p className="mt-3">
                                            We provide no warranties, guarantees, or representations, whether express or implied,
                                            regarding the accuracy, availability, completeness, suitability, or reliability of the
                                            Content and information on the Platform or in other communications sent by or on behalf
                                            of us for any purpose. Your reliance on such information is entirely at your own risk.
                                        </p>
                                        <p className="mt-3">
                                            The Manager, along with its officers, members, and employees, disclaims any liability
                                            for loss or damage (including, but not limited to, indirect or consequential loss or
                                            damage of any kind) arising out of, or in connection with, the use of the Platform or
                                            reliance on its contents. Upon accessing the Platform, you agree not to bring any claims
                                            against the Manager, its officers, members, or employees regarding any losses you may
                                            incur in connection with the Platform.
                                        </p>
                                        <p className="mt-3">
                                            By utilizing the Platform, you expressly agree that your use is at your sole risk. We do
                                            not guarantee that the Platform will be uninterrupted or error-free. In no
                                            circumstances, including negligence, shall We, our Managers, officers, or employees, or
                                            any other involved parties, be liable for any damages whatsoever (including, but not
                                            limited to, direct, incidental, consequential, indirect, or punitive damages) arising
                                            from or related to your access to, use of, or inability to access or use the Platform,
                                            or other locations accessible through the Platform, even if We have advised you of the
                                            possibility of such damages.
                                        </p>
                                        <p className="mt-3">
                                            In the event that any provision of this Disclaimer is determined to be unenforceable
                                            under applicable law, such a determination will not impact the enforceability of the
                                            remaining provisions as stated within this Disclaimer.
                                        </p>
                                        <p className="mt-3">
                                            The Platform functions under the laws of Dubai, UAE, and any disputes or legal
                                            proceedings will be adjudicated in the Dubai Courts.
                                        </p>
                                    </div>

                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Syndication Members</h3>
                                        We explicitly disclaim any and all liability, including both oral and written express or
                                        implied warranties, to any Syndicate Members. Upon entering Games, You explicitly
                                        recognize that We have made no representation, nor do We assume any responsibility or
                                        liability, for any purported claim from a Syndicate Member. To clarify, We will only be
                                        accountable for a valid claim submitted by the registered holder of the Account with the
                                        winning Entry.
                                        <p className="mt-3">
                                            In cases where a Participant enters Games with the intention of distributing a Prize
                                            among Syndicate Members, the Participant is
                                        </p>
                                        <ul className="list-disc pl-5 lg:pl-7 rtl:pr-5 lg:rtl:pr-7">
                                            <li>Considered the Participant for the purposes outlined in the Rules; and</li>
                                            <li>
                                                Not acting as an agent on Our behalf for that purpose. Consequently, We will not be
                                            </li>
                                            <li>
                                                Obligated to pay any Prize to any Syndicate Member, except for a Prize originating
                                                from a winning Entry that is exclusively payable to the registered holder of the
                                                Account with such a winning Entry; and
                                            </li>
                                            <li>
                                                Bound by any agreement between the registered holder of the Account and any Syndicate
                                                Member.
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Hyperlinks</h3>
                                        The Platform and related communications may include hyperlinks leading to external
                                        websites beyond the Manager's control. Additionally, third-party websites may provide
                                        hyperlinks to access the Platform. We have not conducted a review and disclaim any
                                        responsibility for information hosted on external third-party websites accessed through
                                        hyperlinks or those referencing or accessing the Platform. The presence of a hyperlink
                                        does not imply an endorsement of the content, viewpoint, accuracy, opinions, policies,
                                        products, services, or accessibility of any third-party website. The Manager, along with
                                        its officers, members, and employees, bears no liability for any loss or damage, including
                                        indirect, consequential, or other types of losses, arising from or connected to the use of
                                        any third-party website linked via a hyperlink from or to the Platform, or any information
                                        contained on such third-party websites. It is not guaranteed that the controller of any
                                        third-party website will adhere to privacy standards and practices to the same extent as
                                        Us.
                                    </div>

                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Third Party Sales Channels</h3>
                                        We explicitly disclaim all liability, including any express or implied warranties
                                        pertaining to the sale of products and services through authorized Third Party Sales
                                        Channels. We are not accountable for any information stored or its processing, including
                                        prize payments, via any alternative third-party sales channels and their affiliated
                                        websites.
                                    </div>
                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Viruses</h3>
                                        We provide no assurance or representation regarding the compatibility of the Platform or
                                        communications with all systems or devices, or their security. While we employ all
                                        reasonable skill and care to maintain the security and error-free nature of the Platform
                                        and communications, participants are strongly urged to oversee their internet security,
                                        including personal details and devices. We bear no responsibility for any loss or damage
                                        arising from viruses, malware, distributed denial of service attacks, or other harmful
                                        elements that may negatively impact the hardware, software, data, or other materials of
                                        participants resulting from the use of the Platform or any other site referenced on the
                                        Platform.
                                    </div>

                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Altering of Forms</h3>
                                        Any unauthorized attempts to upload or modify information on the Platform, including
                                        unauthorized changes to the wording of online forms available on the Platform, are
                                        strictly prohibited. Such actions may result in the form or information being invalidated.
                                    </div>
                                    <div className="mt-3 lg:mt-7">
                                        <h3 className="text-base lg:text-xl font-semibold">Intellectual Property</h3>
                                        All intellectual property, including trademarks and logos featured on the Platform and in
                                        communications, is either licensed to or the property of the Manager. Reproduction or
                                        distribution of any data, files, graphics, images, results, or other content on the
                                        Website, Mobile App, or in communication is strictly prohibited unless expressly permitted
                                        by the Manager.
                                    </div>
                                    <div className="mt-3 lg:mt-7 mb-20">
                                        <h3 className="text-base lg:text-xl font-semibold">Acknowledgment</h3>
                                        Individuals using the Platform are deemed to have comprehensively read, understood, and
                                        consented to abide by this Legal Disclaimer.
                                    </div>
                                </>
                    {/* } */}
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Legal))
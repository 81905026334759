import { appConstants } from '../helpers/actionType';
import { history } from '../helpers/history';
export const alertActions = {
    clear,
    loading,
    success,
    error
};

function clear(flag) {
    return dispatch => {
        localStorage.removeItem('alertmsg');
        dispatch({ type: appConstants.CLEAR });
        if (flag == 1) {
            history.push('/login');
        }     
    };
}

function loading(message) {
    return { type: appConstants.LOADING, message };
}

function success(message) {
    return { type: appConstants.SUCCESS, message };
}

function error(message,loginflag) {
    if (loginflag == undefined) {
        loginflag = 0;
    }
    return { type: appConstants.ERROR, message, loginflag };
}

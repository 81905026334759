import { Component } from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { gameActions } from '../../action/GameAction';
import { history } from '../../helpers/history';
class LatestResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            VideoSecion: [],
            dataLoadCompleted: false
        }
        setTimeout(() => { this.props.GetLatestResult(0, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey) }, 1000);
    }
    static getDerivedStateFromProps(props, state) {
        return {
            // latestResult: props.latestResult
            latestResult: props.latestResult && props.latestResult.map(function (ele) {
                return { ...ele, game_group_id_temp: ele.game_group_id == 12 ? 9 : ele.game_group_id };
            })
        }
    }

    componentDidMount() {
        this.setjsonvideourl();

    }
    componentDidUpdate(prevState, prevProps) {
        if (this.state.dataLoadCompleted == false && this.state.latestResult && this.state.latestResult.length > 0) {
            this.state.dataLoadCompleted = true;
            if (window.LatesResultSlide) {
                window.LatesResultSlide()
            }
            //    window.LatesResultSlide();
        }
    }
    GotoPreviourresult = (GameId) => {
        localStorage.setItem('drawtabId', 1);
        //GameId == 9 ? history.push('/fortune4draws') : GameId == 10 ? history.push('/super6draws') : history.push('/magic5draws');
        switch (GameId) {
            case "9":
                history.push('/fortune4draws')
                break;
            case "10":
                history.push('/super6draws')
                break;
            case "11":
                history.push('/magic5draws')
                break;
            case "12":
                history.push('/othersdraws')
                break;
            case "13":
                history.push('/lucky1draws')
                break;
            default:
                break;
        }
    }

    setjsonvideourl = () => {
        let hbanner = 0;
        let hbanner_old = localStorage.getItem('videovers');
        if (this.props.appvariables.bVersion) {
            let hbannerVer = this.props.appvariables.bVersion.split("~")
            if (hbannerVer.length > 0) {
                hbanner = hbannerVer[4];
                localStorage.setItem('videovers', hbanner);
            }
        }
        if (hbanner_old != hbanner) {
            var s = process.env.REACT_APP_CDN_URL + 'web/HomeWinnerVideo.json' + "?V" + hbanner;
            fetch(s).then(response => response.json()).then(data => {
                localStorage.setItem('videourl', JSON.stringify(data));
                this.setState({ VideoSecion: data })
                console.log("data video url", data)
                setTimeout(() => {
                    if (window.HomePageVideoSlider)
                        window.HomePageVideoSlider()
                }, 300);
            })
        } else {
            let Videourl = JSON.parse(localStorage.getItem('videourl'));
            this.setState({ VideoSecion: Videourl })
            console.log("data video url", Videourl)
            setTimeout(() => {
                if (window.HomePageVideoSlider)
                    window.HomePageVideoSlider()
            }, 300);
        }
    }


    render() {
        const { t } = this.props;
        return (
            <>
                {/* {!this.state.latestResult || this.state.latestResult.length == 0 ? */}
                {/* <div className="imgAdjust">
                    <img src="./assets/img/banner/Result-Banner.webp?v0.1" />
                  </div> :   */}
                <section className="ldResult">
                    <div className="wrap1">
                        <>
                            <h1 className="headding1 !float-none">{t('lblLatestDrawRes')}</h1>
                            <div className="ldResultCont ldRsltContHeight">   {/*!float-none flex flex-wrap gap-10 justify-center */}
                                {/* && !this.state.latestResult.Status_id */}
                                <div className="ldRsltSlide">
                                    <ul className="slides">
                                        {this.state.latestResult && this.state.latestResult.map((x, inxd) =>
                                            <li>
                                                <div key={`g_${inxd}`} className={`ldResultLi bg-game${x.game_group_id_temp} flex-col`} >  {/*w-1/2 xl:w-1/3 */}
                                                    {/* <h2>{x.draw_no}</h2> */}
                                                    <div className="w-full flex justify-center py-2 rounded-full bg-white">
                                                        {
                                                            x.game_group_id == 9 ? <img src="./assets/img/gamesBg/fortune-4-logo.png?v0.1" alt="fortune 5 logo" className="h-12 w-24" /> :
                                                                x.game_group_id == 10 ? <img src="./assets/img/gamesBg/super-6-logo.png" alt="super 6 logo" className="h-12 w-24" /> :
                                                                    x.game_group_id == 11 ? <img src="./assets/img/gamesBg/magic-5-logo.png" alt="magic 5 logo" className="h-12 w-24" /> :
                                                                        x.game_group_id == 13 ? <img src="./assets/img/gamesBg/lucky-1-logo.png" alt="magic 5 logo" className="h-12 w-24" /> :
                                                                            <img src="./assets/img/gamesBg/fortune-5-logo.png?v0.1" alt="fortune 5 logo" className="h-12 w-24" />
                                                        }
                                                    </div>
                                                    {x.game_group_id != 13 ? <div className={x.win_nods.split(';').length == 5 ? "column5" : x.win_nods.split(';').length == 6 ? "column6" : "column4"}>
                                                        {x.win_nods.split(';').map((r,ine) =>
                                                            <span key={`h_${ine}`}>{r}</span>
                                                        )}
                                                    </div> : <>
                                                        {x.win_nods && x.win_nods.split(';').map((r,inx) =>
                                                            // <div className={`lckmyCartBall lckmyCartBallOrdrs border-game13 game-13-balls-${parseInt(2)} game-${x.gamegroupID}-active`} >
                                                            <ul key={`i_${inx}`} className="zodiacBallSec Latestlukyresult game-13-balls rowGap">
                                                                <li id="ball_526_2">
                                                                    <a className={`game-13-balls-${parseInt(r)}-ld game-13 border-radius10`}>
                                                                        <span>{r}</span>
                                                                    </a></li>
                                                            </ul>
                                                            // </div>
                                                        )}
                                                    </>}
                                                    {x.draw_time && !x.draw_time.startsWith("1~") ?
                                                        <div className="winrAmntDetls">
                                                            <table className="tble">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>{t('lblWinnings')}</th>
                                                                        <th >{t('lblWinnersml')}</th>
                                                                    </tr>

                                                                    {x.draw_time.split('|').map((w,i) =>
                                                                        <tr key={`j_${i}`}>
                                                                            <td>{w.split(',')[0]}</td>
                                                                            <td>{w.split(',')[1]}</td>
                                                                        </tr>
                                                                    )}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        // <ul className="ldrWinners">
                                                        //     {x.draw_time.split('|').map((w) =>
                                                        //         <li>
                                                        //             <div>{t('lblMatching')} {w.split(',')[0]}</div>
                                                        //             {w.split(',')[1] > 1 ? <div className="float-right">{w.split(',')[1]} {t('lblWinner')}</div>
                                                        //                 : <div className="float-right">{w.split(',')[1]} {t('lblSingularWin')}</div>
                                                        //             }
                                                        //         </li>
                                                        //     )}
                                                        //     {x.win_nods.split(';').length == 5 &&
                                                        //         <>
                                                        //             <div>&nbsp;</div>
                                                        //             <div>&nbsp;</div>
                                                        //         </>
                                                        //     }
                                                        // </ul>
                                                        :
                                                        x.draw_time && x.draw_time.startsWith("1~") ?
                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                <p className={`text-game${x.game_group_id_temp}`}>{t('lblresultprogress')}</p>
                                                            </div> :
                                                            <div className="w-full h-48 flex justify-center items-center rounded-2xl text-xl bg-white mt-5">
                                                                <p className={`text-game${x.game_group_id_temp}`}>{t('lblNoWinner')}</p>
                                                            </div>
                                                        // <h3 style={{ color: "#000000", textAlign: "center", fontFamily: "GentiumBookBasic-Bold" }}>{t('lblNoWinner')}</h3>
                                                        // <h3 style={{ color: "#000000", textAlign: "center", fontFamily: "GentiumBookBasic-Bold" }}>{t('lblresultprogress')}</h3>
                                                        // :
                                                        // <h3 style={{ color: "#000000", textAlign: "center", fontFamily: "GentiumBookBasic-Bold" }}>{t('lblNoWinner')}</h3>
                                                    }
                                                    <h3>{x.draw_date}</h3>
                                                    <button onClick={() => { this.GotoPreviourresult(x.game_group_id) }}>{t('lblPrevRes')}</button>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {this.state.VideoSecion && this.state.VideoSecion.length > 0 &&
                                <div className="ldResultCont">
                                    <div className="videoSec hmVideoSec">
                                        <h1 className="formHeadding">{t('lblwinnersec')}</h1>
                                        <div className="hmVideoSlides">
                                            <ul className="slides">
                                                {this.state.VideoSecion && this.state.VideoSecion.length > 0 && this.state.VideoSecion.map((x, inxd) =>
                                                    <>
                                                        <li key={`k_${inxd}`}>
                                                            <iframe
                                                                width="100%"
                                                                height="auto"
                                                                title="YouTube video player"
                                                                // frameborder="0"
                                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                className="rounded-md iframeborder"
                                                                allowFullScreen
                                                                src={x.videoUrl + '&rel=0'}
                                                                // style={{border:"0px"}}
                                                            >
                                                            </iframe>
                                                        </li>

                                                    </>
                                                )}
                                            </ul>
                                        </div>
                                        <a className="hmMoreVideosBtn" onClick={() => { history.push('/winnersstory') }} >{t('lblmorevideos')}</a>
                                    </div>
                                </div>}
                        </>
                    </div>
                </section>
            </>
        )
    }

}

function mapStateToProps(state) {
    const { latestResult } = state.gameReducer;
    const { user, appvariables } = state.loginReducer;
    return { user, latestResult, appvariables };
}
const mapActionToProps = {
    GetLatestResult: gameActions.GetLatestResult
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(LatestResults))


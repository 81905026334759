import React, { Component } from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Header from "../../Header/Header";

class WinnersStory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drawtype: 10,
            pastDrawShow: [],
            currentPageNo: 0,
            NumberofRecord: 6,
            totalPage: 0,
            startinx: 0,
            endinx: 6,
            lfilterdt: [],
            IsValidData: false,
            _dbdrawType: 10

        }
    }

    componentDidMount() {
        this.setjsonvideourl();
        // localStorage.setItem('drawtabId', 3);
    }

    componentDidUpdate(prevState, prevProps) {
        if (this.state.pastDrawShow && this.state.pastDrawShow !== prevProps.pastDrawShow && this.state.pastDrawShow.length != 0 && this.state.IsValidData == false) {
            this.state.IsValidData = true;
            this.setState({ IsValidData: true })
            this.LoadTransactionData()
            // window.allDrawResultPopUp();
        }
    }


    LoadTransactionData = () => {
        if (this.state.pastDrawShow && this.state.pastDrawShow.length > 0) {
            this.setState({ lfilterdt: this.state.pastDrawShow });
            let ltotpage = Math.trunc(this.state.pastDrawShow.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(this.state.pastDrawShow.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }



    setjsonvideourl = () => {
        try {
            let hbanner = 0;
            let hbanner_old = localStorage.getItem('pagevideovr');
            if (this.props.appvariables.bVersion) {
                let hbannerVer = this.props.appvariables.bVersion.split("~")
                if (hbannerVer.length > 0) {
                    hbanner = hbannerVer[4];//[4]
                    localStorage.setItem('pagevideovr', hbanner);
                }
            }
            if (hbanner_old != hbanner) {
                var s = process.env.REACT_APP_CDN_URL + 'web/WinnerVideoUrl.json' + "?V" + hbanner;
                fetch(s).then(response => response.json()).then(data => {
                    localStorage.setItem('pagevideourl', JSON.stringify(data.winnerVideoList));
                    this.setState({ pastDrawShow: data.winnerVideoList })
                    console.log("data video url", data.winnerVideoList)
                })
            } else {
                let Videourl = JSON.parse(localStorage.getItem('pagevideourl'));
                this.setState({ pastDrawShow: Videourl })
                console.log("data video url", Videourl)
            }

        }
        catch (ex) {
            console.log("setjsondata => " + ex)
        }
    };


    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }


    render() {
        const { t } = this.props;
        return (

            <>
                <Header />
                <div className="wrap1">
                    <h2 className="headding3">{t('lblOurWinner')}</h2>
                </div>
                <section className="ourWinners">
                    <div className="wrap1 wrapOurWinrs">
                        {this.state.pastDrawShow && this.state.pastDrawShow.length >= 0 && this.state.pastDrawShow.slice(this.state.startinx, this.state.endinx).map((x, inxd) =>
                            <div className={`ourWiCont oWbg${x.GameTypeId == 9 ? '2' : x.GameTypeId == 10 ? '3' : '1'}`} key={`av_${inxd}`}>
                                <div className="ourWinVideoSec">
                                    {/* <video width="100%" height="auto" controls>
                                        <source src={x.videoUrl} type="video/mp4" />
                                        <source src="movie.ogg" type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video> */}
                                    <iframe
                                        width="100%"
                                        height="auto"
                                        title="YouTube video player"
                                        // frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        className="rounded-md"
                                        allowFullScreen
                                        src={x.videoUrl + '&rel=0'}
                                        style={{border:"0px"}}
                                    >
                                    </iframe>
                                </div>
                                <h4 className={`oWTxtClr${x.GameTypeId == 9 ? '2' : x.GameTypeId == 10 ? '3' : '1'}`}>{x.playername}</h4>
                                <p className={`oWTxtClr${x.GameTypeId == 9 ? '2' : x.GameTypeId == 10 ? '3' : '1'}`}>{x.date} of {process.env.REACT_APP_CURRENCY} {x.amount}</p>
                            </div>
                        )}
                        <div className="pagination paginationWidth mt-16">
                            <div className="paginationBtn">
                                <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                                <div>
                                    <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                    <span>/</span>
                                    <span>{this.state.pastDrawShow && this.state.pastDrawShow.length > 0 ? this.state.totalPage + 1 : 0}</span>
                                </div>
                                <button><img src="./assets/img/icons/right-arrow.png" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} alt="right arrow" onClick={() => { this.loadnxtdata("+") }} /></button>
                            </div>
                        </div>
                    </div>

                </section>
            </>
        )
    }
}


function mapStateToProps(state) {
    const { appvariables } = state.loginReducer;
    return { appvariables };

}
export default connect(mapStateToProps)(withTranslation()(WinnersStory))

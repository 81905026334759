import React, { Component } from 'react';
import { loginActions } from '../../action/loginAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class GivingBack extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
    }
    render() {
        const { t } = this.props;
        const { i18n } = this.props;
        return (
            <>
                <h2 className='headding3'>{t('lblGivingBack')}</h2>
                <div className="addlPage scrollbar marginBottomResult">
                    <div className="givingBack">
                        <div><img src="./assets/img/Giving_Back.png" alt="Giving Back" /></div>
                        {i18n.language === 'ur' ?
                            <div>
                                <p>ہمارا کجھور کا درخت لگانے کا پروجیکٹ ہمارے سیارے کے بہتر مستقبل کے لیے ہماری امید کی علامت ہے۔ ہمیں خطے کے مختلف مقامات پر کھجور کے درخت لگا کر متحدہ عرب امارات کے گرین ایجنڈا 2030 میں تعاون کرنے پر فخر ہے۔ ہمیں یقین ہے کہ یہ منصوبہ ماحولیات پر مثبت اثر ڈالے گا اور متحدہ عرب امارات کے پائیدار مقاصد کے حصول میں اس کی مدد کرے گا۔.</p>
                                <p>ہم زیادہ پائیدار مستقبل بنانے کے لیے پرعزم ہیں اور رہنمائی کے لیے اس کے تصور سے متاثر ہیں۔ ہم زیادہ سبز اور زیادہ پائیدار مستقبل کے اس کے خواب سے متاثر ہیں اور اسے آگے بڑھانے کے لیے پرعزم ہیں۔.</p>
                                <p>ہم مسکراہٹیں پھیلانے اور دنیا کو بہتر جگہ بنانے کے اپنے مشن میں شامل ہونے کے لیے آپ کو دعوت دیتے ہیں۔.</p>
                                <div className="divBorder width20"></div>
                            </div>
                            :
                            i18n.language === 'ml' ?
                                <div>
                                    <p>നമ്മുടെ ഈന്തപ്പന തോ ട്ടം പദ്ധതി നമ്മുടെ ലോ കത്തി ന്റെ മെ ച്ചപ്പെ ട്ട ഭാ വി യെ ക്കുറി ച്ചുള്ള നമ്മുടെ പ്രത്യാ ശയുടെ പ്രതീ കമാ ണ്.
                                        മേ ഖലയി ലെ വി വി ധ സ്ഥലങ്ങളി ൽ ഈന്തപ്പനകൾ നട്ടുപി ടി പ്പി ച്ചുകൊ ണ്ട് യുഎഇയുടെ ഹരി ത അജണ്ട 2030-ലേ ക്ക്
                                        സം ഭാ വന നൽകു ന്നതി ൽ ഞങ്ങൾ അഭി മാ നി ക്കുന്നു. ഈ പദ്ധതി പരി സ്ഥി തി യി ൽ നല്ല സ്വാ ധീ നം ചെ ലുത്തുമെ ന്നും
                                        യുഎഇയെ അതി ന്റെ സുസ്ഥി ര ലക്ഷ്യ ങ്ങൾ കൈ വരി ക്കാ ൻ സഹാ യി ക്കുമെ ന്നും ഞങ്ങൾ വി ശ്വ സി ക്കുന്നു.</p>
                                    <p>കൂടുതൽ സുസ്ഥി രമാ യ ഒരു ഭാ വി സൃഷ്ടി ക്കാ ൻ ഞങ്ങൾ പ്രതിജ്ഞാ ബദ്ധരാ ണ്, ഒപ്പം വഴി നയി ക്കാ നുള്ള അദ്ദേ ഹത്തി ന്റെ
                                        കാ ഴ്ചപ്പാ ടി ൽ നി ന്ന് പ്രചോ ദനം ഉൾക്കൊ ണ്ടവരു മാ ണ്. ഹരി തവും സുസ്ഥി രവുമാ യ ഭാ വി യെ ക്കുറി ച്ചുള്ള അദ്ദേ ഹത്തി ന്റെ
                                        സ്വ പ്നത്തി ൽ നി ന്ന് ഞങ്ങൾ പ്രചോ ദി തരാ ണ്, അത് മുന്നോ ട്ട് കൊ ണ്ടുപോ കാ ൻ പ്രതിജ്ഞാ ബദ്ധരാ ണ്.</p>
                                    <p>പുഞ്ചി രി പരത്താ നും ലോ കത്തെ ഒരു മി കച്ച സ്ഥലമാ ക്കി മാ റ്റാ നുമുള്ള ഞങ്ങളുടെ ദൗ ത്യ ത്തി ൽ ഞങ്ങളോ ടൊ പ്പം ചേ രാ ൻ
                                        ഞങ്ങൾ നി ങ്ങളെ ക്ഷണി ക്കുന്നു.</p>
                                    <div className="divBorder width20"></div>
                                </div> : i18n.language === 'ar' ?
                                    <div>
                                        <p>يعد مشروعنا لزراعة أشجار النخيل رمزًا لأملنا في مستقبل أفضل لكوكبنا. نحن فخورون بمساهمتنا في الأجندة الخضراء لدولة الإمارات العربية المتحدة 2030 من خلال زراعة أشجار النخيل في مواقع مختلفة في المنطقة. ونؤمن بأن هذا المشروع سيكون له تأثير إيجابي على البيئة وسيساعد دولة الإمارات على تحقيق أهداف الاستدامة.</p>
                                        <p>نحن ملتزمون ببناء مستقبل أكثر استدامة، ونستلهم من رؤيته لقيادة الطريق. نحن نستلهم حلمه بمستقبل أكثر خضرة واستدامة، وملتزمون بالمضي قدمًا في تنفيذه.</p>
                                        <p>ندعوك للانضمام إلينا في مهمتنا لنشر الابتسامات وجعل العالم مكانًا أفضل.</p>
                                        <div className="divBorder width20"></div>
                                    </div> :
                                    <div>
                                        <p>Our Palm Tree Plantation Project is a symbol of our hope for a better future for our planet. We are proud to contribute to the UAE’s Green Agenda 2030 by planting palm trees across various locations in the region. We believe that this project will make a positive impact on the environment and help the UAE achieve its sustainability goals.</p>
                                        <p>We are committed to creating a more sustainable future, and are inspired by his vision to lead the way. We are inspired by his dream of a greener and more sustainable future, and are committed to carrying it forward.</p>
                                        <p>We invite you to join us in our mission to spread smiles and make the world a better place.</p>
                                        <div className="divBorder width20"></div>
                                    </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    logout: loginActions.logout
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(GivingBack))
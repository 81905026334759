import React from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import { history } from '../helpers/history';

class PageNotFound extends React.Component {
    constructor(props) {
        super(props);        
    }
    
    render() {
        const { t } = this.props;
            return (<>
                <Header />
                <div className="wrap2">
				<div className="flex flex-col items-center mt-20">
					<div className="text-slate-600">404 {t('lblNotfound')}</div>
					<div className="text-2xl font-semibold">{t('lblRegret')}</div>
					<p className="text-xl text-center text-slate-700">
						{t('lbl404Msg')}
					</p>
					<button className="bg-brand px-4 py-1 text-white rounded-md">
						{/* <a href={`${process.env.REACT_APP_BASENAME}/Home`}>{t('lblGohome')}</a> */}
						<a  onClick={() => {history.push('/')}}>{t('lblGohome')}</a>
					</button>
				</div>
			</div></>
            );
        }
}

function mapStateToProps(state) {
	return {  };
}
const mapActionToProps = {
    
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PageNotFound))

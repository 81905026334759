import React, { Component } from 'react'
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { loginActions } from './action/loginAction';
import { alertActions } from './action/AlertAction';
import { withTranslation } from 'react-i18next';

class IdelSessionTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 1,
            showPopUp: false
        }
        //auto logout ,if url open duplicate window.
        if (window.name == '') {
            let owinName = localStorage.getItem('wname');
            if (owinName) {
                // this.handleLogout();
            }
            else {
                let wname = 'gtweb';
                window.name = wname;
                localStorage.setItem('wname', wname);
            }
        }
    }
    _onAction = (e) => {
        console.log('user did something', e)
        this.setState({ isTimedOut: false })
    };
    _onActive = (e) => {
        console.log('user is active', e)
        this.setState({ isTimedOut: false })
    };
    _onIdle = (e) => {
        console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            this.props.logout(this.props.user.mobileNo);
        } else {
            this.setState({ showPopUp: true })
            if (this.idleTimer != null) {
                this.idleTimer.reset();
            }
            this.setState({ isTimedOut: true });
        }
    };
    handleClose = () => {
        this.setState({ showPopUp: false });
    }
    handleLogout = () => {
        this.setState({ showPopUp: false });
        this.props.logout(this.props.user.mobileNo);
    }
    componentDidMount() {
        // window.addEventListener("beforeunload", () => {
        //     this.checkSessionExpird();
        // });
    }
    checkSessionExpird = () => {
        try {
            let ldate = this.props.user.login_Time;
            let totdate = ldate + this.props.user.loginExpiry;
            let ndate = (new Date()).getTime();
            if (ndate > totdate) {
                this.handleLogout();
            }
        } catch (ex) { }
    };
    render() {
        const { t } = this.props;
        return (
            <div>
                <IdleTimer ref={(ref) => { this.idleTimer = ref }}
                    element={document} onActive={() => { this._onActive() }} onIdle={() => { this._onIdle() }} onAction={() => { this._onAction() }}
                    debounce={250} timeout={this.props.user.loginExpiry} />

                {this.state.showPopUp && <div>
                    <div style={{ transform: "translate(-50%, -50%)", display: "block" }} className="lightbox AlertpopUp">
                        <div className="lightBox-wrap">
                            <div className="userContent">
                                <h2 style={{ padding: "10px" }}>{t('lblSessionExpired')}</h2>
                                <div className="altBtns">
                                    <button className="bg6" onClick={() => { this.setState({ showPopUp: false }); this.props.logout(this.props.user.mobileNo); }}>{t('lblLogout')}</button>
                                    <button className="bg3" onClick={() => { this.handleClose() }}>{t('lblStay')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overlaybetsilp" style={{ display: "block" }}></div>
                </div>
                }
            </div>
        )
    };
}

function mapStateToProps(state) {
    const { user } = state.loginReducer;
    return { user };
}
const mapActionToProps = {
    clearAlerts: alertActions.clear,
    logout: loginActions.logout
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(IdelSessionTimer))
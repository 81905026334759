import React, { Component } from "react";
import { alertActions } from '../../action/AlertAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { accountActions } from "../../action/AccountAction";

class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            serverDate: new Date(),
            creditStatus: 0,
            creditStatus1: 0,
            creditStatus2: 3,
            currentPageNo: 0,
            NumberofRecord: 10,
            totalPage: 0,
            startinx: 0,
            endinx: 10,
            lfilterdt: []
        };
    }
    componentDidMount() {
        window.Loaddaterange(1);
        var lstrdate = document.getElementById('mydaterange').value;
        this.onchangeDate(lstrdate);
        window.getdateFromJS(this.onchangeDate);
        // localStorage.setItem('atabId', 6); this code is using in Account Component
        //setTimeout(() => { this.LoadTransactionData() }, 100);
    }
    componentDidUpdate(prevProps) {
        if (this.props.TransactionData !== prevProps.TransactionData) {
            this.LoadTransactionData()
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            TransactionData: props.TransactionData
        };
    };
    onchangeDate = (ldate) => {
        var fdate = ldate.split('-')[0].trim();
        var tdate = ldate.split('-')[1].trim();
        let checkdate = this.validatedate(fdate, tdate);
        if (checkdate) {
            this.props.GetTransactiondel(0, fdate, tdate, 2, this.props.user.mobileNo, this.props.i18n.language, this.props.user.loginKey);
        }
    }
    validatedate = (fromDate, toDate) => {
        let datepattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var fr = (new Date(fromDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var to = (new Date(toDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
        var currDate = ("0" + this.state.serverDate.getDate()).slice(-2) + "/" + ("0" + (this.state.serverDate.getMonth() + 1)).slice(-2) + "/" + this.state.serverDate.getFullYear();
        var lserverDate = (new Date(currDate.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));

        if (fr > lserverDate || to > lserverDate) {
            this.showMsg('lblNoFuturedate');
            return false;
        }
        return true;
        if (fr > to) {
            this.showMsg('lblFromdateNotGreater');
            return false;
        }
        fr.setHours(0, 0, 0, 0);
        to.setHours(0, 0, 0, 0);
        if (fr > to || fr < to) {
            var date = this.state.serverDate;
            var ss = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
            var cdate = new Date(new Date(ss.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var lfr = ("0" + fr.getDate()).slice(-2) + "/" + ("0" + (fr.getMonth() + 1)).slice(-2) + "/" + fr.getFullYear();
            var lto = ("0" + to.getDate()).slice(-2) + "/" + ("0" + (to.getMonth() + 1)).slice(-2) + "/" + to.getFullYear();
            var fdate = new Date(new Date(lfr.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            var tdate = new Date(new Date(lto.replace(datepattern, '$3-$2-$1').replace(/-/g, '/')));
            cdate.setHours(0, 0, 0, 0);
            fdate.setHours(0, 0, 0, 0);
            tdate.setHours(0, 0, 0, 0);
            if (fdate > cdate && tdate > cdate) {
                this.showMsg('lblCurrentDate');
                return false;
            }
        }
        return true;
    }
    showMsg = (lstrError) => {
        this.props.alertError(this.props.t(lstrError));
    }
    chkbtnClick = (id) => {
        if (id == 1) {
            if (this.state.creditStatus == 0) {
                this.state.creditStatus = id;
                this.setState({ creditStatus: id });
            }
            else {
                this.state.creditStatus = 0;
                this.setState({ creditStatus: 0 });
            }
        }
        else if (id == 2) {
            if (this.state.creditStatus1 == 0) {
                this.state.creditStatus1 = id;
                this.setState({ creditStatus1: id });
            }
            else {
                this.state.creditStatus1 = 0;
                this.setState({ creditStatus1: 0 });
            }
        }
        else if (id == 3) {
            if (this.state.creditStatus2 == 0) {
                this.state.creditStatus2 = id;
                this.setState({ creditStatus2: id });
            }
            else {
                this.state.creditStatus2 = 0;
                this.setState({ creditStatus2: 0 });
            }
        }
        this.LoadTransactionData();
    }
    LoadTransactionData = () => {
        if (this.state.TransactionData && this.state.TransactionData.result && this.state.TransactionData.result.length > 0) {
            let ldt = this.state.TransactionData.result.filter(filterData => {
                return (this.state.creditStatus == 0 && this.state.creditStatus1 == 0 && this.state.creditStatus2 == 0) || (this.state.creditStatus != 0 && this.state.creditStatus1 != 0 && this.state.creditStatus2 != 0) ?
                    filterData : ((filterData.payment_type == this.state.creditStatus && this.state.creditStatus1 == 0 && this.state.creditStatus2 == 0) ||
                        (filterData.payment_type == this.state.creditStatus1 && this.state.creditStatus == 0 && this.state.creditStatus2 == 0) ||
                        (filterData.payment_type == this.state.creditStatus2 && this.state.creditStatus == 0 && this.state.creditStatus1 == 0) ||
                        (filterData.payment_type == this.state.creditStatus || filterData.payment_type == this.state.creditStatus1 && this.state.creditStatus2 == 0) ||
                        (filterData.payment_type == this.state.creditStatus1 || filterData.payment_type == this.state.creditStatus2 && this.state.creditStatus == 0) ||
                        (filterData.payment_type == this.state.creditStatus || filterData.payment_type == this.state.creditStatus2 && this.state.creditStatus1 == 0)
                    )
            });
            this.setState({ lfilterdt: ldt });
            let ltotpage = Math.trunc(ldt.length / this.state.NumberofRecord);
            let ltotpage1 = Math.trunc(ldt.length % this.state.NumberofRecord);
            if (ltotpage1 == 0) {
                ltotpage = ltotpage - 1;
            }
            this.setState({ currentPageNo: 0 });
            this.setState({ totalPage: ltotpage })
            this.setState({ startinx: 0, endinx: this.state.NumberofRecord });
        }
        else {
            this.setState({ lfilterdt: [] });
        }
    }
    loadnxtdata = (st) => {
        if (st == "+") {
            if (this.state.currentPageNo != this.state.totalPage) {
                this.state.currentPageNo = this.state.currentPageNo + 1;
            }
        }
        else if (st == "-") {
            if (this.state.currentPageNo != 0) {
                this.state.currentPageNo = this.state.currentPageNo - 1;
            }
        }
        let _startinx = this.state.currentPageNo * this.state.NumberofRecord;
        let _endinx = _startinx + this.state.NumberofRecord;
        this.setState({ startinx: _startinx, endinx: _endinx });
    }
    render() {
        const { t } = this.props;
        return (
            <div className="profileCont">
                <div className="transaction">
                    <div className="row marTop">
                        <div className="col-md-12">
                            <div className="transorder">
                                <div className="transButton">
                                    {/* <p>{t('lblWalletBal')}</p> */}
                                    <p>{t('lblcredits')}</p>
                                    <div className="transBtnSel">
                                        <span className="currencyFormat"> {process.env.REACT_APP_CURRENCY} </span>
                                        {/* <span className="numbers">{this.props.user.balance}</span> */}
                                        <span className="numbers">{(this.props.user.balance - this.props.user.winbalance) > 0 ? this.props.user.balance - this.props.user.winbalance : 0}</span>
                                    </div>
                                    {/* <button type="button" onClick={() => { this.props.changeTab(3) }}>{t('lblCredit')}</button> */}
                                </div>
                                <div className="transButton">
                                    <p>{t('lblWinnings')}</p>
                                    <div className="transBtnSel">
                                        <span className="currencyFormat"> {process.env.REACT_APP_CURRENCY} </span>
                                        <span className="numbers">{this.props.user.winbalance}</span>
                                    </div>
                                </div>

                                {/* <div className="trnasactionBtns"> */}
                                {/* <button type="button" className="uppercase" onClick={() => { this.props.changeTab(3) }} id="show-transfer">{t('lblCredit')}</button>
                                    <button type="button" className="uppercase" onClick={() => { this.props.changeTab(4) }}>{t('lbldebit')}</button> */}
                                {/* </div> */}
                            <button className="inputDateRangeBtn" style={{paddingRight:"40px"}}>
                                <img src="./assets/img/icons/calendar.svg" />
                                <input style={{ cursor: "pointer", backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" dir="ltr" readOnly />
                            </button>
                            </div>
                        </div>
                    </div>

                    {/* <div className="filterByDate col-md-12 marginTop">
                        <div>
                            <div>{t('lblFilterBy')}:</div>
                            <div className="radioInputs">
                                <div>
                                    <input id="r1" type="checkbox" name="group1" className="gtRadio" checked={this.state.creditStatus == 1} onClick={() => this.chkbtnClick(1)} />
                                    <span>{t('lblCredit')}</span>
                                </div>
                                <div>
                                    <input id="r2" type="checkbox" name="group1" className="gtRadio" checked={this.state.creditStatus1 == 2} onClick={() => this.chkbtnClick(2)} />
                                    <span htmlFor="r2">{t('lbldebit')}</span>
                                </div>
                                <div>
                                    <input id="r3" type="checkbox" name="group1" className="gtRadio" checked={this.state.creditStatus2 == 3} onClick={() => this.chkbtnClick(3)} />
                                    <span htmlFor="r3">{t('lblPurchase')}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <button className="inputDateRangeBtn">
                        <img src="./assets/img/icons/calendar.svg" />
                        <input style={{ cursor: "pointer", backgroundColor: "#df911a", border: "#df911a", color: '#fff', padding: '0px' }} id="mydaterange" className="daterange" type="text" name="daterange" dir="ltr" readOnly />
                    </button> */}
                    <div className="divBorder marginTop dividerWidth"></div>
                    <div className="transTable">
                        <table>
                            <tr>
                                <th>{t('lblID')}</th>
                                <th>{t('lblDateTime')}</th>
                                <th>{t('lblType')}</th>
                                <th>{t('lblDiscountApp')}</th>
                                <th>{t('lblAmt')}</th>
                                <th style={{ textTransform: "uppercase" }}>{t('lblStatus')}</th>
                                {/* <th>{t('lblEndBal')}</th> */}
                            </tr>
                            {this.state.lfilterdt && this.state.lfilterdt.length > 0 && this.state.lfilterdt.slice(this.state.startinx, this.state.endinx).map((data,index) =>
                                <tr key={`bo_${index}`}>
                                    <td>{data.transaction_id}</td>
                                    <td>{data.transaction_date_time}</td>
                                    <td>{data.transaction_type}</td>
                                    <td>{process.env.REACT_APP_CURRENCY} {data.discount}</td>
                                    <td>{process.env.REACT_APP_CURRENCY} {data.amount}</td>
                                    <td>{data.transaction_status}</td>
                                    {/* <td> {process.env.REACT_APP_CURRENCY} {data.balance}</td> */}
                                </tr>
                            )}
                        </table>
                        <div className="pagination">
                            <div className="paginationBtn">
                                <button><img src="./assets/img/icons/left-arrow.png" className={this.state.currentPageNo == 0 ? "pagination-inactive" : ""} alt="left arrow" onClick={() => this.loadnxtdata("-")} /></button>
                                <div>
                                    <span>{this.state.lfilterdt && this.state.lfilterdt.length > 0 ? this.state.currentPageNo + 1 : 0}</span>
                                    <span> / </span>
                                    <span>{this.state.lfilterdt && this.state.lfilterdt.length > 0 ? this.state.totalPage + 1 : 0}</span>
                                </div>
                                <button><img src="./assets/img/icons/right-arrow.png" alt="right arrow" className={this.state.currentPageNo == this.state.totalPage ? "pagination-inactive" : ""} onClick={() => this.loadnxtdata("+")} /></button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}
function mapStateToProps(state) {
    const { user } = state.loginReducer;
    const { TransactionData } = state.accountReducer;
    return { user, TransactionData };
}
const mapActionToProps = {
    alertError: alertActions.error,
    GetTransactiondel: accountActions.GetTransactionDel
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Transaction))
